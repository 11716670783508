import React from 'react'

const SortIcon = () => (
  <svg width={16} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m1 13 5.586 5.586a2 2 0 0 0 2.828 0L15 13M15 7 9.414 1.414a2 2 0 0 0-2.828 0L1 7"
      stroke="#37190C"
    />
  </svg>
)

export default SortIcon
