import { handleActions } from 'redux-actions'
import { toNumber } from 'lodash'

import { ROLES } from '../../utils/constants'
import {
  setUser,
  setRoleUser,
  setNewPlanId,
  setLeaveFromFamilyPlan,
  setReduceNumberRecipes,
  setIncreaseNumberRecipes,
} from '../actions/user'

const initialState = {
  data: null,
  userRole: ROLES.ROLE_GUEST,
}

const actions = {
  [setUser]: (state, { payload }) => {
    return {
      ...state,
      data: payload,
    }
  },
  [setRoleUser]: (state, { payload }) => {
    return {
      ...state,
      userRole: payload,
    }
  },
  [setNewPlanId]: (state, { payload }) => {
    return {
      ...state,
      data: { ...state.data, settings: { ...state.data?.settings, plan_id: payload } },
    }
  },
  [setLeaveFromFamilyPlan]: (state) => {
    return {
      ...state,
      data: { ...state.data, gift_subscription: null, gifter_id: null },
    }
  },
  [setReduceNumberRecipes]: (state) => {
    return {
      ...state,
      data: { ...state.data, recipes_count: state.data?.recipes_count - 1 },
    }
  },
  [setIncreaseNumberRecipes]: (state) => {
    return {
      ...state,
      data: { ...state.data, recipes_count: toNumber(state.data?.recipes_count) + 1 },
    }
  },
}

export default handleActions(actions, initialState)
