import r from '../utils/request'

export const updateAvatar = (file) => {
  return r.upload('/users/avatar', { file })
}

export const updatePassword = (params) => {
  return r.put('/users/password', params)
}

export const updateFullName = (params) => {
  return r.put('/users', params)
}

export const leaveFromFamilyPlan = () => {
  return r.post('/users/subscription/leave')
}
