import { useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate } from 'react-router-dom'

import { EMAIL_PATTERN, REQUIRED_FIELD_MESSAGE, SITE_KEY } from '../../utils/constants'
import { loginAdmin } from '../../service/admin'
import { Lock, Mail } from '../../components/SvgIcon'
import HookForm from '../../components/HookForm'

const AdminLogin = () => {
  const [disableBtn, setDisableBtn] = useState(true)
  const loginFormRef = useRef()
  const navigation = useNavigate()
  const dispatch = useDispatch()

  const defaultFields = {
    className: 'mt-8',
    defaultValue: '',
  }
  const fields = [
    {
      name: 'login',
      rules: {
        required: REQUIRED_FIELD_MESSAGE,
        pattern: {
          value: EMAIL_PATTERN,
          message: 'This field must have valid email',
        },
      },
      icon: <Mail />,
      nameLabel: 'Login',
      inputType: 'email',
      ...defaultFields,
    },
    {
      name: 'password',
      rules: {
        required: REQUIRED_FIELD_MESSAGE,
        minLength: { value: 6, message: 'Password must be at least 6 characters' },
      },
      icon: <Lock />,
      nameLabel: 'Password',
      inputType: 'password',
      ...defaultFields,
    },
  ]

  const Footer = useMemo(
    () => (
      <div>
        <div className="innerRecaptcha">
          <ReCAPTCHA onChange={() => setDisableBtn(false)} sitekey={SITE_KEY} size="normal" />
        </div>
        <button disabled={disableBtn} type="submit" className="buttonWrapper buttonText">
          Login
        </button>
      </div>
    ),
    [disableBtn]
  )

  const handleSubmit = (data) => {
    loginAdmin(data, loginFormRef, navigation)(dispatch)
  }

  return (
    <div className="innerLoginAdmin">
      <div className="modalSignUp">
        <p className="modalTitle">Login as admin</p>

        <HookForm
          ref={loginFormRef}
          className="formSignUp"
          onSubmit={handleSubmit}
          fields={fields}
          Footer={Footer}
        />
      </div>
    </div>
  )
}

export default AdminLogin
