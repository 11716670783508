import { intersection, isEmpty, isNil, omitBy, pick, isString, isArray } from 'lodash'

const useDirty = () => {
  const filterDirtyFields = ({
    dirtyFields,
    data,
    current_step = null,
    urlParams = null,
    checkArray = false,
  }) => {
    const keysDirtyFields = Object.keys(dirtyFields)
    const keysFields = Object.keys(data)
    const checkIsArray = (value) => {
      return checkArray ? isArray(value) : false
    }

    const dataForm = {
      ...pick(data, intersection(keysDirtyFields, keysFields)),
      ...(!isNil(current_step) && { current_step }),
      ...(!isNil(urlParams) && { id: urlParams }),
    }

    return omitBy(
      dataForm,
      (value) => isNil(value) || ((checkIsArray(value) || isString(value)) && isEmpty(value))
    )
  }

  return { filterDirtyFields }
}

export default useDirty
