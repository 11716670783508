const SuccessPurchaseBook = () => {
  return (
    <div className="wrapper">
      <div className="headerSignUp">
        <a className="innerLogoSiteRegistration" href="https://getmorsel.com">
          <img className="siteLogo" alt="Logo" src="/logo.svg" />
        </a>
      </div>
      <div className="modalSignUp">
        <p className="modalTitle">You are success order book!</p>
        <p className="modalSubTitle">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry's standard dummy text ever since.
        </p>
      </div>
    </div>
  )
}

export default SuccessPurchaseBook
