import './styles.css'

const LoadingSpinner = ({ className = '' }) => {
  return (
    <div className={`innerLoadingSpinner ${className}`}>
      <div className="innerSpinner">
        <div className="spinnerSvg">
          <svg
            id="svg-spinner"
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="57"
            className="spinner"
            viewBox="0 0 48 48"
          >
            <circle cx="24" cy="4" r="4" fill="#f7f5ad" />
            <circle cx="12.19" cy="7.86" r="3.7" fill="#f7f4ab" />
            <circle cx="5.02" cy="17.68" r="3.4" fill="#f7f4ab" />
            <circle cx="5.02" cy="30.32" r="3.1" fill="#f7f4ab" />
            <circle cx="12.19" cy="40.14" r="2.8" fill="#f7f4ab" />
            <circle cx="24" cy="44" r="2.5" fill="#f7f4ab" />
            <circle cx="35.81" cy="40.14" r="2.2" fill="#f7f4ab" />
            <circle cx="42.98" cy="30.32" r="1.9" fill="#f7f4ab" />
            <circle cx="42.98" cy="17.68" r="1.6" fill="#f7f4ab" />
            <circle cx="35.81" cy="7.86" r="1.3" fill="#f7f4ab" />
          </svg>
          <p>Loading</p>
        </div>
      </div>
    </div>
  )
}

export default LoadingSpinner
