import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { logout } from '../../service/auth'
import { NAVIGATION_MENU } from '../../utils/constants'
import Layout from '../../components/Layout'
import LayoutAccount from '../../components/LayoutAccount'
import { ArrowNavigation } from '../../components/SvgIcon'

import './styles.css'

const AccountSettingsMenu = () => {
  const dispatch = useDispatch()
  const navigation = useNavigate()

  const ButtonNavigationAccount = ({ routes, text }) => {
    return (
      <Link to={routes} className="linkNavigationAccount">
        {text}
        <ArrowNavigation classStyle="navigationArrowSettings" />
      </Link>
    )
  }

  return (
    <Layout title="Account navigation">
      <LayoutAccount>
        <div className="innerNavigationAccount">
          {NAVIGATION_MENU.map((item) => (
            <ButtonNavigationAccount key={item.id} {...item} />
          ))}
          <p onClick={() => logout(navigation)(dispatch)} className="styleLogout">
            log out
          </p>
        </div>
      </LayoutAccount>
    </Layout>
  )
}

export default AccountSettingsMenu
