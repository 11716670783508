import { Link } from 'react-router-dom'

import routes from '../../routers'
import { ArrowBack } from '../../components/SvgIcon'
import LayoutAccount from '../../components/LayoutAccount'
import Layout from '../../components/Layout'

const GiftSubscriptions = () => {
  return (
    <Layout title="Gift a Subscription">
      <LayoutAccount>
        <h1 className="titleNotification titleChangePassword">Gift a Subscription</h1>
        <div style={{ maxWidth: 533 }} className="innerTextPage">
          Would you like to gift a year’s subscription to your other group members? Email{' '}
          <span>info@getmorsel.com</span> and we can help make that happen!
          <Link className="backLink manageTextBack" to={routes.settings}>
            <ArrowBack />
            back
          </Link>
        </div>
      </LayoutAccount>
    </Layout>
  )
}

export default GiftSubscriptions
