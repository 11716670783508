const LeftArrow = ({ onClick, className }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="14"
      height="25"
      viewBox="0 0 14 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1L2.50676 11.056C1.68517 11.8434 1.68517 13.1566 2.50676 13.944L13 24"
        stroke="#893F1D"
        strokeWidth="2"
      />
    </svg>
  )
}

export default LeftArrow
