import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'

import Router from '../Router'
import createStore from '../redux/store'

import { PUBLIC_GA_ID, PUBLIC_GA_MANAGER_ID } from '../utils/constants'
import LoadingContainer from '../components/LoadingContainer'
import '../components/Header/Header.css'
import '../components/DropDownCustom/DropDownCustom.css'
import '../components/Layout/Layout.css'
import './App.css'

let store = createStore()

const App = () => {
  return (
    <>
      <Provider store={store}>
        <HelmetProvider>
          <div className="App">
            <Helmet>
              <script async src={`https://www.googletagmanager.com/gtag/js?id=${PUBLIC_GA_ID}`} />
              <script>
                {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${PUBLIC_GA_ID}');`}
              </script>

              <script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src="//js-na1.hs-scripts.com/21150666.js"
              />
              <script>{`(function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${PUBLIC_GA_MANAGER_ID}');`}</script>
            </Helmet>
            <Router />
          </div>
        </HelmetProvider>
        <LoadingContainer />
      </Provider>
    </>
  )
}

export default App
