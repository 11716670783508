import React, { useEffect } from 'react'
import { isNil } from 'lodash'
import { connect, useDispatch } from 'react-redux'

import r from '../../utils/request'
import { ROLES } from '../../utils/constants'
import autoload from '../../utils/autoload'
import errorHandler from '../../utils/errorHandler'
import { setRoleUser, setUser } from '../../redux/actions/user'
import { setGroups } from '../../redux/actions/groups'
import {
  getUserInitailData,
  getUserInitialGroups,
  getUserRole,
  setUserRoleStorage,
  updateToken,
} from '../../utils/auth'
import { lockUiByNetwork, setLoading, setLoadingApp } from '../../redux/actions/ui'
import NetworkErrorLayer from '../NetworkErrorLayer'
import ModalTrial from '../Modals/ModalTrial'

const fetchUser = () => (dispatch) => {
  if (isNil(getUserRole()) || getUserRole() === ROLES.ROLE_GUEST) {
    setUserRoleStorage(ROLES.ROLE_GUEST)
    updateToken()
    dispatch(setUser(null))
    setUserRoleStorage()
  }
  dispatch(setRoleUser(getUserRole()))
  autoload()
  return getUserInitailData()
    .then((apiUserData) => {
      dispatch(setUser(apiUserData))
    })
    .then(getUserInitialGroups)
    .then((apiUserGroups) => dispatch(setGroups(apiUserGroups)))
    .catch((e) => console.log(e))
    .finally(() => {
      dispatch(setLoading(false))
      dispatch(setLoadingApp(true))
    })
}

const LoadingContainer = ({ lockUiByNetworkAction, showTrial }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    r.setHandlers(null, (e) => errorHandler(e, lockUiByNetworkAction)(dispatch))
    fetchUser()(dispatch)
  }, [])

  return (
    <>
      <NetworkErrorLayer />
      <ModalTrial show={showTrial} />
    </>
  )
}

export default connect(
  ({ ui }) => ({
    uiTrial: ui?.isModalTrial,
    showTrial: ui.showModalTrial,
  }),
  {
    lockUiByNetworkAction: lockUiByNetwork,
  }
)(LoadingContainer)
