import { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { isArray, isEmpty, isNumber } from 'lodash'

import { COLOR_STATUS, DATE_PATTERN } from '../../../../utils/constants'
import useFullPageLoader from '../../../../utils/hooks/useFullPageLoader'
import { TableHeader, Pagination, Search } from '../../components'
import CustomCheckbox from '../../../../components/CustomCheckbox'
import { DeleteBasket } from '../../../../components/SvgIcon'
import ButtonCustom from '../../../../components/ButtonCustom'
import '../../style.css'

const Table = ({
  dataHeader,
  dataTable,
  searchShow,
  deleteTd,
  smallTable,
  exportButton,
  exportButtonSearch,
  selectedRecipe = [],
  selectAll,
  deleteTableRow = () => {},
  exportClick,
  ITEMS_PER_PAGE = 10,
  pagination = true,
  tableClass = '',
}) => {
  const [comments, setComments] = useState([])
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState({ field: '', order: '' })

  const headers = dataHeader

  useEffect(() => {
    const getData = () => {
      showLoader()
      setComments(dataTable)
      hideLoader()
    }

    getData()
  }, [dataTable])

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
      computedComments = computedComments.filter((comment) => {
        return (
          comment?.groupName?.[0]?.name?.toLowerCase().includes(search.toLowerCase()) ||
          comment?.fullnName?.[0]?.name?.toLowerCase().includes(search.toLowerCase()) ||
          comment?.recipeName?.[0]?.name?.toLowerCase().includes(search.toLowerCase()) ||
          comment?.emailAddress?.toLowerCase().includes(search.toLowerCase()) ||
          comment?.recipesUpload?.toString()?.toLowerCase().includes(search.toLowerCase())
        )
      })
    }

    setTotalItems(computedComments.length)
    // setComments(computedComments)

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1

      computedComments = computedComments.sort((a, b) => {
        const aValue = a[sorting.field]
        const bValue = b[sorting.field]

        if (DATE_PATTERN?.test(aValue) && DATE_PATTERN?.test(bValue)) {
          return reversed * (new Date(aValue) - new Date(bValue))
        }
        if (sorting.field && isArray(aValue)) {
          return reversed * aValue[0].name.localeCompare(bValue[0].name)
        }
        if (isNumber(aValue)) {
          return reversed * (aValue - bValue)
        }
        return reversed * aValue.localeCompare(bValue)
      })
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    )
  }, [comments, currentPage, search, sorting])

  return (
    <div className={smallTable ? 'tableSmallWrap' : 'tableWrap'}>
      <div className={smallTable ? 'tableStyleGroups' : 'tableStyle'}>
        {searchShow && (
          <>
            <p className="searchTitle">Search</p>
            <div className="searchForm">
              <Search
                onSearch={(value) => {
                  setSearch(value)
                  setCurrentPage(1)
                }}
              />
              {exportButtonSearch && (
                <ButtonCustom
                  titleStyle="buttonTableTitle"
                  style="buttonTable"
                  title="Export Selected to CSV"
                  onClick={exportClick}
                />
              )}
            </div>
          </>
        )}

        <table className={`table table-striped ${tableClass}`}>
          <TableHeader
            headers={headers}
            onClick={() => selectAll()}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {commentsData.map((comment, idx) => {
              return (
                <tr key={idx}>
                  {headers.map((col, colIdx) => {
                    const customLink =
                      col.field !== 'status' ? (
                        comment[col.field]
                      ) : (
                        <p style={{ color: COLOR_STATUS?.[comment[col.field]] }}>
                          {comment[col.field]}
                        </p>
                      )
                    const checkOverflowName =
                      col.field === 'ownerName' || col.field === 'fullName' ? 'innerOwnerName' : ''
                    const checkLink = col.link ? (
                      <Link
                        className={`adminTrLink ${checkOverflowName}`}
                        to={comment[col.field][0].link}
                      >
                        {comment[col.field][0].name}
                      </Link>
                    ) : (
                      customLink
                    )

                    return (
                      <>
                        <td className="tableElement" key={`${comment?.id}-${colIdx}`}>
                          {col.checkBox ? (
                            <CustomCheckbox
                              name={comment?.id}
                              value={comment?.id}
                              checked={selectedRecipe.includes(comment?.id)}
                              onChange={comment?.selectRecipe}
                              markBrown
                            />
                          ) : (
                            checkLink
                          )}
                        </td>
                        {deleteTd && headers.length === colIdx + 1 && (
                          <div
                            onClick={() => deleteTableRow(comment?.id, comment?.user_id)}
                            className="deleteBasket"
                          >
                            <DeleteBasket />
                          </div>
                        )}
                      </>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {pagination && (
          <div className="row">
            <div className="col">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        )}
      </div>
      {exportButton && !isEmpty(dataTable) ? (
        <div className="tableButtonWrap">
          <ButtonCustom
            onClick={exportClick}
            style="adminUserResetButton"
            titleStyle="adminUserButtonText"
            title="EXPORT ALL RECIPES"
          />
        </div>
      ) : null}

      {loader}
    </div>
  )
}

export default Table
