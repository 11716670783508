import { isEmpty, isNil } from 'lodash'
import axios from 'axios'

import { getUserInfo } from '../api/auth'
import { getUserGroups } from '../api/group'
import { setShowModalTrial } from '../redux/actions/ui'
import routers from '../routers'

import { AUTH_TOKEN, ROLE_USER, ROLES } from './constants'
import localStorage from './localStorage'

export const updateToken = (token = null, nameToken = AUTH_TOKEN) => {
  if (isEmpty(token)) {
    localStorage.removeItem(nameToken)
    return delete axios.defaults.headers.common['Authorization']
  }
  localStorage.setItem(nameToken, token)
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  return token
}

export const getUserInitailData = async () => {
  const isAdmin = getUserRole() === ROLES.ROLE_ADMIN_USER
  const tokenPresence = !isNil(localStorage.getItem(AUTH_TOKEN)) && !isAdmin

  return tokenPresence
    ? await getUserInfo()
        .then(({ data }) => data)
        .catch(() => null)
    : null
}

export const getUserInitialGroups = async () => {
  const isAdmin = getUserRole() === ROLES.ROLE_ADMIN_USER
  const tokenPresence = !isAdmin && !isNil(localStorage.getItem(AUTH_TOKEN))

  return tokenPresence
    ? await getUserGroups()
        .then(({ data }) => data)
        .catch(() => [])
    : []
}

export const removeUserTokenFromLocalStorage = (nameToken) => {
  localStorage.removeItem(nameToken)
}

export const setUserRoleStorage = (role = ROLES.ROLE_GUEST) => {
  localStorage.setItem(ROLE_USER, role)
}

export const getUserRole = () => {
  return localStorage.getItem(ROLE_USER)
}

export const checkAbilityToCreate = (showModal) => (dispatch, navigate) => {
  showModal && navigate(routers.userProfile)
  return dispatch(setShowModalTrial(showModal))
}
