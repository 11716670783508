import { useEffect, useState } from 'react'
import { isEmpty, isNil, reduce } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import fileDownload from 'js-file-download'
import moment from 'moment'

import routes from '../../routers'
import { setLoading } from '../../redux/actions/ui'
import {
  changeSharedSubscribe,
  changeUserInfo,
  deleteRecipe,
  deleteUser,
  deleteUserFromGroup,
  exportAll,
  getUserById,
  pauseUser,
} from '../../api/admin'
import {
  RECIPES_CREATED_HEADER,
  DEFAULT_VALUES_CHANGE_USER,
  EMAIL_PATTERN,
  GROUPS_USER_IN_HEADER,
  ROLE_USER_IN_GROUP,
  RECIPE_NAME_PATTERN,
  MORSEL_PRO_FAMILY,
  USERS_FAMILY_SUBSCRIBE,
  TITLE_BY_ID,
} from '../../utils/constants'
import { resetPassword } from '../../api/auth'
import useDirty from '../../utils/hooks/useDirty'
import { EditIcon } from '../../components/SvgIcon'
import ModalAccount from '../../components/Modals/ModalAccount'
import UserPhoto from '../../components/UserPhoto'
import ButtonCustom from '../../components/ButtonCustom'
import NotificationChangesForm from './components/userComponents/NotificationChangesForm'
import LayoutRecipe from './components/LayoutAdmin'
import Table from './components/TableComponent'

import './style.css'

const FormChangeEndSubscription = ({ date, userId, setShowModalEndSubscription }) => {
  const { handleSubmit, control, setError } = useForm()
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD')
  const oldDate = moment(date).format('YYYY-MM-DD')

  const changeDate = (data) => {
    changeUserInfo(userId, data)
      .then(() => setShowModalEndSubscription(true))
      .catch(() => setError('end_subscription', { type: 'focus' }, { shouldFocus: true }))
  }

  return !isEmpty(date) ? (
    <form onSubmit={handleSubmit(changeDate)} className="formChangeDate">
      <Controller
        name="end_subscription"
        control={control}
        defaultValue={oldDate}
        render={({ field, fieldState: { error } }) => (
          <input
            {...field}
            min={tomorrow}
            style={error ? { border: '3px solid red' } : {}}
            className="datePicker"
            type="date"
          />
        )}
      />
      <ButtonCustom style="adminUserPauseButton buttonChangeDate" title="Change" />
    </form>
  ) : null
}

const AdminUser = () => {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const [userData, setUserData] = useState({})
  const [userGroups, setUserGroups] = useState([])
  const [allRecipes, setAllRecipes] = useState([])
  const [userFamily, setUserFamily] = useState([])
  const [modalDeleteUser, setModalDeleteUser] = useState(false)
  const [showModalEndSubscription, setShowModalEndSubscription] = useState(false)
  const { handleSubmit, control, formState, reset, setValue } = useForm()
  const { filterDirtyFields } = useDirty()
  const { userId } = useParams()
  const planUser = !isEmpty(userData?.settings?.plan_id)
    ? TITLE_BY_ID?.[userData?.settings?.plan_id]
    : 'Trial'

  const onSubmitChangeUserInfo = (data) => {
    const newData = filterDirtyFields({ dirtyFields: formState?.dirtyFields, data })
    changeUserInfo(userId, newData)
      .then(() => reset(data))
      .catch((e) => console.log(e))
  }

  const deleteUserById = () => {
    deleteUser(userId)
      .then(() => setModalDeleteUser(true))
      .then(() => navigation(routes.adminUserList))
      .catch((e) => console.log(e))
  }

  const deleteRecipeUser = (idRecipe) => {
    deleteRecipe(idRecipe)
      .then(() => setAllRecipes((oldState) => oldState?.filter((item) => item?.id !== idRecipe)))
      .catch((e) => console.log(e))
  }

  const deleteUserFromGroupInTable = (idGroup) => {
    deleteUserFromGroup(userId, idGroup)
      .then(() => setUserGroups((oldState) => oldState?.filter((group) => group?.id !== idGroup)))
      .catch((e) => console.log(e))
  }

  const deleteUserFamily = (idUser) => {
    const emails = userFamily?.filter(({ id }) => id !== idUser)
    changeSharedSubscribe({ id: userId, emails })
      .then(() => setUserFamily(emails))
      .catch((e) => console.log(e))
  }

  const exportAllRecipes = () => {
    exportAll({ user_id: userId })
      .then((data) => fileDownload(data, `${userData?.fullname}.xlsx`))
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    dispatch(setLoading(true))
    getUserById(userId)
      .then(({ data }) => {
        const filterUserInfo = filterDirtyFields({ dirtyFields: DEFAULT_VALUES_CHANGE_USER, data })
        const allRecipes = data?.recipes?.map((item) => ({
          id: item?.id,
          name: [
            {
              name: !isNil(item?.name) ? item?.name : '',
              link: `${routes.adminRecipe}/${item?.id}`,
            },
          ],
          groupsIn: !isNil(item?.groups)
            ? item?.groups?.map((group) => ` ${group}`).toString()
            : '',
          category: !isNil(item?.categories)
            ? item?.categories?.map((category) => ` ${category}`).toString()
            : '',
        }))
        const allGroups = data?.groups?.map((group) => ({
          id: group?.id,
          name: [{ name: group?.name, link: `${routes.adminGroup}/${group?.id}` }],
          status: ROLE_USER_IN_GROUP?.[group?.role],
          dataJoined: moment(group?.joined_at).format('MM/DD/YYYY'),
        }))
        const allUserFamily = data?.settings?.sharedUsers?.map(({ email }, id) => ({ email, id }))

        setUserData(data)
        setUserFamily(allUserFamily)
        setAllRecipes(allRecipes)
        setUserGroups(allGroups)
        reset({ ...DEFAULT_VALUES_CHANGE_USER, ...filterUserInfo })

        return reduce(
          filterUserInfo,
          (res, value, key) => {
            setValue(key, value, { shouldDirty: true })
          },
          {}
        )
      })
      .catch(() => navigation(routes.notFound))
      .finally(() => dispatch(setLoading(false)))
  }, [])

  return (
    <LayoutRecipe>
      <div className="adminUserContent">
        <div className="adminUserWrap">
          <div className="adminUserNameWrap">
            <div className="adminUserAvatar">
              <UserPhoto image={userData?.avatar} classStylePhoto="photoUserChange" />
            </div>

            <form onSubmit={handleSubmit(onSubmitChangeUserInfo)} className="adminUserName">
              <div className="formChaneName mb-16">
                <Controller
                  name="fullname"
                  control={control}
                  rules={{
                    required: false,
                    pattern: {
                      value: RECIPE_NAME_PATTERN,
                    },
                    validate: {
                      value: (value) => !isEmpty(formState?.dirtyFields) && !isEmpty(value),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <input
                      {...field}
                      style={error ? { borderColor: 'red' } : {}}
                      placeholder="FIRST LAST NAME"
                      type="text"
                      className="inputTable"
                    />
                  )}
                />
                <button type="submit" className="btnChangeName">
                  <EditIcon className="changePencilName" />
                </button>
              </div>
              <div className="formChaneName mb-16">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: false,
                    validate: {
                      value: (value) => !isEmpty(formState?.dirtyFields) && !isEmpty(value),
                    },
                    pattern: {
                      value: EMAIL_PATTERN,
                      message: 'This input is emails only',
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <input
                      {...field}
                      style={error ? { borderColor: 'red' } : {}}
                      placeholder="email@email.com"
                      type="text"
                      className="inputTable"
                    />
                  )}
                />
                <button type="submit" className="btnChangeName">
                  <EditIcon className="changePencilName" />
                </button>
              </div>
            </form>
          </div>
          <div className="adminUserDate">
            <p>DATE JOINED: {moment(userData?.created_at).format('MM/DD/YYYY')}</p>
          </div>
        </div>
        <NotificationChangesForm allNotification={userData?.notifications} userId={userId} />
        <div>
          <p className="adminUserTitle">Subscription Info</p>
          <p className="adminUserText">Current Plan: {planUser}</p>
          <br />
          <div className="innerFormChangeDate">
            <span>Payment Due Date:</span>
            <FormChangeEndSubscription
              date={userData?.end_subscription}
              userId={userId}
              setShowModalEndSubscription={setShowModalEndSubscription}
            />
          </div>
          <div className="adminUserWrapButtons">
            <ButtonCustom
              onClick={() => resetPassword({ email: userData?.email })}
              title="RESET PASSWORD"
              titleStyle="adminUserButtonText"
              style="adminUserResetButton"
            />
            <ButtonCustom
              onClick={() => setModalDeleteUser(true)}
              style="adminUserDeleteButton"
              titleStyle="adminUserButtonText"
              title="DELETE USER"
            />
            <ButtonCustom
              onClick={() => pauseUser(userId).catch((e) => console.log(e))}
              style="adminUserPauseButton"
              titleStyle="adminUserButtonText"
              title="PAUSE USER"
            />
          </div>
        </div>
        <div>
          <p className="tableTitle">GROUPS IN</p>
          <Table
            dataTable={userGroups}
            dataHeader={GROUPS_USER_IN_HEADER}
            smallTable
            deleteTd
            deleteTableRow={deleteUserFromGroupInTable}
          />
        </div>
        <div>
          <p className="tableTitle">RECIPES CREATED</p>
          <Table
            dataTable={allRecipes}
            dataHeader={RECIPES_CREATED_HEADER}
            smallTable
            deleteTd
            exportButton
            exportClick={exportAllRecipes}
            deleteTableRow={deleteRecipeUser}
          />
        </div>
        {MORSEL_PRO_FAMILY === userData?.settings?.plan_id && (
          <div>
            <p className="tableTitle">YOUR FAMILY USERS</p>
            {!isEmpty(userFamily) ? (
              <Table
                dataTable={userFamily}
                dataHeader={USERS_FAMILY_SUBSCRIBE}
                smallTable
                deleteTd
                pagination={false}
                tableClass="tableUsersSubscribe"
                deleteTableRow={deleteUserFamily}
              />
            ) : (
              <p className="innerFormChangeDate">You don't have family subscribe users</p>
            )}
          </div>
        )}
      </div>

      <ModalAccount show={modalDeleteUser} onHide={() => setModalDeleteUser(false)}>
        <div className="innerModalRemoveUser">
          <p className="textModalRemoveFirst">
            Are you sure you want to delete {userData?.fullname} ?*
          </p>
          <button onClick={deleteUserById} className="removeUserButton">
            Yes, delete user
          </button>
        </div>
      </ModalAccount>
      <ModalAccount
        show={showModalEndSubscription}
        onHide={() => setShowModalEndSubscription(false)}
      >
        <div className="innerModalRemoveUser">
          <button
            onClick={() => setShowModalEndSubscription(false)}
            style={{ maxWidth: 500, fontWeight: 600, margin: '0 auto' }}
            className="buttonWrapper"
          >
            Success change date
          </button>
        </div>
      </ModalAccount>
    </LayoutRecipe>
  )
}

export default AdminUser
