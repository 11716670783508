import { loginAdmin as loginAdminApi } from '../api/admin'

import routes from '../routers'
import { setRoleUser, setUser } from '../redux/actions/user'
import { ROLES } from '../utils/constants'
import { setUserRoleStorage, updateToken } from '../utils/auth'

export const loginAdmin = (params, loginFormRef, navigation) => (dispatch) => {
  updateToken()
  loginAdminApi(params)
    .then(({ data }) => {
      updateToken(data?.token)
      setUserRoleStorage(ROLES.ROLE_ADMIN_USER)
      dispatch(setRoleUser(ROLES.ROLE_ADMIN_USER))
      dispatch(setUser(null))
      navigation(routes.adminUserList)
    })
    .catch(() => {
      loginFormRef.current.setError('password', {
        type: 'manual',
        message: 'Password is incorrect',
      })
    })
}
