import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { isEmpty, isNil } from 'lodash'
import { connect, useDispatch } from 'react-redux'

import { updateAvatar } from '../../service/user'
import { updateFullName } from '../../api/user'
import { setUser } from '../../redux/actions/user'
import { setLoading } from '../../redux/actions/ui'
import { ChangePencil, UserWithoutPhoto } from '../SvgIcon'

import './styles.css'
import { RECIPE_NAME_PATTERN } from '../../utils/constants'

const LayoutAccount = ({ user, children }) => {
  const dispatch = useDispatch()
  const [avatar, setAvatar] = useState(user?.avatar)
  const [checkChangeName, setCheckChangeName] = useState(false)
  const { handleSubmit, control, formState, reset } = useForm()

  const changeFullName = (dataUser) => {
    updateFullName(dataUser)
      .then(({ data }) => {
        dispatch(setUser(data))
        setCheckChangeName(false)
        reset(dataUser)
      })
      .catch((e) => console.log(e))
  }

  const changeAvatar = (e) => {
    const file = e.target?.files?.[0]
    dispatch(setLoading(true))
    updateAvatar(file)(dispatch)
      .then(() => {
        setAvatar(URL.createObjectURL(file))
      })
      .catch((e) => console.log(e))
      .finally(() => dispatch(setLoading(false)))
  }

  useEffect(() => reset({ fullname: user?.fullname }), [])

  return (
    <div className="innerAccount">
      <div className="innerAccountInfoUser">
        <h1 className="accountSettingsTitle">Account Settings</h1>
        <div className="wrapperPhotoUser">
          {!isNil(avatar) ? (
            <img className="accountPhoto" src={avatar} alt="" />
          ) : (
            <UserWithoutPhoto className="accountPhoto" />
          )}
          <div className="innerChangePencil">
            <input
              accept="image/*"
              onChange={changeAvatar}
              type="file"
              className="changePhotoInput"
            />
            <ChangePencil classStyle="pencilPhoto" />
          </div>
        </div>
        <form
          className="formChaneName"
          style={!checkChangeName ? { alignItems: 'center' } : {}}
          onSubmit={handleSubmit(changeFullName)}
        >
          {!checkChangeName ? (
            <>
              <p className="changeName changeNameText">{user?.fullname}</p>
              <div style={{ cursor: 'pointer' }} onClick={() => setCheckChangeName(true)}>
                <ChangePencil className="changePencilName" big />
              </div>
            </>
          ) : (
            <>
              <Controller
                name="fullname"
                control={control}
                defaultValue={user?.fullname}
                rules={{
                  required: true,
                  pattern: { value: RECIPE_NAME_PATTERN },
                  validate: { value: () => !isEmpty(formState.dirtyFields) },
                }}
                render={({ field }) => (
                  <>
                    <div>
                      <input {...field} type="text" className="changeName" />
                    </div>
                    <button
                      onClick={() => isEmpty(formState.dirtyFields) && setCheckChangeName(false)}
                      type="submit"
                      className="btnChangeName"
                    >
                      save
                    </button>
                  </>
                )}
              />
            </>
          )}
        </form>
      </div>
      {children}
    </div>
  )
}

export default connect(({ user }) => ({ user: user.data }))(LayoutAccount)
