import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { delay } from 'bluebird'
import { isEmpty, isObject } from 'lodash'

import { URL_PATTERN } from '../../../utils/constants'
import useDirty from '../../../utils/hooks/useDirty'
import ButtonCustom from '../../../components/ButtonCustom'
import InputSimple from '../../../components/InputSimple'
import CustomInputFile from '../../../components/CustomInputFile'
import { advertisingAd, deleteAd, getAdvertising } from '../../../api/admin'

const ChangeAdvertising = ({
  title = '',
  innerClass = '',
  textWidth = '',
  idName = '',
  is_desktop,
}) => {
  const [successSave, setSuccessSave] = useState(false)
  const { handleSubmit, control, reset, formState } = useForm()
  const { filterDirtyFields } = useDirty()

  const changeAdvertising = (data) => {
    const newData = filterDirtyFields({ dirtyFields: formState?.dirtyFields, data })
    advertisingAd({ ...newData, is_desktop })
      .then(() => {
        reset({
          ...data,
          image: isObject(data?.image) ? URL.createObjectURL(data?.image) : data?.image,
        })
      })
      .then(() => {
        setSuccessSave(true)
        delay(5000).then(() => setSuccessSave(false))
      })
  }

  const resetForm = () => {
    deleteAd(is_desktop).then(() => reset({ image: '', link: '' }))
  }

  useEffect(() => {
    getAdvertising(is_desktop).then(({ data }) => reset({ image: data?.image, link: data?.link }))
  }, [])

  return (
    <form onSubmit={handleSubmit(changeAdvertising)} className="adminAsBlock">
      <p className="recipeTitle">{title}</p>
      <div className="adFlow">
        <Controller
          name="image"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            validate: {
              value: () => !isEmpty(formState?.dirtyFields),
            },
          }}
          render={({ field }) => (
            <CustomInputFile
              {...field}
              textButton="Upload ad"
              buttonClass="buttonUploadAd"
              classInner={innerClass}
              idName={idName}
            />
          )}
        />
        <p>{textWidth} px</p>
        {successSave ? <p className="successSaveAd">Success saved</p> : null}
      </div>
      <Controller
        name="link"
        control={control}
        defaultValue=""
        rules={{
          required: true,
          pattern: { value: URL_PATTERN },
          validate: { value: () => !isEmpty(formState?.dirtyFields) },
        }}
        render={({ field }) => (
          <InputSimple
            {...field}
            className="adminAdInputWrap"
            styleInput="adminAdInput"
            nameInputPlaceholder="Enter website link here"
          />
        )}
      />
      <ButtonCustom style="saveAdButton" title="Save" />
      <ButtonCustom onClick={resetForm} style="saveAdButton removeBtnAdvertising" title="Remove" />
    </form>
  )
}

export default ChangeAdvertising
