import React from 'react'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'

import { ALL_ROLES, ROLES, ROLES_ONLY_USER } from './utils/constants'
import routers, { invitedUser, inviteFriendsToGroup, manageGroup } from './routers'
import InvitedUser from './components/InvitedUser'
import LoadingSpinner from './components/LoadingSpinner'
import AdminLogin from './pages/AdminPanel/AdminLogin'
import AdminAd from './pages/AdminPanel/AdminAd'
import AdminUser from './pages/AdminPanel/AdminUser'
import AdminUserList from './pages/AdminPanel/AdminUserList'
import AdminRecipe from './pages/AdminPanel/AdminRecipe'
import AdminRecipeList from './pages/AdminPanel/AdminRecipeList'
import AdminGroup from './pages/AdminPanel/AdminGroup'
import AdminGroupList from './pages/AdminPanel/AdminGroupList'
import AdminVideo from './pages/AdminPanel/AdminVideo'
import AccountChangePassword from './pages/AccountChangePassword'
import ManageNotifications from './pages/ManageNotifications'
import AccountSettingsMenu from './pages/AccountSettingsMenu'
import ManageSubscription from './pages/ManageSubscription'
import AccountSettingsGroup from './pages/AccountSettingsGroup'
import AddRecipeSecond from './pages/AddRecipe/AddRecipeSecond'
import AddRecipeThird from './pages/AddRecipe/AddRecipeThird'
import GiftSubscriptions from './pages/GiftSubscriptions'
import CategoriesRecipe from './pages/CategoriesRecipe'
import AccountYourFams from './pages/AccountYourFams'
import InviteFamMember from './pages/InviteFamMember'
import PurchaseBook from './pages/PurchaseBook'
import DraftRecipe from './pages/DraftRecipe'
import AddRecipe from './pages/AddRecipe'
import Category from './pages/Category'
import Dashboard from './pages/Dashboard'
import Profile from './pages/Profile'
import SignIn from './pages/Home'
import SignUp from './pages/Home/SignUp'
import FamGroup from './pages/Home/SignUp/components/FamGroup'
import FamMembers from './pages/Home/SignUp/components/FamMembers'
import CheckEmail from './pages/Home/SignUp/components/CheckEmail'
import ResetPassword from './pages/Home/ResetPassword'
import SuccessReset from './pages/Home/ResetPassword/components/SuccessReset'
import NewPassword from './pages/Home/ResetPassword/components/NewPassword'
import ScrollTop from './components/ScrollTop'
import CheckSubscription from './pages/CheckSubscription'
import RedirectPage from './components/RedirectPage'

import Page404 from './pages/404'
import SuccessPurchaseBook from './pages/SuccessPurchaseBook'

export const pages = [
  {
    path: routers.recipeDraft,
    name: 'Draft',
    Component: DraftRecipe,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: routers.userProfile,
    name: 'Profile',
    Component: Profile,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: `/${routers.dashboard}/:slugRecipe-:recipeId`,
    name: 'DashboardRecipe',
    Component: Dashboard,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: routers.settings,
    name: 'Settings',
    Component: AccountSettingsMenu,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: routers.notification,
    name: 'Notification',
    Component: ManageNotifications,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: routers.manageFams,
    name: 'Manage-Fams',
    Component: AccountYourFams,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: routers.changePassword,
    name: 'Change-Password',
    Component: AccountChangePassword,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: routers.manageSubscription,
    name: 'Manage-Subscription',
    Component: ManageSubscription,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: routers.giftSubscriptions,
    name: 'Gift-Subscriptions',
    Component: GiftSubscriptions,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: routers.purchaseBook,
    name: 'Purchase Book',
    Component: PurchaseBook,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: routers.invite,
    name: 'Invite Friends',
    Component: InviteFamMember,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: routers.addRecipe,
    name: 'AddRecipe',
    Component: AddRecipe,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: routers.addRecipeSecond,
    name: 'AddRecipeSecond',
    Component: AddRecipeSecond,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: routers.addRecipeThird,
    name: 'AddRecipeThird',
    Component: AddRecipeThird,
    exact: true,
    role: ROLES_ONLY_USER,
  },
  {
    path: manageGroup(':groupId'),
    name: 'Group-Settings',
    Component: AccountSettingsGroup,
    exact: true,
    role: ROLES_ONLY_USER,
  },

  {
    path: routers.adminLogin,
    name: 'AdminLogin',
    Component: AdminLogin,
    exact: true,
    role: ALL_ROLES,
  },
  {
    path: routers.adminUserList,
    name: 'AdminUserList',
    Component: AdminUserList,
    exact: true,
    role: [ROLES.ROLE_ADMIN_USER],
  },
  {
    path: `${routers.adminUser}/:userId`,
    name: 'AdminUser',
    Component: AdminUser,
    exact: true,
    role: [ROLES.ROLE_ADMIN_USER],
  },
  {
    path: routers.adminGroupList,
    name: 'AdminGroupList',
    Component: AdminGroupList,
    exact: true,
    role: [ROLES.ROLE_ADMIN_USER],
  },
  {
    path: `${routers.adminGroup}/:idGroup`,
    name: 'AdminGroup',
    Component: AdminGroup,
    exact: true,
    role: [ROLES.ROLE_ADMIN_USER],
  },
  {
    path: routers.adminRecipeList,
    name: 'AdminRecipeList',
    Component: AdminRecipeList,
    exact: true,
    role: [ROLES.ROLE_ADMIN_USER],
  },
  {
    path: `${routers.adminRecipe}/:idRecipe`,
    name: 'AdminRecipe',
    Component: AdminRecipe,
    exact: true,
    role: [ROLES.ROLE_ADMIN_USER],
  },
  {
    path: routers.adminAd,
    name: 'AdminAd',
    Component: AdminAd,
    exact: true,
    role: [ROLES.ROLE_ADMIN_USER],
  },
  {
    path: routers.adminVideo,
    name: 'AdminVideo',
    Component: AdminVideo,
    exact: true,
    role: [ROLES.ROLE_ADMIN_USER],
  },
  {
    path: invitedUser(':idGroup'),
    name: 'InvitedUser',
    Component: InvitedUser,
    exact: true,
    role: ALL_ROLES,
  },
  {
    path: routers.signIn,
    name: 'SignIn',
    Component: SignIn,
    exact: true,
    role: ALL_ROLES,
  },
  {
    path: routers.signUp,
    name: 'SignUp',
    Component: SignUp,
    exact: true,
    role: ALL_ROLES,
  },
  {
    path: routers.createGroup,
    name: 'CreateGroup',
    Component: FamGroup,
    exact: false,
    role: ALL_ROLES,
  },
  {
    path: inviteFriendsToGroup(':idGroup', ':slug'),
    name: 'GroupMembers',
    Component: FamMembers,
    exact: false,
    role: ALL_ROLES,
  },
  {
    path: routers.checkEmail,
    name: 'CheckEmail',
    Component: CheckEmail,
    exact: true,
    role: ALL_ROLES,
  },
  {
    path: routers.resetPassword,
    name: 'ResetPassword',
    Component: ResetPassword,
    exact: true,
    role: ALL_ROLES,
  },
  {
    path: routers.successReset,
    name: 'SuccessReset',
    Component: SuccessReset,
    exact: true,
    role: ALL_ROLES,
  },
  {
    path: routers.newPassword,
    name: 'NewPassword',
    Component: NewPassword,
    exact: false,
    role: ALL_ROLES,
  },
  {
    path: routers.checkSubscribe,
    name: 'CheckSubscribe',
    Component: CheckSubscription,
    exact: false,
    role: [ROLES.ROLE_USER],
  },
  {
    path: routers.successPurchaseBook,
    name: 'SuccessPurchaseBook',
    Component: SuccessPurchaseBook,
    exact: false,
    role: ALL_ROLES,
  },
]
const Router = ({ isLoading, loadApp, userRole }) => {
  if (!loadApp) {
    return <LoadingSpinner />
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          {pages.map((route) => (
            <Route
              key={route.name}
              exact={route.exact}
              path={route.path}
              element={route?.role?.includes(userRole) ? <route.Component /> : <RedirectPage />}
            />
          ))}

          <Route
            path={'/group/:idSlug-:idGroup'}
            element={ROLES_ONLY_USER.includes(userRole) ? <Outlet /> : <RedirectPage />}
          >
            <Route index element={<CategoriesRecipe />} />
            <Route path={`profile-:slug-:idUser`} element={<Profile />} />
            <Route path={routers.category} element={<Outlet />}>
              <Route index element={<Category />} />
              <Route path={`:groupSlug-:recipeId`} element={<Dashboard />} />
            </Route>
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
        <ScrollTop />
      </BrowserRouter>
      {isLoading ? <LoadingSpinner /> : null}
    </>
  )
}

export default connect(({ ui, user }) => ({
  isLoading: ui.isLoading,
  loadApp: ui.appIsLoaded,
  userRole: user.userRole,
}))(Router)
