import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { isNil, isEmpty } from 'lodash'
import { Dropdown } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux'

import routes, { categoriesGroup } from '../../routers'
import { logout } from '../../service/auth'
import { DROPDOWN_HEADER_RECIPES, NAVIGATION_HEADER_MOBILE_MENU } from '../../utils/constants'
import { InviteFamIcon, UserWithoutPhoto } from '../SvgIcon'
import DropDownCustom from '../DropDownCustom'

const MenuBtn = ({ openMenu, setOpenMenu }) => {
  return (
    <div onClick={setOpenMenu}>
      <div className={`btnBurger ${openMenu ? 'active' : ''}`}>
        <span className="spanBtnBurger" />
        <span className="spanBtnBurger" />
        <span className="spanBtnBurger" />
      </div>
    </div>
  )
}

const UserPhoto = ({ avatar, fullname }) => {
  return (
    <div className="innerUserHeaderInfo">
      {!isNil(avatar) ? (
        <img src={avatar} className="userAvatar" alt="" />
      ) : (
        <UserWithoutPhoto className="userAvatar" />
      )}
      {!isNil(fullname) ? <p className="userName">{fullname}</p> : null}
    </div>
  )
}

const DropDownMobileMenu = () => {
  return NAVIGATION_HEADER_MOBILE_MENU?.map(({ text, routes, target }) => (
    <>
      {isEmpty(target) ? (
        <Link to={routes} className="navigateMenuItem">
          {text}
        </Link>
      ) : (
        <a
          target="_blank"
          rel="noopener noreferrer"
          key={text}
          className="navigateMenuItem"
          href={routes}
        >
          {text}
        </a>
      )}
    </>
  ))
}

const Header = ({ user, groups }) => {
  const [openMenu, setOpenMenu] = useState(false)
  const dispatch = useDispatch()
  const navigation = useNavigate()

  const NAVIGATION_HEADER_PROFILE = [
    { name: 'Your Account', onClick: () => navigation(routes.settings) },
    { name: 'Sign Out', onClick: () => logout(navigation)(dispatch) },
  ]

  return (
    <div className="innerHeaderComponent">
      <header className="header">
        <div className="headerInner headerContainer">
          <div className="headerItemFirst">
            <div className="wrapperFirstHeaderItem">
              <Link className="innerLogoSite" to={routes.userProfile}>
                <img src="/logo.svg" alt="Logo" className="headerLogo" />
              </Link>
              <div className="innerDropDownHeader">
                <DropDownCustom
                  link="Recipes"
                  globalClassName="dropTheme recipesLink"
                  styleToggleList="wrapperAllGroupsHeader"
                >
                  {DROPDOWN_HEADER_RECIPES.map(({ title, route }, index) => (
                    <Link
                      key={index}
                      to={route}
                      className="groupsItem innerLinkGroups wrapperAllGroupsHeader"
                    >
                      {title}
                    </Link>
                  ))}
                </DropDownCustom>
              </div>
            </div>
            <div className="innerDropDownHeader">
              <DropDownCustom
                link="Your groups"
                globalClassName="dropTheme"
                styleToggleList="wrapperAllGroupsHeader"
              >
                {groups?.map((item) => (
                  <Dropdown.Item
                    key={item?.id}
                    onClick={() => navigation(categoriesGroup(item?.slug, item?.id))}
                    className="groupsItem innerLinkGroups wrapperAllGroupsHeader"
                  >
                    {item?.name}
                  </Dropdown.Item>
                ))}
                <Link
                  to={routes.manageFams}
                  className="groupsItem innerLinkGroups wrapperAllGroupsHeader"
                >
                  + Create Group
                </Link>
              </DropDownCustom>
            </div>
          </div>
          <div className="headerItemSecond">
            <Link to={routes.invite} className="inviteBtn">
              Invite Your Fam <InviteFamIcon />
            </Link>
            <div className="wrapperItemHeader">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="headerLinkHelp"
                href="https://getmorsel.com/help-guides/"
              >
                Help
              </a>
              <DropDownCustom
                globalClassName="dropTheme"
                styleToggleList="userHeaderDropDown"
                link={<UserPhoto avatar={user?.avatar} fullname={user?.fullname} />}
              >
                {NAVIGATION_HEADER_PROFILE?.map(({ name, onClick }) => (
                  <Dropdown.Item
                    key={name}
                    onClick={onClick}
                    className="groupsItem innerLinkGroups userHeaderDropDown"
                  >
                    {name}
                  </Dropdown.Item>
                ))}
              </DropDownCustom>
            </div>
          </div>
        </div>
        <div className="headerContainer mobileHeader">
          <div className="innerMobileHeader">
            <Link to={routes.settings}>
              <UserPhoto avatar={user?.avatar} />
            </Link>
            <Link className="innerLogoSite" to={routes.userProfile}>
              <img src="/logo.svg" alt="Logo" className="headerLogo" />
            </Link>
            <MenuBtn openMenu={openMenu} setOpenMenu={() => setOpenMenu((oldState) => !oldState)} />
          </div>
        </div>
      </header>
      <div className={`innerHeaderMenu ${openMenu ? 'openMenu' : ''}`}>
        <DropDownMobileMenu />
      </div>
    </div>
  )
}

export default connect(({ user, groups }) => ({ user: user.data, groups: groups.data }))(Header)
