const SendIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.77926 3.68924C2.77926 3.38542 2.77926 2.77779 3.85693 2.77778C5.2425 2.77776 20.6378 10.6771 21.7154 11.5885C22.5776 12.3177 22.1758 13.1076 21.714 13.4115C20.6366 14.3226 5.09527 22.2175 3.85545 22.2222C2.77778 22.2222 2.77778 21.6146 2.77778 21.3108L3.85653 14.6267C3.97937 13.8976 4.31853 13.7153 4.47275 13.7153L13.7099 12.8038C13.8125 12.8038 14.0178 12.7431 14.0178 12.5C14.0178 12.2569 13.8125 12.1962 13.7099 12.1962L4.47275 11.2847C4.31853 11.2847 3.97937 11.1024 3.85653 10.3733C3.49594 8.17005 2.77926 3.99306 2.77926 3.68924Z"
        fill="white"
      />
    </svg>
  )
}

export default SendIcon
