import { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'

import CustomToggle from '../CustomToggle'
import DropdownInput from '../InputDropdown'

const DropDownCustom = ({
  settings = {},
  onChange = () => {},
  onClickMenu = () => {},
  setLinkGroup = () => {},
  ItemMenu = [],
  custom = false,
  link = false,
  blockChangeTitle = false,
  value = '',
  title = '',
  globalClassName = '',
  styleToggleList = '',
  selectedWithValue = false,
  children,
}) => {
  const [selectedValue, setSelectedValue] = useState(selectedWithValue && value)
  const checkTitle = value ? ItemMenu[value]?.name : title
  const [titleValue, setTitleValue] = useState('')

  useEffect(() => {
    checkTitle && setTitleValue(checkTitle)
    selectedWithValue && setSelectedValue(value)
  }, [title])

  const CustomToggleItem = ({ ...rest }) => {
    return <CustomToggle custom={custom} {...rest} />
  }
  const handleChange = (dropDownValue, nameGroup, linkGroup) => {
    onChange(dropDownValue)
    !blockChangeTitle && setTitleValue(nameGroup)
    onClickMenu(dropDownValue, nameGroup)
    setSelectedValue(true)
    setLinkGroup(linkGroup)
  }

  return (
    <Dropdown align={{ lg: 'start' }} className={globalClassName}>
      <Dropdown.Toggle as={CustomToggleItem}>
        {link ? (
          link
        ) : (
          <DropdownInput {...settings} selectedValue={selectedValue} title={titleValue} />
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className={`customDropdownMenu ${styleToggleList}`}>
        {ItemMenu.map((item, index) => (
          <Dropdown.Item className={`innerLinkGroups ${styleToggleList}`} key={index}>
            {custom ? (
              <div
                onClick={() => handleChange(item?.id, item?.name, item?.inviteLink)}
                className="groupsItemSecond"
              >
                {item?.name}
              </div>
            ) : (
              <div onClick={item?.onClick} className="groupsItem">
                {item?.title}
              </div>
            )}
          </Dropdown.Item>
        ))}
        {children}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DropDownCustom
