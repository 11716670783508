import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import Button from '../ButtonCustom'

import './styles.css'

const NetworkErrorLayer = ({ networkLockFlag }) => {
  if (!networkLockFlag) {
    return null
  }

  return (
    <Modal size="lg" fullscreen show={networkLockFlag}>
      <div className="errorPage">
        <div>
          <div className="innerErrorModal">404</div>
          <p className="textPageError">Sorry, the Page not found</p>
          <Button title="Reload Page" onClick={() => window.location.reload()} />
        </div>
      </div>
    </Modal>
  )
}

export default connect(({ ui }) => ({ networkLockFlag: ui.networkLockFlag }))(NetworkErrorLayer)
