import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'

import { NAVIGATION_LINK } from '../../../../utils/constants'
import { NavArrow } from '../../../../components/SvgIcon'
import { logout } from '../../../../service/auth'
import '../../style.css'

const LayoutRecipe = ({ children }) => {
  const navigation = useNavigate()
  const dispatch = useDispatch()

  return (
    <div className="contentAdminPanel">
      <div className="navigationPanel">
        <p className="recipeTitle">Admin Panel</p>
        <div className="navigationContent">
          {NAVIGATION_LINK.map((item) => (
            <NavLink
              style={({ isActive }) => (isActive ? { background: '#E0E0E0' } : {})}
              to={item.link}
              key={item.title}
              className="navigationItem"
            >
              <p className="navigationItemTitle">{item.title}</p>
              <div className="navigationItemArrow">
                <NavArrow />
              </div>
            </NavLink>
          ))}
          <div onClick={() => logout(navigation)(dispatch)} className="navigationItem">
            <div className="navigationItemArrow arrowLogout">
              <NavArrow />
            </div>
            <p className="navigationItemTitle navigationItemArrow">Logout</p>
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}

export default LayoutRecipe
