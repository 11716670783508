import { DATA_PLANS } from '../../utils/constants'
import { Tick } from '../SvgIcon'

import './styles.css'

const PlanList = ({ redirectToChargebee }) => {
  const Plan = ({ title, price, time, dataList, type, discount }) => {
    return (
      <div className="wrapperPlant">
        <p className="titlePlan">{title}</p>
        <p className="pricePlan">
          <span>${price}</span> / {time}
        </p>
        <ul className="listOpportunitiesPlan">
          {dataList.map((text, index) => (
            <li key={index} className="itemOpportunities">
              <Tick />
              {text}
            </li>
          ))}
        </ul>
        <button onClick={() => redirectToChargebee(type)} className="subscribePlan">
          Upgrade
        </button>
        {discount ? <p className="discountPricing">{discount}% off</p> : null}
      </div>
    )
  }

  return <div className="innerAllPlans">{DATA_PLANS.map(Plan)}</div>
}

export default PlanList
