import { useEffect, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { isNil } from 'lodash'
import { Link } from 'react-router-dom'

import { MORSEL_PRO, MORSEL_PRO_FAMILY, TITLE_BY_ID } from '../../utils/constants'
import routes from '../../routers'
import { setLeaveFromFamilyPlan, setNewPlanId } from '../../redux/actions/user'
import { setLoading } from '../../redux/actions/ui'
import { checkSubscribe, subscribe } from '../../api/trial'
import { ArrowBack, ArrowNavigation } from '../../components/SvgIcon'
import Layout from '../../components/Layout'
import LayoutAccount from '../../components/LayoutAccount'
import FormAddons from '../../components/FormAddons'

import './styles.css'
import { leaveFromFamilyPlan } from '../../api/user'
import PlanList from '../../components/PlanList'

const ManageSubscription = ({ user }) => {
  const dispatch = useDispatch()
  const [countFamilyUsers, setCountFamilyUsers] = useState(user?.settings?.sharedUsers?.length ?? 0)
  const [hasSubscription, setHasSubscription] = useState(false)
  const [parentFamily, setParentFamily] = useState('')
  const [typeSubscription, setTypeSubscription] = useState(MORSEL_PRO_FAMILY)
  const typeSubscriptionFamily = [typeSubscription].includes(MORSEL_PRO_FAMILY)
  const checkEmptyFamilyPro = [typeSubscription].includes(MORSEL_PRO_FAMILY) ? (
    <p>{countFamilyUsers} of 6</p>
  ) : (
    ''
  )

  const redirectToChargebee = (type) => {
    subscribe(type ?? typeSubscription)
      .then(({ data }) => {
        dispatch(setNewPlanId(typeSubscription))
        window.open(data?.url, '_blank')
      })
      .catch((e) => console.log(e))
  }

  const leaveFromFamilySubscribe = () => {
    dispatch(setLoading(true))
    leaveFromFamilyPlan()
      .then(() => {
        dispatch(setLeaveFromFamilyPlan())
      })
      .catch((e) => console.log(e))
      .finally(() => dispatch(setLoading(false)))
  }

  const manageSubscription = () => {
    checkSubscribe()
      .then(({ data }) => {
        window.open(data?.access_url, '_blank')
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    dispatch(setLoading(true))
    checkSubscribe()
      .then(({ data }) => {
        setTypeSubscription(user?.settings?.plan_id ?? MORSEL_PRO)
        setHasSubscription(data?.has_subscription)
        setParentFamily(data?.gifterName)
      })
      .catch((e) => console.log(e))
      .finally(() => dispatch(setLoading(false)))
  }, [])

  return (
    <div className="wrapperSubscription">
      <Layout title="Manage Subscriptions">
        <LayoutAccount>
          <h1 className="titleNotification titleChangePassword titleWithoutMargin">
            Manage Subscriptions
          </h1>
          <div
            className={`innerTextPage innerSubscriptionPage ${
              hasSubscription ? 'removePaddingTop' : ''
            }`}
          >
            {isNil(user?.gift_subscription) ? (
              <>
                {hasSubscription ? (
                  <>
                    <div className="innerTypeSubscribe">
                      <p>{TITLE_BY_ID[typeSubscription]}</p>
                      {checkEmptyFamilyPro}
                    </div>
                    <p
                      onClick={manageSubscription}
                      className="linkNavigationAccount linkManageSubscriptions"
                    >
                      Manage subscription
                      <ArrowNavigation classStyle="navigationArrowSettings" />
                    </p>
                  </>
                ) : (
                  <PlanList redirectToChargebee={redirectToChargebee} />
                )}
                {hasSubscription && typeSubscriptionFamily ? (
                  <FormAddons setCount={setCountFamilyUsers} />
                ) : null}
              </>
            ) : (
              <>
                {' '}
                <p>Your are current member of {parentFamily}'s family plan</p>
                <button
                  onClick={leaveFromFamilySubscribe}
                  className="linkNavigationAccount linkManageSubscriptions"
                >
                  Exit from Family plan
                </button>
              </>
            )}
            <Link className="backLink addonLinkBack" to={routes.settings}>
              <ArrowBack />
              back
            </Link>
          </div>
        </LayoutAccount>
      </Layout>
    </div>
  )
}

export default connect(({ user }) => ({ user: user.data }))(ManageSubscription)
