import React, { useEffect, useRef, useState } from 'react'
import { isEmpty, reduce } from 'lodash'
import { connect, useDispatch } from 'react-redux'

import { Link } from 'react-router-dom'

import routes from '../../routers'
import { setUser } from '../../redux/actions/user'
import { updateFullName } from '../../api/user'
import { INPUT_TYPES } from '../../utils/constants'
import { ArrowBack } from '../../components/SvgIcon'
import ModalAccount from '../../components/Modals/ModalAccount'
import HookForm from '../../components/HookForm'
import Layout from '../../components/Layout'
import LayoutAccount from '../../components/LayoutAccount'

import './styles.css'

const ManageNotifications = ({ user }) => {
  const dispatch = useDispatch()
  const checkFormRef = useRef(null)
  const [disable, setDisable] = useState(true)
  const [successSave, setSuccessSave] = useState(false)
  const refCheck = useRef(null)

  const onChange = (e) => {
    checkFormRef.current?.reset({
      checkNotifications: e?.target?.checked,
      new_recipe: false,
      new_fam: false,
    })
    setDisable(e?.target?.checked)
  }

  const fields = [
    {
      name: 'checkNotifications',
      booleanRef: refCheck,
      onClick: onChange,
      defaultValue: true,
      type: INPUT_TYPES.CHECKBOX,
      rules: {
        required: false,
      },
      classInner: 'notificationCheckbox',
      textLabel: 'Recieve email notifications',
      labelStyle: 'checkboxNotificationText',
      markBrown: true,
    },
    {
      name: 'user_joined_group',
      type: INPUT_TYPES.CHECKBOX,
      defaultValue: false,
      checkedFirst: !disable,
      rules: {
        required: false,
      },
      classInner: 'notificationCheckbox',
      labelStyle: 'checkboxNotificationText',
      textLabel: 'Get notified when a new member joins a group',
      markBrown: true,
    },
    {
      name: 'recipe_published_to_group',
      type: INPUT_TYPES.CHECKBOX,
      defaultValue: false,
      checkedFirst: !disable,
      rules: {
        required: false,
      },
      classInner: 'notificationCheckbox',
      labelStyle: 'checkboxNotificationText',
      textLabel: 'Get notified when a new recipe is added to a group',
      markBrown: true,
    },
  ]

  useEffect(() => {
    checkFormRef.current?.reset({
      checkNotifications: !isEmpty(user?.notifications),
      recipe_published_to_group: user?.notifications?.includes('recipe_published_to_group'),
      user_joined_group: user?.notifications?.includes('user_joined_group'),
    })

    setDisable(checkFormRef.current?.watch('checkNotifications'))
  }, [])

  const handleSubmit = ({ checkNotifications, recipe_published_to_group, user_joined_group }) => {
    const notifications = reduce(
      { recipe_published_to_group, user_joined_group },
      (res, value, key) => (value ? [...res, key] : res),
      []
    )

    return updateFullName({ notifications })
      .then(({ data }) => {
        dispatch(setUser(data))
        setSuccessSave(true)
      })
      .then(() => {
        checkFormRef.current?.reset({
          checkNotifications,
          recipe_published_to_group,
          user_joined_group,
        })
      })
      .catch((e) => console.log(e))
  }

  const FooterForm = () => {
    return (
      <div className="innerFooterSettings">
        <Link className="backLink" to={routes.settings}>
          <ArrowBack />
          back
        </Link>
        <button className="addNewRecipes buttonWrapper">
          <p className="buttonText">Save</p>
        </button>
      </div>
    )
  }

  return (
    <Layout title="Manage Notifications">
      <LayoutAccount>
        <div className="innerNotification">
          <h1 className="titleNotification">Manage Notifications</h1>
          <HookForm
            ref={checkFormRef}
            fields={fields}
            onSubmit={handleSubmit}
            Footer={() => <FooterForm />}
          />
        </div>
      </LayoutAccount>
      <ModalAccount show={successSave} onHide={() => setSuccessSave(false)}>
        <div className="innerModalRemoveUser">
          <p className="textModalRemoveFirst">Your changes have been applied</p>
          <button
            onClick={() => setSuccessSave(false)}
            className="removeUserButton modalButtonSuccess"
          >
            Success
          </button>
        </div>
      </ModalAccount>
    </Layout>
  )
}

export default connect(({ user }) => ({ user: user.data }))(ManageNotifications)
