const ArrowNavigation = ({ classStyle }) => {
  return (
    <svg
      className={classStyle}
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 0C27.9252 0 36 8.09351 36 18.0417C36 27.9899 27.9252 36.0834 18 36.0834C8.0748 36.0834 0 27.9899 0 18.0417C0 8.09351 8.0748 0 18 0ZM18 2.70626C9.5634 2.70626 2.7 9.58557 2.7 18.0417C2.7 26.4979 9.5634 33.3772 18 33.3772C26.4366 33.3772 33.3 26.4979 33.3 18.0417C33.3 9.58557 26.4366 2.70626 18 2.70626ZM16.3577 10.8209L22.6325 17.0832C22.8863 17.3376 23.0285 17.6821 23.0285 18.0412C23.0285 18.402 22.8863 18.7466 22.6325 19.001L16.3577 25.2615C16.0949 25.5249 15.7493 25.6566 15.4055 25.6566C15.0599 25.6566 14.7125 25.5249 14.4497 25.2579C13.9241 24.7274 13.9259 23.8723 14.4533 23.3454L19.7669 18.0412L14.4533 12.7387C13.9259 12.2119 13.9241 11.3549 14.4497 10.8245C14.9753 10.2923 15.8285 10.2959 16.3577 10.8209Z"
        fill="white"
      />
    </svg>
  )
}

export default ArrowNavigation
