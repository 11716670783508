import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import { setLoading } from '../../redux/actions/ui'
import { draftRecipeLink } from '../../routers'
import { getRecipe } from '../../api/recipe'
import { DRAFT_STATUS, PAGINATION_SIZE } from '../../utils/constants'
import Layout from '../../components/Layout'
import ListRecipes from '../../components/ListRecipes'

import './styles.css'

const DraftRecipeItem = ({ id, current_step, image, name }) => {
  return (
    <Link to={draftRecipeLink(id, current_step)} className="innerRecipe">
      <img
        src={image ? image : '/images/imageRecipeWithoutPhoto.png'}
        alt=""
        className="recipeImage"
      />
      <p className="nameRecipe">{name}</p>
      <div className="darkWrapper" />
    </Link>
  )
}

const DraftRecipe = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [visibleLoadBtn, setVisibleLoadBtn] = useState(false)
  const [draftRecipe, setDraftRecipe] = useState([])

  useEffect(() => {
    dispatch(setLoading(true))
    getRecipe(DRAFT_STATUS, { page, size: PAGINATION_SIZE })
      .then(({ data }) => {
        const allDraftRecipe = data?.data

        setDraftRecipe(allDraftRecipe)
        setVisibleLoadBtn(allDraftRecipe?.length >= PAGINATION_SIZE)
      })
      .catch((e) => console.log(e))
      .finally(() => dispatch(setLoading(false)))
  }, [])

  const onClick = () => {
    setPage((oldState) => oldState + 1)
    getRecipe(DRAFT_STATUS, { page: page + 1, size: PAGINATION_SIZE })
      .then(({ data }) => {
        const allDraftRecipe = data?.data

        setDraftRecipe((oldState) => [...oldState, ...allDraftRecipe])
        setVisibleLoadBtn(allDraftRecipe?.length >= PAGINATION_SIZE)
      })
      .catch((e) => console.log(e))
  }

  return (
    <Layout title="Draft Recipe">
      <div className="containerMain containRecipe draftsContainer">
        <div className="innerDraftRecipe ">
          <ListRecipes
            title="Your Recipe Drafts"
            loadBtn={visibleLoadBtn}
            dataList={draftRecipe}
            onClick={onClick}
            showCountRecipes={false}
            titleClass="innerTitleDraft"
            innerRecipes="innerDraftRecipePadding"
            router={draftRecipeLink}
          >
            {!isEmpty(draftRecipe) ? (
              draftRecipe?.map(({ name, image, id, current_step }, index) => (
                <DraftRecipeItem
                  key={index}
                  name={name}
                  image={image}
                  id={id}
                  current_step={current_step}
                />
              ))
            ) : (
              <p className="withoutDraftRecipe">Not Found</p>
            )}
          </ListRecipes>
        </div>
      </div>
    </Layout>
  )
}
export default DraftRecipe
