const DropdownArrowHeader = ({ color = '#F7F5AD' }) => {
  return (
    <svg
      className="downArrow"
      width="25"
      height="14"
      viewBox="0 0 25 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L11.056 11.4932C11.8434 12.3148 13.1566 12.3148 13.944 11.4932L24 1"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  )
}

export default DropdownArrowHeader
