import { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { isEmpty, reduce } from 'lodash'

import routes from '../../routers'
import { createRecipe, getRecipeById } from '../../api/recipe'
import useDirty from '../../utils/hooks/useDirty'
import { setLoading } from '../../redux/actions/ui'
import { ACTIVE_STATUS, DEFAULT_VALUES_ADD_RECIPE_SECOND_STEP } from '../../utils/constants'
import IngredientsItem from '../../components/IngredientsItem'
import InputSimple from '../../components/InputSimple'
import ButtonCustom from '../../components/ButtonCustom'
import LayoutRecipe from './components/LayoutRecipe'
import RecipeTitle from './components/RecipeTitle'

import styles from './style.module.css'
import './style.css'

const AddRecipe = () => {
  const { filterDirtyFields } = useDirty()
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const [required, setRequired] = useState(true)
  const [to_publish, setToPublish] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const { control, handleSubmit, reset, setValue } = useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ingredients',
  })
  const [idRecipe] = useSearchParams({})
  const idRecipeForm = idRecipe.get('id')

  const addNewIngredient = () => {
    append({
      quantity: '',
      measurement: '',
      name: '',
    })
  }

  const onSubmit = (data) => {
    const newData = filterDirtyFields({
      dirtyFields: { ...data, measurement: data?.measurement },
      data,
      current_step: 2,
      urlParams: idRecipeForm,
    })

    setDisableButton(true)
    createRecipe({
      ...newData,
      measurement: data?.measurement,
      amount: data?.amount,
      to_publish,
    })
      .then(({ data }) => {
        if (required) {
          return navigation(`${routes.addRecipeThird}?id=${data?.id}`)
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setDisableButton(false))
  }

  useEffect(() => {
    if (!isEmpty(idRecipeForm)) {
      dispatch(setLoading(true))
      return getRecipeById(idRecipeForm)
        .then(({ data }) => {
          const filterDataRecipe = filterDirtyFields({
            dirtyFields: DEFAULT_VALUES_ADD_RECIPE_SECOND_STEP,
            data,
            checkArray: true,
          })
          setToPublish(data?.status === ACTIVE_STATUS)
          reset({ ...DEFAULT_VALUES_ADD_RECIPE_SECOND_STEP, ...filterDataRecipe })
          return reduce(
            filterDataRecipe,
            (res, value, key) => {
              setValue(key, value, { shouldDirty: true })
            },
            {}
          )
        })
        .catch(() => navigation(routes.notFound))
        .finally(() => dispatch(setLoading(false)))
    }
    navigation(routes.notFound)
  }, [])

  return (
    <LayoutRecipe
      step={2}
      navigationPrev={`${routes.addRecipe}?id=${idRecipeForm}`}
      navigationNext
      onSubmit={handleSubmit(onSubmit)}
      disableButtonSave={disableButton}
      isLoading={disableButton}
      navigationPrevTitle="add recipe steps"
      setRequired={setRequired}
    >
      <RecipeTitle
        step={2}
        title="Add Your Recipe’s Ingredients"
        subTitle=" Now enter the amount, unit of measurement, and ingredient. Add as many ingredients as it
        takes!"
        widthSubTitle={500}
      />
      <div>
        {fields.map((item, index) => {
          return <IngredientsItem key={item.id} index={index} control={control} remove={remove} />
        })}
      </div>
      <div className="recipeButtonWrap">
        <ButtonCustom
          onClick={addNewIngredient}
          style="recipeButtonAddIngredient"
          title="+ add another ingredient"
        />
      </div>
      <p className={styles.recipeTitleSecond}>How much does this recipe make?</p>
      <div className="ingredientsCount globalInnerIngredients">
        <div className="ingredientsCount">
          <Controller
            name="amount"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field, fieldState }) => (
              <InputSimple
                {...field}
                {...fieldState}
                nameInputPlaceholder="ex. 2"
                styleInput="inputRecipeNumber textAlightCenter numberInputCustom"
                className="ingredientsAmount"
              />
            )}
          />

          <div>
            <Controller
              name="measurement"
              control={control}
              rules={{
                required: false,
              }}
              render={({ field, fieldState }) => (
                <InputSimple
                  {...field}
                  {...fieldState}
                  nameInputPlaceholder="ex. Dozen"
                  styleInput="inputRecipeNumber textAlightCenter numberInputCustom wrapDropDoze"
                  className="ingredientsMeasurement"
                />
              )}
            />
          </div>
        </div>
      </div>
    </LayoutRecipe>
  )
}

export default AddRecipe
