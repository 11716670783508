import { isNil } from 'lodash'
import { useNavigate, useSearchParams } from 'react-router-dom'

import routes from '../../../routers'
import '../style.css'

const StepsComponent = ({ step = 0, classNameStep = 'innerAllSteps' }) => {
  const [paramsId] = useSearchParams()
  const idGroup = !isNil(paramsId.get('id')) ? `?id=${paramsId.get('id')}` : ''

  const stepCircle = [
    {
      number: 1,
      title: 'Add Recipe Description & Photo',
      link: `${routes.addRecipe}${idGroup}`,
    },
    {
      number: 2,
      title: 'Add Ingredients & Servings',
      link: `${routes.addRecipeSecond}${idGroup}`,
    },
    {
      number: 3,
      title: 'Add Recipes Steps',
      link: `${routes.addRecipeThird}${idGroup}`,
    },
  ]

  const RecipeCircle = ({ number, index, link, title }) => {
    const navigation = useNavigate()
    const checkActiveStep = index < step - 1 ? 'activeCircle' : ''
    const activeStyle = index === step - 1 ? 'focusCircle' : checkActiveStep

    return (
      <button
        type="submit"
        onClick={() => {
          if (step - 1 > index) {
            navigation(link)
          }
        }}
        className="buttonNextStep"
      >
        <div className="recipeStepItem">
          <div className={`recipeStepCircle ${activeStyle}`}>{number}</div>
          <div className="recipeStepText">{title}</div>
        </div>
      </button>
    )
  }
  return (
    <div className="stepRecipeWrap">
      <div className="recipeStepWrap">
        {stepCircle.map((circle, index) => (
          <div className={classNameStep} key={circle.number}>
            <RecipeCircle
              number={circle.number}
              title={circle.title}
              index={index}
              link={circle.link}
            />

            {/*<div className="recipeStepText">{circle.title}</div>*/}

            {stepCircle.length - 1 === index || (
              <div className={`recipeStep ${index < step - 1 ? 'recipeActiveStep' : ''}`} />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default StepsComponent
