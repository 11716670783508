import { useEffect } from 'react'
import { Controller, useForm, useFormState } from 'react-hook-form'
import { isEmpty, reduce } from 'lodash'
import { Form } from 'react-bootstrap'

import {
  CATEGORIES_RECIPE,
  DEFAULT_VALUE_ADMIN_FIRST_STEP,
  MIN_LENGTH,
} from '../../../utils/constants'
import useDirty from '../../../utils/hooks/useDirty'
import { EditIcon } from '../../../components/SvgIcon'
import CustomCheckbox from '../../../components/CustomCheckbox'
import ChoseGroupsComponent from '../../../components/ChoseGroupsComponent'
import { updateRecipe } from '../../../api/admin'

const SummaryChanges = ({ required, dataRecipe = {}, idRecipe }) => {
  const { handleSubmit, control, reset, setValue } = useForm()
  const { filterDirtyFields } = useDirty()
  const { dirtyFields } = useFormState({ control })

  const categoriesSelected = (e, value, item, onChange) => {
    const filtered = !e.target.checked
      ? value?.filter((category) => item?.id !== category)
      : [...value, item?.id]
    onChange(filtered)
  }

  const onSubmit = (data) => {
    const newData = filterDirtyFields({ dirtyFields, data })

    return updateRecipe({ ...newData, id: idRecipe, to_publish: required }).then(() => {
      reset(data)
      reduce(
        data,
        (res, value, key) => {
          setValue(key, value, { shouldDirty: true })
        },
        {}
      )
    })
  }

  useEffect(() => {
    const filterDataRecipe = filterDirtyFields({
      dirtyFields: DEFAULT_VALUE_ADMIN_FIRST_STEP,
      data: dataRecipe,
    })

    reset({ ...DEFAULT_VALUE_ADMIN_FIRST_STEP, ...filterDataRecipe })
    reduce(
      filterDataRecipe,
      (res, value, key) => {
        setValue(key, value, { shouldDirty: true })
      },
      {}
    )
  }, [dataRecipe])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-16">
      <div className="adminUserNameWrap mt-16">
        <p className="adminUserTitle">Summary</p>
        <button type="submit" className="btnChangeName">
          <EditIcon className="changePencilName" />
        </button>
      </div>
      <div className="recipeCategoriesWrap">
        {CATEGORIES_RECIPE.map((item, index) => (
          <Controller
            name="categories"
            key={index}
            control={control}
            defaultValue={[]}
            rules={{
              required,
              validate: {
                value: (value) => !isEmpty(dirtyFields) || !isEmpty(value),
              },
            }}
            render={({ field: { onChange, value }, fieldState }) => {
              return (
                <CustomCheckbox
                  {...fieldState}
                  onChange={(e) => categoriesSelected(e, value, item, onChange)}
                  markBrown
                  styleCheckBox="biggerCheckBox"
                  classInner="recipeCategoriesItem"
                  textLabel={item?.name}
                  checked={value?.includes(item?.id)}
                  labelStyle="recipeCategoriesText"
                  value={item?.id}
                />
              )
            }}
          />
        ))}
      </div>
      <Controller
        name="description"
        control={control}
        rules={{
          required,
          minLength: MIN_LENGTH,
          validate: { value: (value) => !isEmpty(dirtyFields) || !isEmpty(value) },
        }}
        render={({ field, fieldState: { error } }) => (
          <Form.Control
            {...field}
            as="textarea"
            className={`textAreaDescription grayPlaceholder ${error ? 'borderError' : ''}`}
            placeholder="Write a short summary or description of your delicious recipe here."
          />
        )}
      />
      <Controller
        name="groups"
        control={control}
        rules={{
          required,
          validate: { value: (value) => !isEmpty(dirtyFields) || !isEmpty(value) },
        }}
        render={({ field: { onChange, value }, fieldState }) => (
          <ChoseGroupsComponent
            {...fieldState}
            groups={dataRecipe?.author?.groups}
            onChange={onChange}
            value={value}
          />
        )}
      />
    </form>
  )
}

export default SummaryChanges
