import { HexColorPicker } from 'react-colorful'
import { Form } from 'react-bootstrap'

const ColorPicker = ({
  value,
  error,
  onChange,
  nameLabel,
  className = '',
  wrapHexColorPicker = '',
}) => {
  return (
    <div className="formSignUpGroup">
      {nameLabel && (
        <div>
          <Form.Label
            className={`formSignUpLabel ${className}`}
            style={error ? { color: 'red' } : {}}
          >
            {!error ? nameLabel : error.message}
          </Form.Label>
        </div>
      )}
      <div className="inputContent">
        <HexColorPicker className={wrapHexColorPicker} color={value} onChange={onChange} />
      </div>
    </div>
  )
}

export default ColorPicker
