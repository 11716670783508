import React from 'react'

const NextArrow = () => (
  <svg width={36} height={36} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 0c9.925 0 18 8.075 18 18s-8.075 18-18 18S0 27.925 0 18 8.075 0 18 0Zm0 2.7C9.563 2.7 2.7 9.563 2.7 18c0 8.437 6.863 15.3 15.3 15.3 8.437 0 15.3-6.863 15.3-15.3 0-8.437-6.863-15.3-15.3-15.3Zm-1.642 8.096 6.274 6.248a1.352 1.352 0 0 1 0 1.913l-6.274 6.246a1.344 1.344 0 0 1-1.908-.003 1.35 1.35 0 0 1 .003-1.909L19.767 18l-5.314-5.29a1.352 1.352 0 0 1-.003-1.91 1.348 1.348 0 0 1 1.908-.004Z"
      fill="#fff"
    />
  </svg>
)

export default NextArrow
