import { createAction } from 'redux-actions'

const SET_LOADING = 'SET_LOADING'
const SET_MODAL_TRIAL = 'SET_MODAL_TRIAL'
const SET_SHOW_MODAL_TRIAL = 'SET_SHOW_MODAL_TRIAL'
const LOCK_UI_BY_NETWORK = 'LOCK_UI_BY_NETWORK'
const UNLOCK_UI_BY_NETWORK = 'UNLOCK_UI_BY_NETWORK'
const LOADING_APP = 'LOADING_APP'

const setLoading = createAction(SET_LOADING)
const setModalTrial = createAction(SET_MODAL_TRIAL)
const setShowModalTrial = createAction(SET_SHOW_MODAL_TRIAL)
const lockUiByNetwork = createAction(LOCK_UI_BY_NETWORK)
const unlockUiByNetwork = createAction(UNLOCK_UI_BY_NETWORK)
const setLoadingApp = createAction(LOADING_APP)

export {
  setLoading,
  setModalTrial,
  lockUiByNetwork,
  unlockUiByNetwork,
  setLoadingApp,
  setShowModalTrial,
}
