import { useEffect } from 'react'
import { Controller, useFieldArray, useForm, useFormState } from 'react-hook-form'

import { DEFAULT_VALUES_ADD_RECIPE_SECOND_STEP, OPTIONS_TBLS } from '../../../utils/constants'
import useDirty from '../../../utils/hooks/useDirty'
import { updateRecipe } from '../../../api/admin'
import { EditIcon } from '../../../components/SvgIcon'
import IngredientsItem from '../../../components/IngredientsItem'
import ButtonCustom from '../../../components/ButtonCustom'
import InputSimple from '../../../components/InputSimple'

const IngredientsForm = ({ required, dataRecipe = {}, idRecipe }) => {
  const { control, handleSubmit, reset } = useForm()
  const { filterDirtyFields } = useDirty()
  const { dirtyFields } = useFormState({ control })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ingredients',
  })

  const addNewIngredient = () => {
    append({
      quantity: '',
      measurement: 1,
      name: '',
    })
  }

  const onSubmit = (data) => {
    const newData = filterDirtyFields({ dirtyFields, data })
    updateRecipe({ ...newData, id: idRecipe, to_publish: required }).then(() => reset(newData))
  }

  useEffect(() => {
    const filterDataRecipe = filterDirtyFields({
      dirtyFields: DEFAULT_VALUES_ADD_RECIPE_SECOND_STEP,
      data: dataRecipe,
    })

    reset({ ...DEFAULT_VALUES_ADD_RECIPE_SECOND_STEP, ...filterDataRecipe })
  }, [dataRecipe])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-16">
      <div className="adminUserNameWrap">
        <p className="adminUserTitle">Ingredients</p>
        <button type="submit" className="btnChangeName">
          <EditIcon className="changePencilName" />
        </button>
      </div>
      <div className="innerStepsAdmin">
        <div>
          {fields.map((item, index) => {
            return (
              <IngredientsItem
                key={item.id}
                index={index}
                required={required}
                dirtyFields={dirtyFields}
                control={control}
                dropOptions={OPTIONS_TBLS}
                remove={remove}
              />
            )
          })}
        </div>
        <div className="recipeButtonWrap">
          <ButtonCustom
            onClick={addNewIngredient}
            style="recipeButtonAddIngredient"
            title="+ add another ingredient"
          />
        </div>
      </div>
      <div className="ingredientsCount globalInnerIngredients">
        <div className="ingredientsCount">
          <Controller
            name="amount"
            control={control}
            rules={{
              required,
            }}
            render={({ field, fieldState }) => (
              <InputSimple
                {...field}
                {...fieldState}
                nameInputPlaceholder="ex. 2"
                styleInput="inputRecipeNumber textAlightCenter numberInputCustom"
                className="ingredientsAmount"
              />
            )}
          />

          <div>
            <Controller
              name="measurement"
              control={control}
              rules={{
                required,
              }}
              render={({ field, fieldState }) => (
                <InputSimple
                  {...field}
                  {...fieldState}
                  nameInputPlaceholder="ex. Dozen"
                  styleInput="inputRecipeNumber textAlightCenter numberInputCustom wrapDropDoze"
                  className="ingredientsAmount"
                />
              )}
            />
          </div>
        </div>
      </div>
    </form>
  )
}

export default IngredientsForm
