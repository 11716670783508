import { useEffect, useMemo, useRef, useState } from 'react'
import { isNil } from 'lodash'
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate, useSearchParams } from 'react-router-dom'

import routes from '../../../routers'
import { signUp } from '../../../api/auth'
import { removeUserTokenFromLocalStorage, updateToken } from '../../../utils/auth'
import {
  EMAIL_PATTERN,
  INPUT_TYPES,
  RECIPE_NAME_PATTERN,
  REQUIRED_FIELD_MESSAGE,
  SITE_KEY,
  TEMPORARY_TOKEN,
} from '../../../utils/constants'
import { Lock, Mail, User } from '../../../components/SvgIcon'
import LayoutRegistration from '../../../components/LayoutRegistration'
import HookForm from '../../../components/HookForm'
import { logout } from '../../../service/auth'

const SignUp = () => {
  const [disableBtn, setDisableBtn] = useState(true)
  const [linkToSignIp, setLinkToSignIp] = useState(routes.signIn)
  const signUpFormRef = useRef()
  const navigation = useNavigate()
  const [query] = useSearchParams()

  const { idGroup, slugGroup, referrer_id } = {
    idGroup: query.get('idGroup'),
    slugGroup: query.get('slug'),
    referrer_id: query.get('referrer_id'),
  }

  const defaultFieldsInputs = {
    className: 'mt-8',
    defaultValue: '',
  }
  const defaultFieldsCheckbox = {
    defaultValue: true,
    type: INPUT_TYPES.CHECKBOX,
    style: 'modalCheck',
    classInner: 'modalCheckWrap',
    rules: { required: false },
  }

  const fields = [
    {
      name: 'fullname',
      rules: {
        required: REQUIRED_FIELD_MESSAGE,
        pattern: {
          value: RECIPE_NAME_PATTERN,
        },
      },
      defaultValue: '',
      icon: <User />,
      nameLabel: 'Full Name',
      ...defaultFieldsInputs,
    },
    {
      name: 'email',
      rules: {
        required: REQUIRED_FIELD_MESSAGE,
        pattern: {
          value: EMAIL_PATTERN,
          message: 'This input is emails only',
        },
      },
      icon: <Mail />,
      nameLabel: 'Your email',
      inputType: 'email',
      ...defaultFieldsInputs,
    },
    {
      name: 'password',
      rules: {
        required: REQUIRED_FIELD_MESSAGE,
        minLength: { value: 6, message: 'Password must be at least 6 characters' },
      },
      icon: <Lock />,
      nameLabel: 'Password',
      inputType: 'password',
      ...defaultFieldsInputs,
    },
    {
      name: 'confirmPassword',
      rules: {
        required: REQUIRED_FIELD_MESSAGE,
        validate: {
          value: (value) =>
            value === signUpFormRef.current?.watch('password') || 'Passwords do not match',
        },
      },
      icon: <Lock />,
      nameLabel: 'Re-enter Password',
      inputType: 'password',
      ...defaultFieldsInputs,
    },
    {
      name: 'privatePolicy',
      labelStyle: 'modalCheckText',
      textLabel: (
        <>
          By checking this box, you agree to{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://getmorsel.com/terms-of-service/"
            className="underline"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://getmorsel.com/privacy-policy/"
            className="underline"
          >
            Privacy Policy
          </a>
          .
        </>
      ),
      ...defaultFieldsCheckbox,
      defaultValue: false,
      rules: { required: true },
    },
  ]

  useEffect(() => {
    removeUserTokenFromLocalStorage(TEMPORARY_TOKEN)
    if (idGroup && slugGroup) {
      setLinkToSignIp(`${routes.signIn}?idGroup=${idGroup}&slug=${slugGroup}`)
    }
  }, [])

  const Footer = useMemo(() => {
    return (
      <div>
        <div className="innerRecaptcha">
          <ReCAPTCHA onChange={() => setDisableBtn(false)} sitekey={SITE_KEY} size="normal" />
        </div>
        <button type="submit" disabled={disableBtn} className="buttonWrapper buttonText">
          NEXT...
        </button>
      </div>
    )
  }, [disableBtn])

  const handleSubmit = (data) => {
    logout()
    const checkUrlParams = !isNil(idGroup) && !isNil(slugGroup)
    const checkReferrerId = !isNil(referrer_id) ? { referrer_id } : {}
    const checkInvite = checkUrlParams ? { group_id: idGroup, slug: slugGroup } : {}

    signUp({ ...data, ...checkInvite, ...checkReferrerId })
      .then(({ data }) => {
        const { token } = data?.token

        updateToken(token, TEMPORARY_TOKEN)
        checkUrlParams ? navigation(routes.checkEmail) : navigation(routes.createGroup)
      })
      .catch((error) => {
        const code = error.response.status

        if (code === 400) {
          signUpFormRef.current?.setError('email', {
            type: 'manual',
            message: 'User already exists',
          })
        }
      })
  }

  return (
    <LayoutRegistration routesRedirect={linkToSignIp} title="Sign-Up" signIn>
      <div className="modalSignUp">
        <p className="modalTitle">Create an account</p>
        <p className="modalSubTitle">Fill in the information below</p>
        <HookForm
          ref={signUpFormRef}
          className="formSignUp"
          onSubmit={handleSubmit}
          fields={fields}
          Footer={Footer}
        />
      </div>
    </LayoutRegistration>
  )
}

export default SignUp
