import r from '../utils/request'
import { ACTIVE_STATUS } from '../utils/constants'

export const createRecipe = (params) => {
  return r.post('/recipes', params)
}

export const getRecipe = (status = ACTIVE_STATUS, params) => {
  return r.get('/recipes/my', { status, ...params })
}

export const getRecipeById = (id, to_edit = true) => {
  return r.get(`/recipes/${id}`, { to_edit })
}

export const getRecipesByCategory = (params) => {
  return r.get(`/groups/${params?.idGroup}/categories/${params?.codeCategory}`, {
    page: params?.page,
    size: params?.size,
  })
}

export const getRecipeByUserIdGroupId = (idUser, idGroup) => {
  return r.get(`/recipes/groups/${idGroup}/users/${idUser}`)
}

export const deleteRecipe = (id) => {
  return r.delete(`/recipes/${id}`)
}

export const reportRecipe = (params) => {
  return r.post('/report', params)
}

export const getVideoRecipe = () => {
  return r.get('/recipes/video')
}

export const getRecipesForOrder = (groupId, order_id) => {
  return r.get(`/recipes/group/${groupId}/all`, { order_id })
}
