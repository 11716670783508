import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'

import routes from '../../routers'
import { ROLE_ADMIN, ROLE_OWNER, ROLE_USER_ON_GROUP } from '../../utils/constants'
import { getInfoGroup, kickUserFromGroup, makeAdmin, reducingTheRole } from '../../api/group'
import { setLoading } from '../../redux/actions/ui'
import { ArrowBack, CloseSquare } from '../../components/SvgIcon'
import Layout from '../../components/Layout'
import UserPhoto from '../../components/UserPhoto'
import LayoutAccount from '../../components/LayoutAccount'
import ModalAccount from '../../components/Modals/ModalAccount'

import './styles.css'

const AccountSettingsGroup = ({ userData }) => {
  const { groupId } = useParams()
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const [user, setUser] = useState({})
  const [modalRemove, setModalRemove] = useState(false)
  const [modalMakeAdmin, setModalMakeAdmin] = useState(false)
  const [demoteAdmin, setDemoteAdmin] = useState(false)
  const [groupInfo, setGroupInfo] = useState({})
  const [initialUser, setInitialUser] = useState({})

  const removeFromGroup = (userId) => {
    kickUserFromGroup(groupId, userId)
      .then(() => {
        setGroupInfo((oldState) => {
          const filterUser = oldState?.users?.filter((item) => item?.id !== userId)
          return { ...oldState, users: filterUser }
        })
        setModalRemove(false)
      })
      .catch((e) => console.log(e))
  }

  const makeAdminGroup = (user_id) => {
    const data = { group_id: groupId, user_id }
    makeAdmin(data)
      .then(() => {
        setGroupInfo((oldState) => {
          const users = oldState?.users?.map((item) =>
            item?.id === user_id ? { ...item, role: ROLE_ADMIN } : item
          )
          return { ...oldState, users }
        })

        setModalMakeAdmin(false)
      })
      .catch((e) => console.log(e))
  }

  const demoteToUser = (idUser) => {
    reducingTheRole(idUser, groupId)
      .then(() => {
        setGroupInfo((oldState) => {
          const users = oldState?.users?.map((item) =>
            item?.id === idUser ? { ...item, role: ROLE_USER_ON_GROUP } : item
          )
          return { ...oldState, users }
        })
        setDemoteAdmin(false)
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    dispatch(setLoading(true))
    getInfoGroup(groupId)
      .then(({ data }) => {
        const users = data?.users?.filter((item) => {
          item?.id === userData?.id && setInitialUser(item)
          return item?.id !== userData?.id
        })
        setGroupInfo({ ...data, users })
      })
      .catch(() => navigation(routes.notFound))
      .finally(() => dispatch(setLoading(false)))
  }, [])

  const ItemUser = ({ name, userId, avatar, role }) => {
    const checkGlobalRole = role === ROLE_ADMIN || role === ROLE_OWNER
    const newUserData = { userId, groupId, name }

    const ownerDowngrade =
      initialUser?.role !== ROLE_OWNER ? (
        <span className="fakeMakeAdmin" />
      ) : (
        <p
          onClick={() => {
            setUser(newUserData)
            setDemoteAdmin(true)
          }}
          className="makeItemBtn btnMakeAdmin"
        >
          Demote Admin
        </p>
      )

    return (
      <div className="innerItemUser">
        {!(role === ROLE_OWNER || role === ROLE_ADMIN) || initialUser?.role === ROLE_OWNER ? (
          <div
            onClick={() => {
              setUser(newUserData)
              setModalRemove(true)
            }}
            className="wrapperBtnClose"
          >
            <CloseSquare />
          </div>
        ) : (
          <div className="hideRemoveBtn" />
        )}
        <UserPhoto image={avatar} />
        <div className="userNameInfo">
          <p className="userNameGroup">{name}</p>
          {!checkGlobalRole ? (
            <p
              onClick={() => {
                setUser(newUserData)
                setModalMakeAdmin(true)
              }}
              className="makeItemBtn btnMakeAdmin"
            >
              Make Admin
            </p>
          ) : (
            ownerDowngrade
          )}
        </div>
      </div>
    )
  }

  return (
    <Layout title="Group settings">
      <LayoutAccount>
        <h1 className="titleNotification titleChangePassword">{groupInfo?.name}</h1>
        <div className="wrapperGroupUsers">
          {!isEmpty(groupInfo?.users) ? (
            <div className="innerUsersGroup">
              {groupInfo?.users?.map((userInfo) => (
                <ItemUser
                  key={userInfo.id}
                  name={userInfo?.fullname}
                  userId={userInfo?.id}
                  avatar={userInfo?.avatar}
                  role={userInfo?.role}
                />
              ))}
            </div>
          ) : (
            <p className="withoutUsers">There are no participants</p>
          )}
          <div className="groupSettingsBack backLink">
            <ArrowBack />
            <Link to={routes.settings}>back</Link>
          </div>
        </div>
      </LayoutAccount>

      <ModalAccount show={modalRemove} onHide={() => setModalRemove(false)}>
        <div className="innerModalRemoveUser">
          <p className="textModalRemoveFirst">
            Are you sure you want to remove {user?.name} from {groupInfo?.name}?*
          </p>
          <button onClick={() => removeFromGroup(user?.userId)} className="removeUserButton">
            Yes, remove from group
          </button>
          <p className="textModalRemoveSecond">
            **Their recipes will be removed from your group upon confirming.
          </p>
        </div>
      </ModalAccount>

      <ModalAccount show={modalMakeAdmin} onHide={() => setModalMakeAdmin(false)}>
        <div className="innerModalRemoveUser">
          <p className="textModalRemoveFirst">
            Are you sure you want to promote {user?.name} to admin?
          </p>
          <p className="textModalRemoveFirst secondTextMargin">
            They’ll now be able to remove group members and manage subscription
          </p>
          <button
            onClick={() => makeAdminGroup(user?.userId)}
            className="removeUserButton modalButtonSuccess"
          >
            Yes, make Admin
          </button>
        </div>
      </ModalAccount>
      <ModalAccount show={demoteAdmin} onHide={() => setDemoteAdmin(false)}>
        <div className="innerModalRemoveUser">
          <p className="textModalRemoveFirst">
            Are you sure you want to demote {user?.name} to user?
          </p>
          <p className="textModalRemoveFirst secondTextMargin">
            They will no longer be able to delete group members and manage subscriptions
          </p>
          <button
            onClick={() => demoteToUser(user?.userId)}
            className="removeUserButton modalButtonSuccess"
          >
            Yes, lower to user
          </button>
        </div>
      </ModalAccount>
    </Layout>
  )
}

export default connect(({ user }) => ({ userData: user.data }))(AccountSettingsGroup)
