import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'

import useInvited from '../../utils/hooks/useInvited'
import { setLoading } from '../../redux/actions/ui'

const InvitedUser = ({ user }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { invited } = useInvited()
  const [params] = useSearchParams()

  useEffect(() => {
    dispatch(setLoading(true))
    invited(user, navigate, params.get('group'), params.get('slug'))(dispatch)
  }, [])

  return null
}

export default connect(({ user }) => ({ user: user.data }))(InvitedUser)
