import { isFunction } from 'lodash'

import { ROLES } from '../utils/constants'
import routes from '../routers'
import { setUser } from '../redux/actions/user'
import { setGroups } from '../redux/actions/groups'
import { getUserInfo, login as loginAPI } from '../api/auth'
import { setUserRoleStorage, updateToken } from '../utils/auth'
import { setModalTrial } from '../redux/actions/ui'

export const login = (params) => (dispatch) => {
  return loginAPI(params)
    .then(({ data }) => {
      dispatch(setGroups(data?.groups))
      updateToken(data?.token?.token)
    })
    .then(getUserInfo)
    .then(({ data }) => {
      dispatch(setUser(data))
      return data
    })
}

export const logout = (navigation) => (dispatch) => {
  setUserRoleStorage(ROLES.ROLE_GUEST)
  updateToken()
  dispatch(setUser(null))
  dispatch(setModalTrial(false))
  isFunction(navigation) && navigation(routes.signIn)
}
