import React from 'react'
import { DropdownArrow } from '../SvgIcon'
import './style.css'

const Index = ({
  title = '',
  style = '',
  width,
  styleArrow = '',
  titleStyle = '',
  selectedValue = '',
}) => (
  <div
    style={{ maxWidth: width ? `${width}` : '100%' }}
    className={`dropHeader ${style} ${selectedValue ? 'selectedCheckbox' : ''}`}
  >
    <span className={titleStyle}>{title}</span>
    <div className={`dropdownArrow ${styleArrow}`}>
      <DropdownArrow color={'#E0E0E0'} />
    </div>
  </div>
)

export default Index
