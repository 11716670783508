import { forwardRef } from 'react'

import { DropdownArrowHeader } from './SvgIcon'

const CustomToggle = forwardRef(({ children, onClick, custom }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
    className="toggleDrop"
  >
    {custom ? (
      <> {children}</>
    ) : (
      <>
        {children} <DropdownArrowHeader />
      </>
    )}
  </a>
))

export default CustomToggle
