import { isNil } from 'lodash'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { LIMIT_RECIPES, STATUS_NEED_SUBSCRIBE, STATUS_TRIAL } from '../constants'
import routes from '../../routers'
import { ROLES } from '../constants'
import { setRoleUser } from '../../redux/actions/user'
import { setModalTrial } from '../../redux/actions/ui'
import { login, logout } from '../../service/auth'
import { setUserRoleStorage } from '../auth'

const useLogin = () => {
  const navigation = useNavigate()
  const dispatch = useDispatch()

  const handleLogin = (data, loginFormRef, idGroup, slugGroup) => {
    logout()
    const checkInvite =
      !isNil(idGroup) && !isNil(slugGroup) ? { group_id: idGroup, slug: slugGroup } : {}
    login({ ...data, ...checkInvite })(dispatch)
      .then((data) => {
        const checkLimit =
          data?.status === STATUS_TRIAL ||
          (data?.status === STATUS_NEED_SUBSCRIBE && data?.recipes_count >= LIMIT_RECIPES)
        setUserRoleStorage(ROLES.ROLE_USER)
        dispatch(setModalTrial(checkLimit))
        dispatch(setRoleUser(ROLES.ROLE_USER))
        navigation(routes.userProfile)
      })
      .catch((error) => {
        const status = error?.response?.status

        if (status === 400) {
          loginFormRef.current.setError('login', {
            type: 'manual',
            message: 'Confirm email',
          })
          throw error
        }
        loginFormRef.current.setError('password', {
          type: 'manual',

          message: 'Password is incorrect',
        })
      })
  }

  return { handleLogin }
}

export default useLogin
