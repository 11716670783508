const Home = () => {
  return (
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6363 17.852C16.1403 17.852 17.3645 19.0674 17.3645 20.5614V24.4038C17.3645 24.7248 17.6218 24.9822 17.9516 24.9897H20.3325C22.2087 24.9897 23.7339 23.4832 23.7339 21.6319V10.7343C23.7252 10.0972 23.4217 9.49764 22.9008 9.09916L14.6651 2.5311C13.5596 1.65544 12.0131 1.65544 10.9039 2.5336L2.72441 9.09666C2.18352 9.50763 1.87998 10.1072 1.87373 10.7555V21.6319C1.87373 23.4832 3.39895 24.9897 5.27518 24.9897H7.67856C8.01708 24.9897 8.29189 24.7211 8.29189 24.3913C8.29189 24.3189 8.30063 24.2464 8.31562 24.1777V20.5614C8.31562 19.0762 9.5323 17.862 11.025 17.852H14.6363ZM20.3325 26.8634H17.9291C16.5526 26.8309 15.4908 25.7504 15.4908 24.4038V20.5614C15.4908 20.1005 15.1073 19.7257 14.6363 19.7257H11.0313C10.5704 19.7282 10.1894 20.1042 10.1894 20.5614V24.3913C10.1894 24.485 10.1769 24.5749 10.1506 24.6599C10.0157 25.8965 8.95894 26.8634 7.67856 26.8634H5.27518C2.3659 26.8634 0 24.5162 0 21.6319V10.7468C0.0124916 9.50513 0.584605 8.3684 1.57269 7.62016L9.73591 1.06834C11.5334 -0.355697 14.038 -0.355697 15.8318 1.06584L24.0537 7.62391C25.0193 8.35966 25.5914 9.49389 25.6077 10.7206V21.6319C25.6077 24.5162 23.2418 26.8634 20.3325 26.8634Z"
        fill="#F7F5AD"
      />
    </svg>
  )
}

export default Home
