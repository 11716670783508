const routes = {
  signIn: '/',
  dashboard: 'recipes',
  addRecipe: '/first-step',
  addRecipeSecond: '/second-step',
  addRecipeThird: '/third-step',
  categories: '/categories',
  category: ':codeCategory',
  recipeDraft: '/recipe-draft',
  profile: 'profile',
  userProfile: '/profile',
  settings: '/settings',
  notification: '/notification',
  manageFams: '/manage-fams',
  changePassword: '/change-password',
  manageSubscription: '/subscription',
  giftSubscriptions: '/gift-subscriptions',
  purchaseBook: '/purchase-book',
  successPurchaseBook: '/success-purchase',
  invite: '/invite',
  groupSettings: '/group-settings',

  signUp: '/sign-up',
  resetPassword: '/reset-password',
  createGroup: '/create-group',
  famMembers: '/group-members',
  checkEmail: '/check-email',
  successReset: '/reset-successful',
  newPassword: '/new-password',

  adminLogin: '/admin',
  adminUserList: '/users',
  adminUser: '/user',
  adminGroupList: '/groups',
  adminGroup: '/group',
  adminRecipeList: '/recipes',
  adminRecipe: '/recipe',
  adminAd: '/ad',
  adminVideo: '/video',

  invitedUser: '/invited',
  notFound: '/not-found',
  checkSubscribe: 'check-subscribe',
}

export const inviteFriendsToGroup = (id, slug) => {
  return `${routes.famMembers}/groupId=${id}/slug=${slug}`
}

export const invitedUser = () => {
  return `${routes.invitedUser}`
}

export const manageGroup = (groupId) => {
  return `${routes.manageFams}-${groupId}`
}

export const categoriesGroup = (slug, idGroup) => {
  return `/group/${slug}-${idGroup}`
}

export const categoryGroup = (categoryId) => {
  return `${categoriesGroup()}/${routes.category}-${categoryId}`
}

export const recipeAll = (recipeId) => {
  return `${categoryGroup()}/${routes.dashboard}-${recipeId}`
}

export const draftRecipeLink = (id, step) => {
  const allSteps = [routes.addRecipe, routes.addRecipeSecond, routes.addRecipeThird]
  return `${allSteps[step - 1]}?id=${id}`
}

export default routes
