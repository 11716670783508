import React, { useState } from 'react'
import { SearchIcon } from '../../../../components/SvgIcon'

const Search = ({ onSearch }) => {
  const [search, setSearch] = useState('')

  const onInputChange = (value) => {
    setSearch(value)
    onSearch(value)
  }
  return (
    <div className="searchForm">
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        value={search}
        onChange={(e) => onInputChange(e.target.value)}
      />
      <div className="searchIcon">
        <SearchIcon />
      </div>
    </div>
  )
}

export default Search
