import { useEffect, useState } from 'react'
import moment from 'moment'
import { isEmpty } from 'lodash'
import fileDownload from 'js-file-download'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import routes from '../../routers'
import {
  RECIPES_GROUP_HEADER,
  DATA_GROUP_MEMBERS_HEADER,
  ROLE_USER_IN_GROUP,
  RECIPE_NAME_PATTERN,
} from '../../utils/constants'
import {
  changeNameGroup,
  deleteGroupById,
  deleteRecipeFromGroup,
  deleteUserFromGroup,
  exportAll,
  getGroupById,
} from '../../api/admin'
import { EditIcon } from '../../components/SvgIcon'
import ButtonCustom from '../../components/ButtonCustom'
import LayoutRecipe from './components/LayoutAdmin'
import Table from './components/TableComponent'

import './style.css'

const AdminGroup = () => {
  const navigation = useNavigate()
  const { handleSubmit, control, reset, formState } = useForm()
  const [allDataGroup, setAllDataGroup] = useState({})
  const [recipeInGroup, setRecipeInGroup] = useState([])
  const [allGroupUsers, setAllGroupUsers] = useState([])
  const { idGroup } = useParams()

  const deleteRecipe = (idRecipe) => {
    deleteRecipeFromGroup(idRecipe, idGroup)
      .then(() =>
        setRecipeInGroup((oldState) => oldState?.filter((recipe) => recipe?.id !== idRecipe))
      )
      .catch((e) => console.log(e))
  }

  const exportAllRecipesGroup = () => {
    exportAll({ group_id: idGroup }).then((data) => fileDownload(data, `${allDataGroup.name}.xlsx`))
  }

  const deleteUser = (idUser) => {
    deleteUserFromGroup(idUser, idGroup)
      .then(() => setAllGroupUsers((oldState) => oldState?.filter((user) => user?.id !== idUser)))
      .catch((e) => console.log(e))
  }

  const deleteGroup = () => {
    deleteGroupById(idGroup).then(() => navigation(routes.adminGroupList))
  }

  const changeName = (data) => {
    changeNameGroup({ ...data, id: idGroup }).then(() => reset(data))
  }

  useEffect(() => {
    getGroupById(idGroup).then(({ data }) => {
      const allRecipes = data?.recipes?.map((item) => ({
        id: item?.id,
        name: [{ name: item?.name, link: `${routes?.adminRecipe}/${item?.id}` }],
        category: item?.categories?.map((category) => ` ${category}`).toString(),
        createdBy: item?.author_name,
      }))
      const allUsers = data?.users?.map((user) => ({
        id: user?.id,
        name: [{ name: user?.fullname, link: `${routes?.adminUser}/${user?.id}` }],
        adminLevel: ROLE_USER_IN_GROUP?.[user?.pivot?.role],
        joinedGroup: moment(user?.pivot?.joined_at).format('MM/DD/YYYY'),
      }))

      setAllDataGroup(data)
      setRecipeInGroup(allRecipes)
      setAllGroupUsers(allUsers)
      reset({ name: data?.name })
    })
  }, [])

  return (
    <LayoutRecipe>
      <div className="adminUserContent">
        <div>
          <div className="adminUserWrap">
            <div>
              <form onSubmit={handleSubmit(changeName)} className="formChaneName">
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    pattern: {
                      value: RECIPE_NAME_PATTERN,
                    },
                    validate: { value: () => !isEmpty(formState?.dirtyFields) },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <input
                      {...field}
                      style={error ? { borderColor: 'red' } : {}}
                      defaultValue="Group Name"
                      type="text"
                      className="inputTable"
                    />
                  )}
                />
                <button type="submit" className="btnChangeName">
                  <EditIcon className="changePencilName" />
                </button>
              </form>
              <p className="adminUserTitle">Group Ident #{allDataGroup?.id}</p>
            </div>
            <div className="adminDataWrap">
              <p className="adminData">
                DATE CREATED: {moment(allDataGroup?.created_at).format('MM/DD/YYYY')}
              </p>
              <ButtonCustom
                style="adminUserDeleteButton mt-16"
                titleStyle="adminUserButtonText"
                onClick={deleteGroup}
                title="DELETE GROUP"
              />
            </div>
          </div>
        </div>
        <div>
          <p className="tableTitle">GROUP MEMBERS</p>
          <Table
            dataTable={allGroupUsers}
            dataHeader={DATA_GROUP_MEMBERS_HEADER}
            smallTable
            deleteTd
            deleteTableRow={deleteUser}
          />
          <p className="tableTitle">RECIPES IN GROUP</p>
          <Table
            dataTable={recipeInGroup}
            dataHeader={RECIPES_GROUP_HEADER}
            smallTable
            deleteTd
            exportButton
            exportClick={exportAllRecipesGroup}
            deleteTableRow={deleteRecipe}
          />
        </div>
      </div>
    </LayoutRecipe>
  )
}

export default AdminGroup
