import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { isNil } from 'lodash'

import routes, { inviteFriendsToGroup } from '../../../../../routers'
import { setUrl } from '../../../../../redux/actions/groups'
import { createGroup } from '../../../../../api/group'
import LayoutRegistration from '../../../../../components/LayoutRegistration'
import localStorage from '../../../../../utils/localStorage'
import {
  INPUT_TYPES,
  RECIPE_NAME_PATTERN,
  TEMPORARY_TOKEN,
  USER_LINK_TO_GROUP,
} from '../../../../../utils/constants'
import { Group } from '../../../../../components/SvgIcon'
import HookForm from '../../../../../components/HookForm'

const FamGroup = () => {
  const dispatch = useDispatch()
  const navigation = useNavigate()

  const field = [
    {
      name: 'name',
      rules: { required: 'Create your group', pattern: { value: RECIPE_NAME_PATTERN } },
      nameLabel: 'Name of Your Family / Group',
      className: 'mt-8 famPadding',
      defaultValue: '',
      type: INPUT_TYPES.TEXT,
      icon: <Group />,
    },
  ]

  const handleSubmit = (data) => {
    createGroup(data)
      .then(({ data }) => {
        localStorage.setItem(USER_LINK_TO_GROUP, data?.inviteLink)
        dispatch(setUrl(data?.inviteLink))
        navigation(inviteFriendsToGroup(data?.id, data?.slug))
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    isNil(localStorage.getItem(TEMPORARY_TOKEN)) && navigation(routes.signIn)
  }, [])

  return (
    <LayoutRegistration title="Create Group">
      <div className="modalSignUp">
        <p className="modalTitle">Create a Group</p>
        <p className="modalSubTitle">
          Create a group by naming your family below, you can invite friends or family members next…
        </p>
        <HookForm
          className="formSignUp"
          fields={field}
          onSubmit={handleSubmit}
          Footer={() => (
            <button type="submit" className="buttonWrapper buttonText">
              NEXT...
            </button>
          )}
        />
        <Link to={routes.checkEmail} className="skipButton opacity">
          Skip this
        </Link>
      </div>
    </LayoutRegistration>
  )
}

export default FamGroup
