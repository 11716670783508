import React from 'react'

const DeleteBasket = () => (
  <svg width={23} height={24} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.662 8.064c.495.04.865.474.825.97-.007.081-.657 8.134-1.032 11.512-.232 2.097-1.617 3.372-3.708 3.41a230.736 230.736 0 0 1-9.431-.007c-2.006-.039-3.394-1.34-3.621-3.394C2.317 17.147 1.67 9.114 1.664 9.033a.899.899 0 0 1 .824-.97c.489-.012.93.33.97.825.004.052.268 3.333.556 6.578l.058.648c.145 1.613.292 3.163.412 4.243.128 1.167.758 1.77 1.87 1.793 3 .063 6.06.067 9.361.007 1.18-.023 1.82-.613 1.951-1.809.372-3.352 1.02-11.378 1.027-11.46.04-.494.478-.84.969-.824ZM13.615 0a2.44 2.44 0 0 1 2.354 1.807l.305 1.513c.098.497.534.859 1.038.867h3.938a.9.9 0 0 1 0 1.8h-3.903a.93.93 0 0 1-.018 0l-.03-.001H.9a.9.9 0 0 1 0-1.8h3.937l.122-.008c.451-.059.826-.402.918-.858l.292-1.459C6.465.743 7.433 0 8.535 0h5.08Zm0 1.8h-5.08a.635.635 0 0 0-.614.47l-.28 1.404c-.036.178-.087.35-.154.513h7.176a2.864 2.864 0 0 1-.154-.513l-.292-1.459c-.061-.222-.314-.415-.602-.415Z"
      fill="#D30000"
    />
  </svg>
)

export default DeleteBasket
