import { useEffect, useState } from 'react'
import moment from 'moment'
import { isNil, toNumber } from 'lodash'

import routes from '../../routers'
import { getAllGroups } from '../../api/admin'
import { DATA_TABLE_GROUPS_HEAD } from '../../utils/constants'
import LayoutRecipe from './components/LayoutAdmin'
import Table from './components/TableComponent'

import './style.css'

const AdminGroupList = () => {
  const [groupsData, setGroupsData] = useState([])

  useEffect(() => {
    getAllGroups()
      .then(({ data }) => {
        const newData = data?.map((item) => ({
          id: item?.id,
          groupName: [{ name: item?.name, link: `${routes.adminGroup}/${item?.id}` }],
          adminName: [{ name: item?.owner_name, link: `${routes.adminUser}/${item?.owner_id}` }],
          adminEmail: item?.owner_email,
          numberGroupMembers: toNumber(item?.member_count),
          numberRecipes: !isNil(item?.recipe_count) ? toNumber(item?.recipe_count) : 0,
          ident: item?.id,
          date: moment(item?.created_at).format('MM/DD/YYYY'),
        }))
        setGroupsData(newData)
      })
      .catch((e) => console.log(e))
  }, [])

  return (
    <LayoutRecipe>
      <Table
        ITEMS_PER_PAGE={18}
        dataHeader={DATA_TABLE_GROUPS_HEAD}
        dataTable={groupsData}
        tableClass="fixedTable"
        searchShow
      />
    </LayoutRecipe>
  )
}

export default AdminGroupList
