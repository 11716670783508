import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import { isNil } from 'lodash'
import { useNavigate } from 'react-router-dom'

import routes from '../../routers'
import Header from '../Header'
import Footer from '../Footer'

const Layout = ({ user, title, children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    isNil(user) && navigate(routes.signIn)
  }, [user])

  return (
    <div>
      <Helmet>
        <title>{title} - Morsel</title>
        <meta property="og:title" content={title} key="title" />
      </Helmet>

      <Header />
      {children}
      <Footer />
    </div>
  )
}

export default connect(({ user }) => ({ user: user.data }))(Layout)
