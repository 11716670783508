import React from 'react'

const FullPageLoader = () => {
  return (
    <div className="fp-container">
      <p>LOADING...</p>
    </div>
  )
}

export default FullPageLoader
