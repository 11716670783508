import { Modal } from 'react-bootstrap'

import './styles.css'

const ModalAccount = ({ show, onHide, children }) => {
  return (
    <Modal size="lg" centered show={show} onHide={onHide} className="modalRemoveUser">
      <div onClick={onHide} className="modalClose opacity">
        X
      </div>
      {children}
    </Modal>
  )
}

export default ModalAccount
