import { useEffect, useState } from 'react'
import { isEmpty, isNil, isObject } from 'lodash'

import { LIMIT_SIZE_IMAGE } from '../../utils/constants'

import './CustomInputFile.css'

const CustomInputFile = ({
  value,
  error,
  onChange = () => {},
  idName = 'file',
  classInner = '',
  buttonClass = '',
  setExternal = () => {},
  isAlwaysShow = false,
  globalInnerClassName = '',
  textButton = 'Upload a Recipe Photo',
}) => {
  const [image, setImage] = useState('')
  const [bigSize, setBigSize] = useState('')
  const [defaultShowUploadButton, setDefaultShowUploadButton] = useState(true)

  const classNameDefaultShowUploadButton = defaultShowUploadButton ? 'showDefaultUploadButton' : ''
  const classNameHideBtnUpload = !isEmpty(image) ? 'hideBtnUpload' : ''

  useEffect(() => {
    if (!isObject(value)) {
      setImage(value)
      setExternal(value)
    }
  }, [value])

  const removeImage = () => {
    setExternal('')
    onChange('')
    setImage('')
  }

  return (
    <div className={`innerUploadedFile ${globalInnerClassName}`}>
      <div
        onMouseLeave={() => setDefaultShowUploadButton(isAlwaysShow)}
        className={`recipeUploadWrap ${classInner} ${
          error ? 'borderError' : ''
        } ${classNameHideBtnUpload} ${classNameDefaultShowUploadButton}`}
      >
        <img className="formPhotoRecipe" src={image} alt="" />
        <input
          onChange={(e) => {
            const file = e.target?.files?.[0]
            if (file?.size > LIMIT_SIZE_IMAGE) {
              return setBigSize('Large image')
            }
            if (!isNil(file)) {
              const image = URL.createObjectURL(file)
              setBigSize('')
              onChange(file)
              setImage(image)
              setExternal(image)
            }
          }}
          id={idName}
          className="inputFilePhoto"
          type="file"
          accept=".png, .jpg, .jpeg"
        />
        <label className={`recipeButtonUpload buttonWrapper ${buttonClass}`} htmlFor={idName}>
          {textButton}
        </label>
      </div>
      <div className="innerImageSettings">
        <p className="errorBigImage">{bigSize}</p>
        {!buttonClass && !isEmpty(image) ? (
          <button
            type="button"
            onClick={removeImage}
            className="removeImageBtn btn btn-outline-danger mr-1"
          >
            Remove image
          </button>
        ) : null}
      </div>
    </div>
  )
}

export default CustomInputFile
