import { useEffect, useState } from 'react'

import DropDownCustom from '../DropDownCustom'

const ChoseGroupsComponent = ({ groups, value, onChange, error }) => {
  const mapGroup = groups?.filter(({ id }) => value?.includes(id))
  const [groupsChose, setGroupsChose] = useState([])
  const menuGroups = groups?.filter(({ id }) => !value?.includes(id))

  useEffect(() => setGroupsChose(mapGroup), [value])

  return (
    <div style={{ width: '100%' }}>
      <DropDownCustom
        title="Add to a Group (select one or more)"
        settings={{
          style: `mt-16 dropDownPaddingRight ${error ? 'borderError' : ''}`,
          titleStyle: 'textDropDownInvite',
        }}
        ItemMenu={menuGroups}
        styleToggleList="listForm"
        custom
        blockChangeTitle
        onClickMenu={(valueGroups, groupName) => {
          const valueCopy = [...value, valueGroups]

          setGroupsChose((oldState) => [...oldState, { id: valueGroups, name: groupName }])
          onChange(valueCopy)
        }}
      />
      <div className="recipeGroupTags">
        {groupsChose?.map((item) => (
          <div key={item.id} className="recipeGroupTag">
            {item?.name}{' '}
            <div
              onClick={() => {
                setGroupsChose((oldState) =>
                  oldState.filter((itemGroup) => item?.id !== itemGroup?.id)
                )
                onChange(value.filter((itemGroup) => itemGroup !== item?.id))
              }}
              className="recipeTagClose opacity"
            >
              X
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ChoseGroupsComponent
