import { useRef } from 'react'
import { Link } from 'react-router-dom'

import routes from '../../routers'
import { updatePassword } from '../../api/user'
import { DEFAULT_VALUES_CHANGE_PASSWORD } from '../../utils/constants'
import { ArrowBack } from '../../components/SvgIcon'
import HookForm from '../../components/HookForm'
import LayoutAccount from '../../components/LayoutAccount'
import Button from '../../components/ButtonCustom'
import Layout from '../../components/Layout'

import './styles.css'

const AccountChangePassword = () => {
  const formChangePassword = useRef(null)

  const fields = [
    {
      name: 'current_password',
      className: 'inputChangePassword',
      nameInputPlaceholder: 'Old Password',
      defaultValue: '',
      nameLabel: true,
      rules: { required: true },
      inputType: 'password',
    },
    {
      name: 'new_password',
      className: 'inputChangePassword',
      nameInputPlaceholder: 'New Password',
      defaultValue: '',
      rules: { required: true, minLength: 6 },
      inputType: 'password',
    },
    {
      name: 'confirm_password',
      className: 'inputChangePassword',
      nameInputPlaceholder: 'Confirm Password',
      defaultValue: '',
      rules: {
        required: true,
        validate: {
          value: (value) => value === formChangePassword.current.watch('new_password'),
        },
      },
      inputType: 'password',
    },
  ]
  //TODO change this when we will have back-end
  const handleSubmit = (data) => {
    updatePassword(data)
      .then(() => formChangePassword.current?.reset(DEFAULT_VALUES_CHANGE_PASSWORD))
      .catch(() => {
        formChangePassword.current.setError('current_password', {
          type: 'manual',
          message: 'Password is incorrect',
        })
      })
  }

  return (
    <Layout title="Change Password">
      <LayoutAccount>
        <h1 className="titleNotification titleChangePassword">Change Password</h1>
        <HookForm
          ref={formChangePassword}
          className="innerChangePassword"
          fields={fields}
          onSubmit={handleSubmit}
          Footer={() => (
            <div className="innerFooterSettings">
              <Link className="backLink" to={routes.settings}>
                <ArrowBack />
                back
              </Link>
              <Button title="Save" style="addNewRecipes" />
            </div>
          )}
        />
      </LayoutAccount>
    </Layout>
  )
}

export default AccountChangePassword
