import { useEffect, useState } from 'react'
import { isNil } from 'lodash'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import routes from '../../routers'
import { setLoading } from '../../redux/actions/ui'
import Layout from '../../components/Layout'
import TableUsers from '../../components/TableUsers'
import ListRecipes from '../../components/ListRecipes'
import { getAllCategoriesGroup } from '../../api/categories'

const CategoriesRecipe = () => {
  const dispatch = useDispatch()
  const [allCategories, setAllCategories] = useState([])
  const { idGroup } = useParams()
  const navigation = useNavigate()

  useEffect(() => {
    dispatch(setLoading(true))
    getAllCategoriesGroup(idGroup)
      .then(({ data }) => setAllCategories(data))
      .catch(() => navigation(routes.notFound))
      .finally(() => dispatch(setLoading(false)))
  }, [idGroup])

  return (
    <Layout title="Categories">
      <div className="containerMain containRecipe">
        <div className="dashboardWrap">
          <TableUsers />
          <ListRecipes showCountRecipes={false} title="Recipe Categories">
            {allCategories?.map(({ code, count, name, image }) => (
              <Link to={code} key={code} className="innerRecipe">
                <img
                  src={!isNil(image) ? image : '/images/imageRecipeWithoutPhoto.png'}
                  alt=""
                  className="recipeImage"
                />
                <p className="countRecipe">{count}</p>
                <p className="nameRecipe">{name}</p>
                <div className="darkWrapper" />
              </Link>
            ))}
          </ListRecipes>
        </div>
      </div>
    </Layout>
  )
}

export default CategoriesRecipe
