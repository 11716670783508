import { useEffect, useState } from 'react'
import { isNil } from 'lodash'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { setUrl } from '../../../../../redux/actions/groups'
import routes from '../../../../../routers'
import { inviteToGroup } from '../../../../../api/group'
import {
  COPY_TEXT,
  SUCCESS_COPY_TEXT,
  TEMPORARY_TOKEN,
  USER_LINK_TO_GROUP,
} from '../../../../../utils/constants'
import { removeUserTokenFromLocalStorage } from '../../../../../utils/auth'
import localStorage from '../../../../../utils/localStorage'
import { Doc, Group } from '../../../../../components/SvgIcon'
import ToGroupList from '../../../../../components/ToGroupList/ToGroupList'
import LayoutRegistration from '../../../../../components/LayoutRegistration'

const FamMembers = ({ url }) => {
  const dispatch = useDispatch()
  const { idGroup, slug } = useParams()
  const [copyText, setCopyText] = useState(COPY_TEXT)
  const navigate = useNavigate()

  useEffect(() => {
    isNil(idGroup) || (isNil(slug) && navigate(routes.signIn))
    const getInviteLink = localStorage.getItem(USER_LINK_TO_GROUP)
    dispatch(setUrl(getInviteLink))
  }, [])

  const submitInvite = (data) => {
    const newData = { ...data, group_id: idGroup, slug: slug }

    inviteToGroup(newData)
      .then(() => {
        removeUserTokenFromLocalStorage(USER_LINK_TO_GROUP)
        removeUserTokenFromLocalStorage(TEMPORARY_TOKEN)
        navigate(routes.checkEmail)
      })
      .catch((e) => console.log(e))
  }

  const SuccessCopy = (props) => {
    return (
      <Tooltip {...props} id="button-tooltip">
        {copyText}
      </Tooltip>
    )
  }

  const FooterList = () => {
    return (
      <>
        <p className="mt-16 textAlightCenter">
          ...or invite them by sharing this private link:
          <span className="boldText"> {url}</span>
          <OverlayTrigger placement="top" delay={{ show: 300, hide: 400 }} overlay={SuccessCopy}>
            <CopyToClipboard text={url} className="iconCopyText">
              <button
                onMouseLeave={() => setCopyText(COPY_TEXT)}
                onClick={() => setCopyText(SUCCESS_COPY_TEXT)}
                type="button"
              >
                <Doc />
              </button>
            </CopyToClipboard>
          </OverlayTrigger>
        </p>

        <button type="submit" className="buttonWrapper buttonText buttonGetStarted">
          GET STARTED!
        </button>
      </>
    )
  }
  return (
    <LayoutRegistration title="Invite form">
      <div className="modalSignUp">
        <p className="modalTitle">Invite Your Group Members</p>
        <p className="modalSubTitle">
          Invite your friends and family members who you want to share recipes with. Enter their
          email below and they'll be notified to join.
        </p>
        <ToGroupList Footer={FooterList} icon={<Group />} submitInvite={submitInvite} />

        <Link to={routes.checkEmail} className="skipButton opacity">
          Skip this
        </Link>
      </div>
    </LayoutRegistration>
  )
}

export default connect(({ groups }) => ({ url: groups.url }))(FamMembers)
