import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { ENABLED_SENTRY } from './utils/constants'
import App from './pages/App'

import './index.css'

Sentry.init({
  enabled: ENABLED_SENTRY,
  dsn: 'https://4bebb20e8bed427ba9d98853886c1532@o345232.ingest.sentry.io/6208643',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

ReactDOM.render(<App />, document.getElementById('root'))
