import React from 'react'
import { Form } from 'react-bootstrap'

const CustomCheckbox = ({
  style = '',
  checkedFirst = false,
  onChange,
  onClick,
  textLabel,
  value,
  markBrown,
  classInner = '',
  labelStyle = '',
  styleCheckBox = '',
  checked,
  error,
  name = '',
}) => {
  const markBrownStyle = markBrown ? 'markBrown' : ''

  return (
    <div className={classInner}>
      <div key="checkbox" className={style}>
        <Form.Check type="checkbox">
          <Form.Check.Input
            name={name}
            disabled={checkedFirst}
            value={value}
            onClick={onClick}
            onChange={onChange}
            type="checkbox"
            checked={checked}
            className={`mark cursor-pointer ${markBrownStyle} ${styleCheckBox}`}
            style={error ? { borderColor: 'red' } : {}}
          />
        </Form.Check>
      </div>
      <p className={labelStyle}>{textLabel}</p>
    </div>
  )
}

export default React.forwardRef(CustomCheckbox)
