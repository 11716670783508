import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import routes from '../../../../../routers'
import { confirmNewPassword } from '../../../../../api/auth'
import { REQUIRED_FIELD_MESSAGE } from '../../../../../utils/constants'
import { Lock } from '../../../../../components/SvgIcon'
import LayoutRegistration from '../../../../../components/LayoutRegistration'
import HookForm from '../../../../../components/HookForm'

const NewPassword = () => {
  const [requiredEmail, setRequiredEmail] = useState(null)
  const formResetPassword = useRef(null)
  const navigation = useNavigate()
  const [searchParams] = useSearchParams()

  const code = searchParams.get('confirm')

  const defaultFields = {
    className: 'mt-8',
    defaultValue: '',
    inputType: 'password',
  }

  const fields = [
    {
      name: 'password',
      rules: {
        required: REQUIRED_FIELD_MESSAGE,
        minLength: { value: 6, message: 'Password must be at least 6 characters' },
      },
      icon: <Lock />,
      nameLabel: 'Enter new password',
      ...defaultFields,
    },
    {
      name: 'confirmPassword',
      className: 'mt-8',
      rules: {
        required: REQUIRED_FIELD_MESSAGE,
        validate: {
          value: (value) => value === formResetPassword.current.watch('password'),
        },
      },
      icon: <Lock />,
      nameLabel: 'Re-enter new password',
      ...defaultFields,
    },
  ]

  const handleSubmit = (data) => {
    const decodeCode = window.atob(code)
    const [codeConfirm, email] = decodeCode.split(':')
    const newData = { ...data, code: codeConfirm, email }

    confirmNewPassword(newData)
      .then(() => navigation(routes.signIn))
      .catch((error) => {
        const status = error?.response?.status
        const messageError = error?.response?.data?.data
        if (status === 400) {
          return setRequiredEmail(messageError)
        }
        if (status === 422) {
          return setRequiredEmail('Follow the correct link')
        }
      })
  }

  useEffect(() => {
    isEmpty(code) && navigation(routes.signIn)
  }, [])

  return (
    <LayoutRegistration title="New Password">
      <div className="modalSignUp">
        <p className="modalTitle">Enter a new password:</p>
        <p className="requiredText">{requiredEmail}</p>
        <HookForm
          ref={formResetPassword}
          className="formSignUp"
          fields={fields}
          onSubmit={handleSubmit}
          Footer={() => (
            <button type="submit" className="buttonWrapper buttonText">
              Reset Password
            </button>
          )}
        />
      </div>
    </LayoutRegistration>
  )
}

export default NewPassword
