import { Controller } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { Form } from 'react-bootstrap'

import { MIN_LENGTH } from '../utils/constants'
import { DeleteIcon, DeleteRecipeIcon } from './SvgIcon'

const RecipeSteps = ({ index, dirtyFields, required, control, remove }) => {
  const numberStep = index + 1

  return (
    <Controller
      name={`steps.${index}.description`}
      rules={{
        required,
        validate: {
          value: (value) => !isEmpty(dirtyFields) && !isEmpty(value),
        },
        minLength: MIN_LENGTH,
      }}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className="stepWrap">
            <p className="stepTitle">{numberStep}.</p>
            <Form.Control
              {...field}
              as="textarea"
              className={`textAreaSteps grayPlaceholder ${error ? 'borderError' : ''}`}
              placeholder={
                numberStep === 1
                  ? 'Describe the first step of your recipe'
                  : 'Describe the next step of your recipe'
              }
            />
            <div onClick={() => remove(index)} className="deleteIcon mobView deleteStepsArray">
              <DeleteRecipeIcon />
            </div>

            <div onClick={() => remove(index)} className="desView deleteStepsArray">
              <DeleteIcon />
            </div>
          </div>
        )
      }}
    />
  )
}

export default RecipeSteps
