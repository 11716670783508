import React from 'react'

const EditIcon = () => (
  <svg width={21} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.68 18.305a.787.787 0 0 1 0 1.574h-7.612a.788.788 0 0 1 0-1.574h7.613ZM14.818.686l1.808 1.405c.638.38 1.137 1.06 1.326 1.864a3.005 3.005 0 0 1-.402 2.333l-.008.01c-.071.094-.38.484-1.939 2.437a.789.789 0 0 1-.134.167l-.337.423-.24.3-1.773 2.22-.356.447a35869.36 35869.36 0 0 1-5.26 6.58c-.48.6-1.203.95-1.979.959l-3.82.048h-.01a.788.788 0 0 1-.766-.605l-.86-3.644a2.489 2.489 0 0 1 .483-2.138l9.888-12.366.011-.014c1.084-1.296 3.045-1.487 4.368-.426ZM9.335 5.024 1.78 14.476c-.18.224-.245.514-.18.792l.716 3.029 3.19-.04a.996.996 0 0 0 .769-.37l3.395-4.248.438-.548.438-.548 3.263-4.084-4.473-3.435Zm2.326-2.907L10.32 3.794l4.472 3.434 1.48-1.856c.171-.28.239-.674.148-1.054a1.481 1.481 0 0 0-.684-.931 347 347 0 0 1-1.898-1.468 1.571 1.571 0 0 0-2.175.198Z"
      fill="#37190C"
    />
  </svg>
)

export default EditIcon
