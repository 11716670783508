import React, { useEffect } from 'react'
import { isFunction } from 'lodash'
import { useForm } from 'react-hook-form'

import ControllerField from './components/ControllerField'

const HookForm = (
  { fields, className, onSubmit, Footer, defaultValues, focusField, ...rest },
  ref
) => {
  const {
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    setError,
    formState,
    setFocus,
    clearErrors,
  } = useForm({
    defaultValues,
  })

  useEffect(() => {
    if (focusField) {
      setFocus(focusField)
    }
  }, [setFocus, focusField])

  React.useImperativeHandle(ref, () => {
    return {
      watch: watch,
      reset: reset,
      setError: setError,
      dirtyFields: formState.dirtyFields,
      formState,
      clearErrors,
      setValue: setValue,
    }
  })

  const submitHandler = handleSubmit(onSubmit)

  return (
    <form className={className} onSubmit={submitHandler} {...rest}>
      {fields.map(({ name, rules, ...rest }) => (
        <ControllerField key={name} name={name} control={control} rules={rules} {...rest} />
      ))}
      {isFunction(Footer) ? <Footer onSubmit={submitHandler} formState={formState} /> : Footer}
    </form>
  )
}

export default React.forwardRef(HookForm)
