// import { useEffect } from 'react'
import {
  Link,
  // useLocation, useNavigate, useSearchParams
} from 'react-router-dom'
// import { isEmpty } from 'lodash'

import routes from '../../routers'
import { ArrowBack } from '../../components/SvgIcon'
import Layout from '../../components/Layout'
import LayoutAccount from '../../components/LayoutAccount'
// import { STEPS_ORDER_BOOK } from '../../utils/constants'
// import Stepper from './components/Stepper'
// import FirstStep from './components/FirstStep'
// import SecondStep from './components/SecondStep'
// import ThirdStep from './components/ThirdStep'
// import PreviewBook from './components/PreviewBook'

import './styles.css'

const PurchaseBook = () => {
  // const { state } = useLocation()
  // const navigate = useNavigate()
  // const [allSearchParams, setAllSearchParams] = useSearchParams()
  // const searchParams = Object.fromEntries([...allSearchParams])
  //
  // const currentStep = state?.currentStep || STEPS_ORDER_BOOK['FIRST_STEP']
  // const FormComponents = {
  //   [STEPS_ORDER_BOOK['FIRST_STEP']]: FirstStep,
  //   [STEPS_ORDER_BOOK['SECOND_STEP']]: SecondStep,
  //   [STEPS_ORDER_BOOK['THIRD_STEP']]: ThirdStep,
  //   [STEPS_ORDER_BOOK['PREVIEW_STEP']]: PreviewBook,
  // }
  // const CurrentStep = FormComponents[currentStep]
  //
  // useEffect(() => {
  //   if (!isEmpty(searchParams)) {
  //     setAllSearchParams({})
  //     navigate('.', { state: { ...state, ...searchParams }, replace: true })
  //   }
  // }, [])

  return (
    <Layout title="Purchase a Recipe Book">
      <LayoutAccount>
        <h1 className="titleNotification titleChangePassword">Purchase a Recipe Book</h1>
        <div style={{ maxWidth: 533 }} className="innerTextPage">
          Would you like to purchase a beautiful recipe book of all your group’s recipes? <br />
          <br />
          Email <span>info@getmorsel.com</span> and we can help make that happen!
          <Link className="backLink manageTextBack" to={routes.settings}>
            <ArrowBack />
            back
          </Link>
        </div>
      </LayoutAccount>
      {/*<div className="containerMain">*/}
      {/*  <div className="headerOrderPurchase">*/}
      {/*    <h1 className="titleNotification titleOrder">Order a Cookbook!</h1>*/}
      {/*    <p className="descriptionOrder">*/}
      {/*      Order a cookbook for yourself or others in your life. You already did the hard part of*/}
      {/*      uploading recipes, now go through our simple process and order a keepsake that will last*/}
      {/*      a lifetime.*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  <Stepper currentStep={currentStep} />*/}
      {/*  <CurrentStep orderUrlData={state} currentStep={currentStep} />*/}
      {/*</div>*/}
    </Layout>
  )
}

export default PurchaseBook
