import { AUTH_TOKEN } from './constants'
import { updateToken } from './auth'
import localStorage from './localStorage'

const autoload = async () => {
  const token = localStorage.getItem(AUTH_TOKEN)
  updateToken(token)
}

export default autoload
