import { useState } from 'react'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { isNil } from 'lodash'

import routes from '../../routers'
import { SUCCESS_COPY_TEXT, COPY_TEXT } from '../../utils/constants'
import { inviteToGroup } from '../../api/group'
import { ArrowBack, Doc } from '../../components/SvgIcon'
import Layout from '../../components/Layout'
import LayoutAccount from '../../components/LayoutAccount'
import ToGroupList from '../../components/ToGroupList/ToGroupList'
import Button from '../../components/ButtonCustom'

import './styles.css'

const InviteFamMember = () => {
  const [linkToGroupInvite, setLinkToGroupInvite] = useState(null)
  const [successInvite, setSuccessInvite] = useState(false)
  const [copyText, setCopyText] = useState(COPY_TEXT)

  const onSubmit = (data) => {
    inviteToGroup(data)
      .then(() => setSuccessInvite(true))
      .catch((e) => console.log(e))
  }

  const SuccessCopy = (props) => {
    return (
      <Tooltip {...props} id="button-tooltip">
        {copyText}
      </Tooltip>
    )
  }

  const FooterList = () => {
    return (
      <>
        <div className="textInviteGroup">
          {!isNil(linkToGroupInvite) ? (
            <p className="mt-16 textAlightCenter">
              ...or invite them by sharing this private link:{' '}
              <span id="textAreaRef" className="boldText inviteLink">
                {linkToGroupInvite}{' '}
              </span>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={SuccessCopy}
              >
                <CopyToClipboard text={linkToGroupInvite} className="iconCopyText">
                  <button
                    onMouseLeave={() => setCopyText(COPY_TEXT)}
                    onClick={() => setCopyText(SUCCESS_COPY_TEXT)}
                    type="button"
                  >
                    <Doc />
                  </button>
                </CopyToClipboard>
              </OverlayTrigger>
            </p>
          ) : null}
        </div>
        <div className="innerFooterSettings">
          <Link className="backLink" to={routes.settings}>
            <ArrowBack />
            back
          </Link>
          {successInvite ? (
            <p className="successInviteFriends">
              Your invite has been sent! While you wait, let them know you sent them an email
              invite.
            </p>
          ) : null}
          <div className="innerButtonInvite">
            <Button title="Invite" style="inviteButtonAccount" />
          </div>
        </div>
      </>
    )
  }
  return (
    <Layout title="Invite a Fam Member">
      <LayoutAccount>
        <h1 className="titleNotification titleChangePassword">Invite a Member</h1>
        <div className="innerInviteFam">
          <ToGroupList
            classInnerList="innerListFam"
            classInput="famInput"
            Footer={FooterList}
            inputPlaceHolder="Enter their email address"
            linkToGroup
            submitInvite={onSubmit}
            setLinkToGroupInvite={setLinkToGroupInvite}
            titleStyle="textDropDownInvite"
          />
        </div>
      </LayoutAccount>
    </Layout>
  )
}

export default InviteFamMember
