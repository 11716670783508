import { FIELD_ADVERTISING } from '../../utils/constants'
import LayoutRecipe from './components/LayoutAdmin'
import ChangeAdvertising from './components/ChangeAdvertising'

import './style.css'

const AdminAd = () => {
  return (
    <LayoutRecipe>
      <div className="adminAdWrap">
        {FIELD_ADVERTISING?.map((props) => (
          <ChangeAdvertising key={props?.idName} {...props} />
        ))}
      </div>
    </LayoutRecipe>
  )
}

export default AdminAd
