import r from '../utils/request'

export const createGroup = (params) => {
  return r.post('/groups', params)
}

export const inviteToGroup = (params) => {
  return r.post('/groups/invite', params)
}

export const getUserGroups = () => {
  return r.get('/users/groups')
}

export const leaveFromGroup = (id) => {
  return r.delete(`/groups/${id}/leave`)
}

export const getInfoGroup = (id) => {
  return r.get(`/groups/${id}`)
}

export const kickUserFromGroup = (groupId, userId) => {
  return r.delete(`/groups/${groupId}/kick/${userId}`)
}

export const makeAdmin = (params) => {
  return r.post('/groups/promote', params)
}

export const reducingTheRole = (idUser, idGroup) => {
  return r.put(`/groups/${idGroup}/demote/${idUser}`)
}

export const acceptInvite = (params) => {
  return r.post('/groups/accept-invite', params)
}
