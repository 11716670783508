import r from '../utils/request'

export const login = (params) => {
  return r.post('/login', params)
}

export const signUp = (params) => {
  return r.post('/signup', params)
}

export const getUserInfo = () => {
  return r.get('/me')
}

export const resetPassword = (params) => {
  return r.put('/users/password/reset', params)
}

export const confirmNewPassword = (params) => {
  return r.put('/users/password/confirm', params)
}

export const confirmEmail = (params) => {
  return r.post('/confirm_email', params)
}
