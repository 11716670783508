import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import routes from '../../../routers'
import { NextArrow, NextPrev } from '../../../components/SvgIcon'
import ModalAccount from '../../../components/Modals/ModalAccount'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Layout from '../../../components/Layout'
import StepsComponent from './StepsComponent'

import '../style.css'

const LayoutRecipe = ({
  onSubmit,
  children,
  isLoading,
  setRequired,
  navigationPrev,
  navigationNext,
  step = 1,
  disableButtonSave,
  navigationPrevTitle = 'Next step',
}) => {
  const navigation = useNavigate()
  const [successSaveToDraft, setSuccessSaveToDraft] = useState(false)

  return (
    <Layout title={`Add recipe step-${step}`}>
      <div className="innerFormManageRecipe">
        <form onSubmit={onSubmit} className="containerRecipeMain">
          <div className="addRecipeWrap">
            <div className="desView">
              <StepsComponent step={step} />
            </div>

            <div className="recipeContentWrap">
              <div className="recipeContent">{children}</div>
              <div className="navigationButtonWrap">
                <div className={navigationPrev ? 'navigationLinkWrap' : 'navigationSingleWrap'}>
                  {navigationPrev ? (
                    <Link to={navigationPrev}>
                      <p>
                        <NextPrev />
                        &nbsp;
                        <span className="recipeBack">back</span>
                      </p>
                    </Link>
                  ) : null}

                  <button
                    className="recipeSave"
                    disabled={disableButtonSave}
                    onClick={() => {
                      setRequired(false)
                      setSuccessSaveToDraft(true)
                    }}
                  >
                    Save to Drafts
                  </button>
                </div>
                {navigationNext ? (
                  <button
                    type="submit"
                    disabled={disableButtonSave}
                    onClick={() => setRequired(true)}
                    className="buttonWrapper nextButton"
                  >
                    <p className="buttonText">{navigationPrevTitle}</p>
                    &nbsp;&nbsp;
                    <NextArrow />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </form>
        <ModalAccount show={successSaveToDraft} onHide={() => setSuccessSaveToDraft(false)}>
          <div className="innerModalRemoveUser">
            <p className="textModalRemoveFirst">
              Success! Your recipe has been saved to the Drafts folder.
            </p>
            <button
              onClick={() => setSuccessSaveToDraft(false)}
              className="removeUserButton modalButtonSuccess"
            >
              Continue Editing
            </button>
            <button
              onClick={() => navigation(routes.recipeDraft)}
              className="removeUserButton modalButtonSuccess viewRecipeBtn"
            >
              View Recipe Drafts
            </button>
          </div>
        </ModalAccount>
        {isLoading && <LoadingSpinner />}
      </div>
    </Layout>
  )
}

export default LayoutRecipe
