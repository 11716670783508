import { handleActions } from 'redux-actions'

import {
  lockUiByNetwork,
  setLoading,
  unlockUiByNetwork,
  setLoadingApp,
  setModalTrial,
  setShowModalTrial,
} from '../actions/ui'

const initialState = {
  isLoading: true,
  appIsLoaded: false,
  isModalTrial: false,
  showModalTrial: false,
}

const actions = {
  [setLoading]: (state, { payload }) => {
    return {
      ...state,
      isLoading: payload,
    }
  },
  [setModalTrial]: (state, { payload }) => {
    return {
      ...state,
      isModalTrial: payload,
    }
  },
  [setShowModalTrial]: (state, { payload }) => {
    return {
      ...state,
      showModalTrial: payload,
    }
  },
  [setLoadingApp]: (state, { payload }) => {
    return {
      ...state,
      appIsLoaded: payload,
    }
  },
  [lockUiByNetwork]: (state) => {
    return {
      ...state,
      networkLockFlag: true,
    }
  },

  [unlockUiByNetwork]: (state) => {
    return {
      ...state,
      networkLockFlag: false,
    }
  },
}

const createReducer = (isLoading = true) => {
  return handleActions(actions, { ...initialState, isLoading })
}

export default createReducer
