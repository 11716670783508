import { Link, useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'

import routes from '../../routers'
import { LIMIT_RECIPES, STATUS_NEED_SUBSCRIBE, STATUS_TRIAL } from '../../utils/constants'
import { setShowModalTrial } from '../../redux/actions/ui'
import Button from '../ButtonCustom'

import './ListRecipes.css'

const ListRecipes = ({
  title,
  loadBtn = false,
  innerRecipes = '',
  children,
  visibleButtonAddRecipe = true,
  onClick = () => {},
  titleClass = '',
  userData,
  showCountRecipes = true,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const statusNeedPay =
    userData?.status === STATUS_TRIAL || userData?.status === STATUS_NEED_SUBSCRIBE

  const linkToCreateRecipes = () => {
    return statusNeedPay && userData?.recipes_count >= LIMIT_RECIPES
      ? dispatch(setShowModalTrial(true))
      : navigate(routes.addRecipe)
  }

  return (
    <div className={`innerListRecipes ${innerRecipes}`}>
      <div
        className={`innerHeaderRecipes ${!visibleButtonAddRecipe ? 'justify-content-center' : ''}`}
      >
        <div />
        <h1 className={`titleList ${titleClass}`}>
          {title}
          {showCountRecipes && (
            <div className="countRecipes">
              <span className="numberRecipes">{userData?.recipes_count} Uploads</span>
              {statusNeedPay && (
                <>
                  <span>|</span>
                  <Link to={routes.manageSubscription} className="linkToManage">
                    Upgrade to PRO
                  </Link>
                </>
              )}
            </div>
          )}
        </h1>
        {visibleButtonAddRecipe ? (
          <Button
            title="Add New Recipes"
            onClick={linkToCreateRecipes}
            style="addNewRecipes recipeBtnAdd"
          />
        ) : null}
      </div>
      <div className="innerListRecipesAll">{children}</div>
      {loadBtn ? (
        <Button onClick={onClick} title="Load more..." style="loadBtn" titleStyle="loadBtnText" />
      ) : null}
    </div>
  )
}

export default connect(({ user }) => ({ userData: user.data }))(ListRecipes)
