import React from 'react'
import { Controller } from 'react-hook-form'

import { INPUT_TYPES } from '../../../utils/constants'
import Check from '../../Check'
import InputSimple from '../../InputSimple'
import ColorPicker from '../../ColorPicker'
import DropDownCustom from '../../DropDownCustom'
import CustomBlockInput from '../../CustomBlockInput'

const INPUT_COMPONENTS = {
  [INPUT_TYPES.CHECKBOX]: Check,
  [INPUT_TYPES.TEXT]: InputSimple,
  [INPUT_TYPES.DROPDOWN]: DropDownCustom,
  [INPUT_TYPES.COLOR_PICKER]: ColorPicker,
  [INPUT_TYPES.CUSTOM_DROPDOWN]: CustomBlockInput,
}

const ControllerField = ({
  name,
  control,
  rules,
  type = INPUT_TYPES.TEXT,
  defaultValue,
  ...rest
}) => {
  const Component = INPUT_COMPONENTS[type]

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
        <Component
          inputRef={ref}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          error={error}
          {...rest}
        />
      )}
    />
  )
}

export default ControllerField
