import React from 'react'
import ReactPaginate from 'react-paginate'

import { SETTINGS_PAGINATION } from '../../../../utils/constants'

const PaginationComponent = ({ total = 0, onPageChange, itemsPerPage }) => {
  const totalPages = Math.ceil(total / itemsPerPage)

  const changePage = ({ selected }) => {
    onPageChange(selected + 1)
  }

  if (totalPages === 0) {
    return null
  }

  return <ReactPaginate {...SETTINGS_PAGINATION} pageCount={totalPages} onPageChange={changePage} />
}

export default PaginationComponent
