const Group = ({ color = '#E0E0E0' }) => (
  <svg width={33} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.21 15.506h.168l.376.002c2.875.018 9.444.309 9.444 4.761 0 4.424-6.34 4.712-9.4 4.731h-1.132c-2.875-.019-9.446-.309-9.446-4.756 0-4.429 6.57-4.718 9.446-4.736l.376-.002h.168Zm0 2.234c-3.538 0-7.756.434-7.756 2.504 0 2.023 3.966 2.496 7.432 2.523h.324c3.538 0 7.754-.432 7.754-2.498 0-2.09-4.216-2.53-7.754-2.53Zm11.842-2.838c3.608.54 4.366 2.235 4.366 3.562 0 .81-.319 2.288-2.448 3.1a1.118 1.118 0 0 1-.795-2.088c1.01-.384 1.01-.808 1.01-1.012 0-.653-.83-1.108-2.464-1.352a1.12 1.12 0 0 1-.94-1.272c.09-.61.657-1.017 1.271-.938Zm-22.415.938a1.12 1.12 0 0 1-.94 1.272c-1.634.244-2.464.7-2.464 1.352 0 .204 0 .626 1.011 1.012a1.116 1.116 0 1 1-.795 2.088C.32 20.75 0 19.272 0 18.464c0-1.326.758-3.022 4.367-3.562.615-.077 1.178.328 1.27.938ZM16.21 0a6.81 6.81 0 0 1 6.803 6.803 6.81 6.81 0 0 1-6.803 6.803h-.04a6.73 6.73 0 0 1-4.793-2.004A6.728 6.728 0 0 1 9.408 6.8C9.408 3.052 12.46 0 16.21 0Zm0 2.233a4.574 4.574 0 0 0-4.568 4.57 4.515 4.515 0 0 0 1.319 3.225 4.521 4.521 0 0 0 3.212 1.345l.037 1.117v-1.117c2.52 0 4.57-2.05 4.57-4.57 0-2.52-2.05-4.57-4.57-4.57Zm9.215-.775a5.377 5.377 0 0 1 4.52 5.325c-.007 2.673-2.004 4.966-4.646 5.337a1.118 1.118 0 0 1-.308-2.213 3.176 3.176 0 0 0 2.72-3.127 3.15 3.15 0 0 0-2.65-3.118 1.117 1.117 0 0 1 .364-2.204Zm-17.147.92c.1.61-.313 1.184-.92 1.284a3.15 3.15 0 0 0-2.649 3.121 3.174 3.174 0 0 0 2.719 3.124 1.116 1.116 0 1 1-.308 2.213c-2.643-.371-4.638-2.664-4.644-5.334a5.378 5.378 0 0 1 4.519-5.328 1.11 1.11 0 0 1 1.283.92Z"
      fill={color}
    />
  </svg>
)

export default Group
