import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { useFieldArray, useForm, useFormState } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { DEFAULT_VALUES_ADD_RECIPE_THIRD_STEP } from '../../utils/constants'
import routes from '../../routers'
import useDirty from '../../utils/hooks/useDirty'
import { setLoading } from '../../redux/actions/ui'
import { createRecipe, getRecipeById } from '../../api/recipe'
import ButtonCustom from '../../components/ButtonCustom'
import RecipeSteps from '../../components/RecipeSteps'
import LayoutRecipe from './components/LayoutRecipe'
import RecipeTitle from './components/RecipeTitle'

import './style.css'

const AddRecipe = () => {
  const [required, setRequired] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const { control, handleSubmit, reset } = useForm()
  const { dirtyFields } = useFormState({ control })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'steps',
  })
  const { filterDirtyFields } = useDirty()
  const [idRecipe] = useSearchParams({})
  const idRecipeForm = idRecipe.get('id')
  const step = fields?.length + 1

  const addNewIngredient = () => {
    append({
      description: '',
      step,
    })
  }

  const onSubmit = (data) => {
    const steps = data?.steps?.map((item, index) => ({ ...item, step: index + 1 }))
    const newData = { steps, id: idRecipeForm, to_publish: required, current_step: 3 }

    setDisableButton(true)
    createRecipe(newData)
      .then(() => {
        if (required) {
          return navigation(routes.userProfile)
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setDisableButton(false))
  }

  useEffect(() => {
    if (!isEmpty(idRecipeForm)) {
      dispatch(setLoading(true))
      return getRecipeById(idRecipeForm)
        .then(({ data }) => {
          const filterDataRecipe = filterDirtyFields({
            dirtyFields: DEFAULT_VALUES_ADD_RECIPE_THIRD_STEP,
            data,
            checkArray: true,
          })

          return reset(
            { ...DEFAULT_VALUES_ADD_RECIPE_THIRD_STEP, ...filterDataRecipe },
            { keepDefaultValues: Object.keys(filterDataRecipe).length === 1 }
          )
        })
        .catch(() => navigation(routes.notFound))
        .finally(() => dispatch(setLoading(false)))
    }
    navigation(routes.notFound)
  }, [])

  return (
    <LayoutRecipe
      step={3}
      navigationNext
      isLoading={disableButton}
      setRequired={setRequired}
      navigationPrevTitle="Publish"
      disableButtonSave={disableButton}
      onSubmit={handleSubmit(onSubmit)}
      navigationPrev={`${routes.addRecipeSecond}?id=${idRecipeForm}`}
    >
      <RecipeTitle
        step={3}
        title="Add Your Recipe’s Steps"
        subTitle="Describe step-by-step how to make your magical creation for all."
        widthSubTitle={500}
      />
      <div className="innerAllSteps">
        {fields.map((item, index) => {
          return (
            <RecipeSteps
              key={item.id}
              index={index}
              control={control}
              dirtyFields={dirtyFields}
              required={required}
              remove={remove}
            />
          )
        })}
      </div>
      <div className="recipeButtonWrap">
        <ButtonCustom
          onClick={() => addNewIngredient()}
          style="recipeButtonUpload"
          title="+ add another step"
        />
      </div>
    </LayoutRecipe>
  )
}

export default AddRecipe
