const Tick = () => {
  return (
    <svg
      version="1.0"
      width="16"
      height="16"
      className="tickStyle"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1280.000000 1084.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1084.000000) scale(0.100000,-0.100000)"
        fill="#57ad91"
        stroke="none"
      >
        <path
          d="M7380 7385 c-1614 -1614 -2938 -2934 -2942 -2933 -4 2 -447 442 -983
978 l-975 975 -983 -983 -982 -982 1963 -1962 1962 -1963 3922 3922 3923 3923
-980 980 c-539 539 -982 980 -985 980 -3 0 -1326 -1321 -2940 -2935z"
        />
      </g>
    </svg>
  )
}

export default Tick
