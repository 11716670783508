import React from 'react'

const NextPrev = () => (
  <svg width={18} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 7.39a.818.818 0 0 1-.707.811l-.111.008-14.383-.001 5.196 5.175a.818.818 0 0 1-1.062 1.238l-.092-.079-6.6-6.571A.821.821 0 0 1 0 7.422L0 7.39V7.36l.004-.048L0 7.39a.823.823 0 0 1 .161-.488l.007-.01a.823.823 0 0 1 .072-.081L6.84.237a.818.818 0 0 1 1.235 1.068l-.08.092-5.194 5.174h14.38c.453 0 .819.367.819.818Z"
      fill="#37190C"
    />
  </svg>
)

export default NextPrev
