const SearchIcon = () => (
  <svg width={21} height={21} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.87 0c5.442 0 9.869 4.427 9.869 9.869 0 2.567-.986 4.91-2.598 6.667l3.173 3.166A.759.759 0 0 1 19.776 21a.76.76 0 0 1-.537-.222l-3.21-3.202a9.82 9.82 0 0 1-6.16 2.163C4.429 19.739 0 15.31 0 9.869 0 4.427 4.428 0 9.87 0Zm0 1.52c-4.604 0-8.35 3.745-8.35 8.349s3.746 8.35 8.35 8.35c4.603 0 8.348-3.746 8.348-8.35 0-4.604-3.745-8.349-8.348-8.349Z"
      fill="#E0E0E0"
    />
  </svg>
)

export default SearchIcon
