import { Controller } from 'react-hook-form'

import { DeleteIcon } from './SvgIcon'
import InputSimple from './InputSimple'

const IngredientsItem = ({ index, control, remove }) => (
  <div className="ingredientsWrap">
    <div className="ingredientsCount">
      <Controller
        name={`ingredients.${index}.quantity`}
        rules={{
          required: false,
        }}
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <InputSimple
            {...field}
            {...fieldState}
            nameInputPlaceholder="Number"
            styleInput="inputRecipeNumber numberInputCustom"
            className="ingredientsNumber"
          />
        )}
      />
      <Controller
        name={`ingredients.${index}.measurement`}
        rules={{
          required: false,
        }}
        control={control}
        render={({ field, fieldState }) => (
          <div className="wrapDropTbl">
            <InputSimple
              {...field}
              {...fieldState}
              nameInputPlaceholder="Tbls"
              styleInput="inputRecipeNumber numberInputCustom"
              className="dropSecondStep"
            />
          </div>
        )}
      />
    </div>
    <Controller
      name={`ingredients.${index}.name`}
      control={control}
      defaultValue=""
      rules={{
        required: false,
      }}
      render={({ field, fieldState }) => (
        <div className="ingredientsCount">
          <InputSimple
            {...field}
            {...fieldState}
            nameInputPlaceholder="Ingredient"
            styleInput="inputRecipeNumber"
            className="ingredientsName"
          />
          <div
            className={`innerDeleteIngredient ${index === 0 ? 'hideButtonRemove' : ''}`}
            onClick={() => remove(index)}
          >
            <DeleteIcon />
          </div>
        </div>
      )}
    />
  </div>
)

export default IngredientsItem
