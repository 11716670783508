import { Link } from 'react-router-dom'

import routes from '../../../../../routers'
import LayoutRegistration from '../../../../../components/LayoutRegistration'

const SuccessReset = () => {
  return (
    <LayoutRegistration title="Success Registration">
      <div className="modalSignUp">
        <p className="modalTitle">Success! Check your email to finish resetting your password.</p>
        <Link to={routes.signIn} className="linkText opacity">
          Log-in
        </Link>
      </div>
    </LayoutRegistration>
  )
}

export default SuccessReset
