const RightArrow = ({ onClick, className }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="14"
      height="25"
      viewBox="0 0 14 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 24L11.4932 13.944C12.3148 13.1566 12.3148 11.8434 11.4932 11.056L0.999999 1"
        stroke="#893F1D"
        strokeWidth="2"
      />
    </svg>
  )
}

export default RightArrow
