import { useRef } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import routes, { categoriesGroup } from '../../routers'
import { SETTINGS_SLIDER } from '../../utils/constants'
import { LeftArrow, RightArrow } from '../SvgIcon'
import UserPhoto from '../UserPhoto'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './CustomSlider.css'

const CustomSlider = ({ data = [], className, idUser, idGroup, idSlug }) => {
  const sliderRef = useRef(null)

  const settings = {
    prevArrow: (
      <LeftArrow className="leftArrowSlider" onClick={() => sliderRef?.current?.slickPrev()} />
    ),
    nextArrow: (
      <RightArrow className="rightArrowSlider" onClick={() => sliderRef?.current?.slickNext()} />
    ),
    ...SETTINGS_SLIDER,
  }

  //TODO: when we have back-end change this

  return (
    <Slider ref={sliderRef} {...settings} className={className}>
      {data.map(({ avatar, fullname, id, slug }, index) => {
        const checkInitialUser =
          idUser === id
            ? routes.userProfile
            : `${categoriesGroup(idSlug, idGroup)}/profile-${slug}-${id}`

        return (
          <Link key={id} to={checkInitialUser}>
            <UserPhoto key={index} name={fullname} image={avatar} />
          </Link>
        )
      })}
      <Link to={routes.invite} className="groupUserPhotoMargin">
        <div className="inviteUser">
          <p className="inviteText">invite</p>
        </div>
      </Link>
    </Slider>
  )
}

export default CustomSlider
