const Mail = () => (
  <svg width={24} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.863 0c3.587 0 6.29 2.926 6.29 6.806v7.397c0 1.985-.701 3.78-1.977 5.052C20.03 20.395 18.546 21 16.88 21H6.27c-1.662 0-3.146-.603-4.292-1.745C.702 17.983 0 16.188 0 14.203V6.806C0 2.926 2.704 0 6.29 0h10.573Zm0 1.615H6.29c-2.708 0-4.675 2.183-4.675 5.191v7.397c0 1.554.535 2.942 1.504 3.908.835.834 1.926 1.274 3.154 1.274h10.59c.003-.003.011 0 .018 0 1.229 0 2.318-.44 3.154-1.274.97-.966 1.503-2.354 1.503-3.909V6.807c0-3.008-1.966-5.19-4.675-5.19ZM18.561 6.6a.808.808 0 0 1-.118 1.136l-4.786 3.89a3.276 3.276 0 0 1-4.091.006L4.736 7.74A.809.809 0 0 1 5.75 6.48l4.825 3.89c.607.48 1.46.48 2.071-.005l4.777-3.883a.807.807 0 0 1 1.138.117Z"
      fill="#E0E0E0"
    />
  </svg>
)

export default Mail
