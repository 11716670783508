import React from 'react'
import { Form } from 'react-bootstrap'

import './style.css'

const Check = ({
  style = '',
  checkedFirst = false,
  onChange = () => {},
  onClick = () => {},
  textLabel,
  value,
  markBrown,
  classInner = '',
  labelStyle = '',
  booleanRef,
  styleCheckBox = '',
  error,
}) => {
  const markBrownStyle = markBrown ? 'markBrown' : ''

  return (
    <div className={classInner}>
      <div key="checkbox" className={style}>
        <Form.Check type="checkbox">
          <Form.Check.Input
            disabled={checkedFirst}
            ref={booleanRef}
            value={value}
            onChange={(e) => {
              onChange(e)
              onClick(e)
            }}
            type="checkbox"
            isValid
            style={error ? { borderColor: 'red' } : {}}
            checked={value}
            className={`mark ${markBrownStyle} ${styleCheckBox}`}
          />
        </Form.Check>
      </div>
      <p className={labelStyle}>{textLabel}</p>
    </div>
  )
}

export default React.forwardRef(Check)
