import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'

import {
  DROPDOWN_SUBSCRIPTION,
  LIMIT_RECIPES,
  MORSEL_PRO,
  PRICE_MORSEL_FAMILY_PRO,
  PRICE_MORSEL_PRO,
  TITLE_BY_ID,
} from '../../../utils/constants'
import { setShowModalTrial } from '../../../redux/actions/ui'
import { subscribe } from '../../../api/trial'
import DropDownCustom from '../../DropDownCustom'
import ButtonCustom from '../../ButtonCustom'

import './styles.css'

const ModalTrial = ({ show }) => {
  const dispatch = useDispatch()
  const [error, setError] = useState('')
  const [typeSubscription, setTypeSubscription] = useState(MORSEL_PRO)
  const checkPrice = typeSubscription === MORSEL_PRO ? PRICE_MORSEL_PRO : PRICE_MORSEL_FAMILY_PRO

  const redirectToChargebee = () => {
    return subscribe(typeSubscription)
      .then(({ data }) => {
        window.location.replace(data?.url)
      })
      .catch((error) => {
        const statusCode = error?.response?.status

        if (statusCode === 403) {
          setError('You have already subscribed. Please wait a few minute.')
        }
      })
  }

  return (
    <Modal
      contentClassName="modalTrialContent"
      dialogClassName="modalTrialDialog"
      backdropClassName="backgroundTrial"
      show={show}
      onHide={() => dispatch(setShowModalTrial(false))}
    >
      <p className="modalTrialText">
        You're quite the chef!
        <br />
        <br />
        You've hit your {LIMIT_RECIPES} free recipe uploads. Upgrade today to Morsel Pro to add
        unlimited recipes for just <span className="boldTextCourier">${checkPrice} a year</span>
      </p>
      <div className="innerDropdownTypeSubscr">
        <DropDownCustom
          title={TITLE_BY_ID[typeSubscription]}
          settings={{
            style: 'dropdownTypeSubscription',
          }}
          ItemMenu={DROPDOWN_SUBSCRIPTION}
          styleToggleList="listForm"
          custom
          onClickMenu={(idTypeSubscription) => {
            setTypeSubscription(idTypeSubscription)
          }}
        />
      </div>
      <p style={{ color: 'red' }}>{error}</p>
      <ButtonCustom onClick={redirectToChargebee} style="modalButton" title="Upgrade Now" />
      <div onClick={() => dispatch(setShowModalTrial(false))} className="modalExit opacity">
        Continue using Morsel for Free
      </div>
    </Modal>
  )
}

export default ModalTrial
