import { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import { isEmpty, isNil } from 'lodash'
import { Link, useParams } from 'react-router-dom'

import routes, { categoriesGroup } from '../../routers'
import { getAdvertising } from '../../api/admin'
import { getRecipe, getRecipeByUserIdGroupId, getVideoRecipe } from '../../api/recipe'
import { setLoading } from '../../redux/actions/ui'
import {
  ACTIVE_STATUS,
  MIN_LAST_PAGE,
  PAGINATION_SIZE,
  STATUS_NEED_SUBSCRIBE,
  STATUS_TRIAL,
} from '../../utils/constants'
import ListRecipes from '../../components/ListRecipes'
import UserPhoto from '../../components/UserPhoto'
import Layout from '../../components/Layout'

import './styles.css'

const Profile = ({ user }) => {
  const dispatch = useDispatch()
  const { idUser, idGroup, idSlug } = useParams()
  const [recipeData, setRecipeData] = useState([])
  const [visibleLoad, setVisibleLoad] = useState(false)
  const [advertising, setAdvertising] = useState([])
  const [userData, setUserData] = useState({})
  const [page, setPage] = useState(1)
  const [videoData, setVideoData] = useState(null)

  const checkLinkToRecipe = (idRecipe, slug) => {
    return !isNil(idUser)
      ? `${categoriesGroup(idSlug, idGroup)}/all/${slug}-${idRecipe}`
      : `/${routes.dashboard}/${slug}-${idRecipe}`
  }

  useEffect(() => {
    dispatch(setLoading(true))
    getAdvertising()
      .then(({ data }) => setAdvertising(data))
      .then(getVideoRecipe)
      .then(({ data }) => setVideoData(data))
      .catch((e) => console.log(e))
    if (isNil(idUser)) {
      return getRecipe(ACTIVE_STATUS, { page, size: PAGINATION_SIZE })
        .then(({ data }) => {
          const allRecipes = data?.data
          setUserData(user)
          setRecipeData(allRecipes)
          setVisibleLoad(allRecipes?.length >= PAGINATION_SIZE && data?.lastPage !== MIN_LAST_PAGE)
        })
        .catch((e) => console.log(e))
        .finally(() => dispatch(setLoading(false)))
    }
    getRecipeByUserIdGroupId(idUser, idGroup)
      .then(({ data }) => {
        const allRecipes = data?.recipes?.data
        setUserData(data?.user)
        setRecipeData(allRecipes)
        setVisibleLoad(allRecipes?.length >= PAGINATION_SIZE && data?.lastPage !== MIN_LAST_PAGE)
      })
      .catch((e) => console.log(e))
      .finally(() => dispatch(setLoading(false)))
  }, [])

  const paginateRecipes = () => {
    setPage((oldState) => oldState + 1)
    dispatch(setLoading(true))
    if (isNil(idUser)) {
      return getRecipe(ACTIVE_STATUS, { page: page + 1, size: PAGINATION_SIZE })
        .then(({ data }) => {
          const allRecipes = data?.data
          setRecipeData((oldState) => [...oldState, ...allRecipes])
          setVisibleLoad(allRecipes?.length >= PAGINATION_SIZE && data?.lastPage !== MIN_LAST_PAGE)
        })
        .catch((e) => console.log(e))
        .finally(() => dispatch(setLoading(false)))
    }
    getRecipeByUserIdGroupId(idUser, idGroup)
      .then(({ data }) => {
        const allRecipes = data?.recipes?.data
        setRecipeData((oldState) => [...oldState, ...allRecipes])
        setVisibleLoad(allRecipes?.length >= PAGINATION_SIZE && data?.lastPage !== MIN_LAST_PAGE)
      })
      .catch((e) => console.log(e))
      .finally(() => dispatch(setLoading(false)))
  }

  const UserInfoBlock = () => {
    return (
      <div className="innerUsers">
        <h2 className="titleTableUsers">{userData?.fullname}</h2>
        <UserPhoto image={userData?.avatar} classStylePhoto="userProfilePhoto" />
        {!isNil(idUser) ? (
          <>
            <p className="textProfileBlock joinedText">
              Joined group in: {moment(userData?.joined_at).format('YYYY')}
            </p>
            <p className="textProfileBlock">Number of Recipes Added:</p>
            <p className="textProfileBlock">{recipeData?.length}</p>
          </>
        ) : null}
        {user?.status === STATUS_TRIAL || user?.status === STATUS_NEED_SUBSCRIBE ? (
          <>
            {!isEmpty(advertising?.[0]?.link) ? (
              <a href={advertising?.[0]?.link}>
                <img src={advertising?.[0]?.image} alt="" className="advertisingDesktop" />
              </a>
            ) : null}
            {!isEmpty(advertising?.[1]?.link) ? (
              <a href={advertising?.[1]?.link}>
                <img src={advertising?.[1]?.image} alt="" className="advertisingMobile" />
              </a>
            ) : null}
          </>
        ) : null}
      </div>
    )
  }

  return (
    <Layout title="Profile">
      <div className="containerMain containRecipe">
        <div className="dashboardWrap">
          <UserInfoBlock />
          <ListRecipes
            onClick={paginateRecipes}
            title="Recipes"
            innerRecipes="innerDraftRecipePadding"
            loadBtn={visibleLoad}
            visibleButtonAddRecipe={isNil(idUser)}
          >
            {!isEmpty(recipeData) ? (
              recipeData?.map(({ id, image, name, slug }) => (
                <Link to={checkLinkToRecipe(id, slug)} key={id} className="innerRecipe">
                  <img
                    src={image ? image : '/images/imageRecipeWithoutPhoto.png'}
                    alt=""
                    className="recipeImage"
                  />
                  <p className="nameRecipe">{name}</p>
                  <div className="darkWrapper" />
                </Link>
              ))
            ) : (
              <div className="profileVideo">
                <p className="textEmptyRecipes">{videoData?.text}</p>
                <video className="innerVideoForm" playsInline controls src={videoData?.url} />
              </div>
            )}
          </ListRecipes>
        </div>
      </div>
    </Layout>
  )
}

export default connect(({ user }) => ({ user: user.data }))(Profile)
