import { handleActions } from 'redux-actions'

import { setAdmin } from '../actions/admin'

const initialState = { data: null }

const actions = {
  [setAdmin]: (state, { payload }) => {
    return {
      ...state,
      data: payload,
    }
  },
}

export default handleActions(actions, initialState)
