const Lock = () => (
  <svg width={21} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.05.002c3.505 0 6.358 2.854 6.358 6.362v1.892a5.46 5.46 0 0 1 3.663 5.15v5.146a5.456 5.456 0 0 1-5.45 5.451H5.45A5.456 5.456 0 0 1 0 18.553v-5.148a5.46 5.46 0 0 1 3.662-5.149V6.364a6.334 6.334 0 0 1 1.882-4.519C6.751.65 8.345-.043 10.05.002Zm4.57 9.753H5.45a3.654 3.654 0 0 0-3.65 3.65v5.147a3.654 3.654 0 0 0 3.65 3.651h9.17a3.654 3.654 0 0 0 3.651-3.65v-5.148a3.655 3.655 0 0 0-3.65-3.65Zm-4.585 3.991a.9.9 0 0 1 .9.9v2.665a.9.9 0 0 1-1.8 0v-2.665a.9.9 0 0 1 .9-.9Zm.012-11.944h-.02a4.53 4.53 0 0 0-3.214 1.321c-.867.857-1.346 2.001-1.35 3.22L5.46 7.954h9.146l.001-1.59a4.566 4.566 0 0 0-4.561-4.562Z"
      fill="#E0E0E0"
    />
  </svg>
)

export default Lock
