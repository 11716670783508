const InviteFamIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evened"
        clipRule="evened"
        d="M6.27298 9.91954L6.36783 9.91956L6.57805 9.92014C8.36345 9.92968 12.546 10.092 12.546 12.851C12.546 15.409 9.11091 15.7508 6.43895 15.7637L5.96791 15.7635C4.18251 15.7539 0 15.5917 0 12.8334C0 10.2214 3.57967 9.91954 6.27298 9.91954ZM6.27298 11.1141C2.90355 11.1141 1.19455 11.693 1.19455 12.8334C1.19455 13.985 2.90355 14.5695 6.27298 14.5695C9.64241 14.5695 11.3514 13.9906 11.3514 12.851C11.3514 11.6978 9.64241 11.1141 6.27298 11.1141ZM13.7008 4.71345C14.0305 4.71345 14.298 4.98103 14.298 5.31073L14.298 6.30961L15.3301 6.31025C15.6598 6.31025 15.9273 6.57783 15.9273 6.90752C15.9273 7.23722 15.6598 7.5048 15.3301 7.5048L14.298 7.50416L14.298 8.50495C14.298 8.83465 14.0305 9.10223 13.7008 9.10223C13.3711 9.10223 13.1035 8.83465 13.1035 8.50495L13.1034 7.50416L12.0729 7.5048C11.7432 7.5048 11.4756 7.23722 11.4756 6.90752C11.4756 6.57783 11.7432 6.31025 12.0729 6.31025L13.1034 6.30961L13.1035 5.31073C13.1035 4.98103 13.3711 4.71345 13.7008 4.71345ZM6.27298 0C8.60872 0 10.5081 1.90013 10.5081 4.23508C10.5081 6.57002 8.60872 8.47015 6.27298 8.47015H6.24829C5.11825 8.46617 4.05908 8.0226 3.2651 7.22304C2.47033 6.4227 2.03472 5.36034 2.03867 4.23269C2.03867 1.90013 3.93803 0 6.27298 0ZM6.27298 1.19455C4.59742 1.19455 3.23322 2.55873 3.23322 4.23508C3.23006 5.04657 3.54224 5.8071 4.11164 6.38128C4.68184 6.95467 5.44157 7.27242 6.25068 7.2756L6.27298 7.86571V7.2756C7.94933 7.2756 9.31351 5.91143 9.31351 4.23508C9.31351 2.55873 7.94933 1.19455 6.27298 1.19455Z"
        fill="#F7F5AD"
      />
    </svg>
  )
}

export default InviteFamIcon
