import { useEffect, useState } from 'react'
import { useForm, Controller, useFormState } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { isArray, isEmpty, isObject, isString, reduce } from 'lodash'

import routes from '../../routers'
import { createRecipe, getRecipeById } from '../../api/recipe'
import { setLoading } from '../../redux/actions/ui'
import {
  ACTIVE_STATUS,
  CATEGORIES_RECIPE,
  DEFAULT_VALUES_ADD_RECIPE_FIRST_STEP,
  LIMIT_RECIPES,
  MIN_LENGTH,
  RECIPE_NAME_PATTERN,
  STATUS_NEED_SUBSCRIBE,
  STATUS_TRIAL,
} from '../../utils/constants'
import useDirty from '../../utils/hooks/useDirty'
import { checkAbilityToCreate } from '../../utils/auth'
import { setIncreaseNumberRecipes } from '../../redux/actions/user'
import InputSimple from '../../components/InputSimple'
import CustomInputFile from '../../components/CustomInputFile'
import ChoseGroupsComponent from '../../components/ChoseGroupsComponent'
import CustomCheckbox from '../../components/CustomCheckbox'
import LayoutRecipe from './components/LayoutRecipe'
import RecipeTitle from './components/RecipeTitle'

import './style.css'

const AddRecipe = ({ myGroups, user }) => {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const { filterDirtyFields } = useDirty()
  const [required, setRequired] = useState(true)
  const [to_publish, setToPublish] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const { control, handleSubmit, reset, setValue } = useForm()
  const { dirtyFields } = useFormState({ control })
  const [idRecipe, setIdRecipe] = useSearchParams({})
  const idRecipeForm = idRecipe.get('id')

  const categoriesSelected = (e, value, item, onChange) => {
    const filtered = !e.target.checked
      ? value?.filter((category) => item?.id !== category)
      : [...value, item?.id]
    onChange(filtered)
  }

  const onSubmit = (data) => {
    const newData = filterDirtyFields({
      dirtyFields,
      data,
      current_step: 1,
      urlParams: idRecipeForm,
    })
    const checkFileImage = isObject(data?.image) ? { image: data?.image } : {}
    const checkEmptyFile =
      !isString(data?.image) || !isEmpty(data?.image) ? checkFileImage : { image: '' }

    const dataField = new FormData()
    for (const [key, value] of Object.entries({
      ...newData,
      ...checkEmptyFile,
      to_publish: to_publish && required,
    })) {
      dataField.append(key, isArray(value) ? JSON.stringify(value) : value)
    }

    setDisableButton(true)
    createRecipe(dataField)
      .then(({ data }) => {
        setToPublish(required)
        !idRecipeForm && dispatch(setIncreaseNumberRecipes())
        if (required) {
          return navigation(`${routes.addRecipeSecond}?id=${data?.id}`)
        }
        setIdRecipe({ id: data?.id })
      })
      .catch((e) => console.log(e))
      .finally(() => setDisableButton(false))
  }

  useEffect(() => {
    const checkUserLimit =
      (user?.status === STATUS_TRIAL || user?.status === STATUS_NEED_SUBSCRIBE) &&
      user?.recipes_count >= LIMIT_RECIPES
    checkAbilityToCreate(isEmpty(idRecipeForm) && checkUserLimit)(dispatch, navigation)
    if (!isEmpty(idRecipeForm)) {
      dispatch(setLoading(true))
      return getRecipeById(idRecipeForm)
        .then(({ data }) => {
          const filterDataRecipe = filterDirtyFields({
            dirtyFields: DEFAULT_VALUES_ADD_RECIPE_FIRST_STEP,
            data,
          })
          setToPublish(data?.status === ACTIVE_STATUS)
          reset({ ...DEFAULT_VALUES_ADD_RECIPE_FIRST_STEP, ...filterDataRecipe })
          return reduce(
            filterDataRecipe,
            (res, value, key) => {
              setValue(key, value, { shouldDirty: true })
            },
            {}
          )
        })
        .catch(() => navigation(routes.notFound))
        .finally(() => dispatch(setLoading(false)))
    }
    reset(DEFAULT_VALUES_ADD_RECIPE_FIRST_STEP)
  }, [])

  return (
    <LayoutRecipe
      step={1}
      navigationNext={routes.addRecipeSecond}
      navigationPrevTitle="Next step"
      onSubmit={handleSubmit(onSubmit)}
      disableButtonSave={disableButton}
      isLoading={disableButton}
      setRequired={setRequired}
    >
      <RecipeTitle
        step={1}
        title="Add a New Recipe"
        subTitle="It just takes a few minutes, but your new recipe will last for generations to come. Follow the steps and add a new recipe."
        widthSubTitle={417}
      />
      <Controller
        name="name"
        control={control}
        rules={{
          required,
          pattern: {
            value: RECIPE_NAME_PATTERN,
          },
          minLength: MIN_LENGTH,
          validate: { value: (value) => !isEmpty(dirtyFields) || !isEmpty(value) },
        }}
        defaultValue=""
        render={({ field, fieldState }) => (
          <InputSimple
            {...field}
            {...fieldState}
            nameInputPlaceholder="Recipe Name"
            styleInput="inputRecipeTitle grayPlaceholder"
            className="innerRecipeName"
          />
        )}
      />
      <div>
        <p className="categoriesTitle">Choose Categories (select one or more):</p>
        <div className="recipeCategoriesWrap">
          {CATEGORIES_RECIPE.map((item, index) => (
            <Controller
              name="categories"
              key={index}
              control={control}
              rules={{
                required,
                validate: {
                  value: (value) => !isEmpty(dirtyFields) || !isEmpty(value),
                },
              }}
              render={({ field: { onChange, value }, fieldState }) => {
                return (
                  <CustomCheckbox
                    {...fieldState}
                    onChange={(e) => categoriesSelected(e, value, item, onChange)}
                    markBrown
                    styleCheckBox="biggerCheckBox"
                    classInner="recipeCategoriesItem"
                    textLabel={item?.name}
                    checked={value?.includes(item?.id)}
                    labelStyle="recipeCategoriesText"
                    value={item?.id}
                  />
                )
              }}
            />
          ))}
        </div>
      </div>
      <Controller
        name="description"
        control={control}
        rules={{
          required,
          minLength: MIN_LENGTH,
          maxLength: 4096,
          validate: { value: (value) => !isEmpty(dirtyFields) || !isEmpty(value) },
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <Form.Control
                {...field}
                as="textarea"
                className={`textAreaDescription grayPlaceholder ${error ? 'borderError' : ''}`}
                placeholder="Write a short summary or description of your delicious recipe here."
              />
              {error?.type === 'maxLength' && (
                <span className="errorDescriptionLength">
                  Large number of characters, max length 4096
                </span>
              )}
            </>
          )
        }}
      />

      <Controller
        name="groups"
        control={control}
        rules={{
          required: false,
        }}
        render={({ field: { onChange, value }, fieldState }) => {
          return (
            <ChoseGroupsComponent
              {...fieldState}
              groups={myGroups}
              onChange={onChange}
              value={value}
            />
          )
        }}
      />
      <Controller
        name="image"
        control={control}
        defaultValue=""
        rules={{
          required: false,
        }}
        render={({ field, fieldState }) => <CustomInputFile {...field} {...fieldState} />}
      />
    </LayoutRecipe>
  )
}

export default connect(({ groups, user }) => ({
  myGroups: groups.data,
  user: user.data,
}))(AddRecipe)
