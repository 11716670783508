import LayoutRegistration from '../../../../../components/LayoutRegistration'

const CheckEmail = () => {
  return (
    <LayoutRegistration title="Check Email">
      <div className="modalSignUp">
        <p className="modalTitle">Please Check Your Email!</p>
        <p className="modalSubTitle">
          Confirm your account details via the email sent to your inbox just now.
        </p>
      </div>
    </LayoutRegistration>
  )
}

export default CheckEmail
