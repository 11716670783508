import { useEffect, useState } from 'react'
import { isNil, toNumber } from 'lodash'
import fileDownload from 'js-file-download'
import moment from 'moment'

import routes from '../../routers'
import { DATA_TABLE_RECIPES_HEAD, RECIPE_STATUS } from '../../utils/constants'
import { exportAll, getAllRecipe } from '../../api/admin'
import LayoutRecipe from './components/LayoutAdmin'
import Table from './components/TableComponent'

import './style.css'

const AdminRecipeList = () => {
  const [selectedRecipe, setSelectedRecipe] = useState([])
  const [dataRecipe, setDataRecipe] = useState([])

  const selectAllRecipe = () => {
    setSelectedRecipe(dataRecipe?.map((item) => toNumber(item?.id)))
  }

  const selectRecipe = (e) => {
    const id = toNumber(e?.target.value)
    setSelectedRecipe((oldState) => {
      return oldState?.includes(id)
        ? oldState?.filter((idRecipe) => idRecipe !== id)
        : [...oldState, toNumber(id)]
    })
  }

  const exportRecipeAll = () => {
    if (dataRecipe.length === selectedRecipe.length) {
      return exportAll().then((data) => fileDownload(data, 'recipes.xlsx'))
    }
    return exportAll({ ids: selectedRecipe }).then((data) => fileDownload(data, 'recipes.xlsx'))
  }

  useEffect(() => {
    getAllRecipe()
      .then(({ data }) => {
        const newData = data?.map((item) => {
          return {
            id: item?.id,
            recipeName: [
              {
                name: !isNil(item?.name) ? item?.name : '',
                link: `${routes.adminRecipe}/${item?.id}`,
              },
            ],
            ownerName: [{ name: item?.author_name, link: `${routes.adminUser}/${item?.user_id}` }],
            groups: !isNil(item?.groups)
              ? item?.groups?.map((group) => ` ${group}`).toString()
              : '',
            categories: !isNil(item?.categories)
              ? item?.categories?.map((category) => ` ${category}`).toString()
              : '',
            recipeIdent: item?.id,
            status: RECIPE_STATUS?.[item?.status],
            date: moment(item?.created_at).format('MM/DD/YYYY'),
            selectRecipe: selectRecipe,
          }
        })
        setDataRecipe(newData)
      })
      .catch((e) => console.log(e))
  }, [])

  return (
    <LayoutRecipe>
      <Table
        ITEMS_PER_PAGE={18}
        dataHeader={DATA_TABLE_RECIPES_HEAD}
        dataTable={dataRecipe}
        selectedRecipe={selectedRecipe}
        searchShow
        tableClass="fixedTable"
        exportButtonSearch
        exportClick={exportRecipeAll}
        selectAll={selectAllRecipe}
      />
    </LayoutRecipe>
  )
}

export default AdminRecipeList
