import { useEffect, useMemo, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link, useSearchParams } from 'react-router-dom'

import routes from '../../routers'
import { removeUserTokenFromLocalStorage } from '../../utils/auth'
import {
  EMAIL_PATTERN,
  REQUIRED_FIELD_MESSAGE,
  SITE_KEY,
  TEMPORARY_TOKEN,
} from '../../utils/constants'
import { confirmEmail } from '../../api/auth'
import { Lock, Mail } from '../../components/SvgIcon'
import useLogin from '../../utils/hooks/useLogin'
import LayoutRegistration from '../../components/LayoutRegistration'
import HookForm from '../../components/HookForm'

const SignIn = () => {
  const loginFormRef = useRef(null)
  const { handleLogin } = useLogin()
  const [query] = useSearchParams()
  const [linkToSignUp, setLinkToSignUp] = useState(routes.signUp)
  const [disableBtn, setDisableBtn] = useState(true)

  const code = query.get('confirm')
  const { idGroup, slugGroup } = { idGroup: query.get('idGroup'), slugGroup: query.get('slug') }

  const defaultFields = {
    className: 'mt-8',
    defaultValue: '',
  }

  const fields = [
    {
      name: 'login',
      rules: {
        required: REQUIRED_FIELD_MESSAGE,
        pattern: {
          value: EMAIL_PATTERN,
          message: 'This field must have valid email',
        },
      },
      icon: <Mail />,
      nameLabel: 'Email Address',
      inputType: 'email',
      ...defaultFields,
    },
    {
      name: 'password',
      rules: {
        required: REQUIRED_FIELD_MESSAGE,
        minLength: { value: 6, message: 'Password must be at least 6 characters' },
      },
      icon: <Lock />,
      nameLabel: 'Password',
      inputType: 'password',
      ...defaultFields,
    },
  ]

  const handleSubmit = (data) => {
    handleLogin(data, loginFormRef, idGroup, slugGroup)
  }

  const Footer = useMemo(() => {
    return (
      <div>
        <div className="innerRecaptcha">
          <ReCAPTCHA onChange={() => setDisableBtn(false)} sitekey={SITE_KEY} size="normal" />
        </div>
        <button disabled={disableBtn} type="submit" className="buttonWrapper buttonText">
          SIGN IN
        </button>
        <Link to={routes.resetPassword} className="modalForget opacity">
          Forget your password?
        </Link>
      </div>
    )
  }, [disableBtn])

  useEffect(() => {
    removeUserTokenFromLocalStorage(TEMPORARY_TOKEN)
    if (!isEmpty(code)) {
      const decodeCode = window.atob(code) || ''
      const [codeConfirm, login] = decodeCode.split(':')
      confirmEmail({ code: codeConfirm, login: login }).catch((e) => console.log(e))
    }
    if (idGroup && slugGroup) {
      setLinkToSignUp(`${routes.signUp}?idGroup=${idGroup}&slug=${slugGroup}`)
    }
  }, [])

  return (
    <LayoutRegistration routesRedirect={linkToSignUp} title="Sign-In">
      <div className="modalSignUp">
        <p className="modalTitle">Sign in to your account</p>
        <p className="modalSubTitle">
          or sign up for a{' '}
          <Link to={linkToSignUp} className="subTitleSignUp opacity">
            free account
          </Link>
        </p>
        <HookForm
          ref={loginFormRef}
          className="formSignUp"
          onSubmit={handleSubmit}
          fields={fields}
          Footer={Footer}
        />
      </div>
    </LayoutRegistration>
  )
}
export default SignIn
