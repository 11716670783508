import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { isEmpty, isNil } from 'lodash'
import { useForm, Controller } from 'react-hook-form'

import { setLoading } from '../../redux/actions/ui'
import { getVideoRecipe } from '../../api/recipe'
import { addNewVideo } from '../../api/admin'
import InputSimple from '../../components/InputSimple'
import ButtonCustom from '../../components/ButtonCustom'
import LayoutRecipe from './components/LayoutAdmin'

const ChangeVideoTitle = () => {
  const { control, handleSubmit, reset } = useForm()

  const changeTitle = (data) => {
    addNewVideo(data)
      .then(() => reset({ text: data?.text }))
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    getVideoRecipe()
      .then(({ data }) => reset({ text: data?.text }))
      .catch((e) => console.log(e))
  }, [])

  return (
    <form onSubmit={handleSubmit(changeTitle)} className="innerVideoTitle">
      <Controller
        name="text"
        control={control}
        rules={{ required: false }}
        render={({ field }) => <InputSimple {...field} className="styleTextVideo" />}
      />
      <ButtonCustom style="btnChangeTitleVideo" title="Save" />
    </form>
  )
}

const AdminVideo = () => {
  const dispatch = useDispatch()
  const fileRef = useRef()
  const { handleSubmit, control } = useForm()
  const [urlVideo, setUrlVideo] = useState(null)

  const onSubmit = (data) => {
    dispatch(setLoading(true))
    addNewVideo(data).finally(() => dispatch(setLoading(false)))
  }

  useEffect(() => {
    dispatch(setLoading(true))
    getVideoRecipe()
      .then(({ data }) => setUrlVideo(data?.url))
      .catch((e) => console.log(e))
      .finally(() => dispatch(setLoading(false)))
  }, [])

  return (
    <LayoutRecipe>
      <div style={{ width: '100%' }}>
        <ChangeVideoTitle />
        <form onSubmit={handleSubmit(onSubmit)} className="innerVideoForm">
          {!isNil(urlVideo) ? (
            <video className="isEmptyVideo" playsInline controls src={urlVideo} />
          ) : (
            <p>Not Found</p>
          )}
          <Controller
            name="video"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <div className={`recipeUploadWrap ${error ? 'borderError' : ''} `}>
                <input
                  ref={fileRef}
                  onChange={(e) => {
                    const file = e.target?.files?.[0]

                    if (!isNil(file)) {
                      setUrlVideo(URL.createObjectURL(file))
                      onChange(file)
                    }
                  }}
                  id="video"
                  className="inputFilePhoto"
                  type="file"
                  accept=".mp4, .mov, .wmv, .flv, .avi, .avchd, .webm, .mkv, "
                />
                <label className={`recipeButtonUpload buttonWrapper`} htmlFor="video">
                  Upload new video
                </label>
              </div>
            )}
          />
          {!isEmpty(fileRef.current?.value) ? (
            <ButtonCustom style="saveVideoButton" title="Save" />
          ) : null}
        </form>
      </div>
    </LayoutRecipe>
  )
}

export default AdminVideo
