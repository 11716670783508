import { useEffect, useState } from 'react'
import { delay } from 'bluebird'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { setModalTrial } from '../../redux/actions/ui'
import { ACTIVE_STATUS } from '../../utils/constants'
import routes from '../../routers'
import { getUserInitailData } from '../../utils/auth'

import './styles.css'

const CheckSubscription = () => {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const [countRender, setCountRender] = useState(0)
  const [error, setError] = useState(false)

  const checkStatus = () => {
    if (countRender !== 3) {
      setCountRender((oldState) => oldState + 1)
      return getUserInitailData().then((data) => {
        if (ACTIVE_STATUS === data?.status) {
          dispatch(setModalTrial(false))
          navigation(routes.userProfile)
        }
      })
    }
    return setError(true)
  }

  useEffect(() => {
    countRender === 0 ? checkStatus() : delay(10000).then(checkStatus)
  }, [countRender])

  return (
    <div className="wrapper innerLoadingPage">
      {!error ? (
        <>
          <a href="https://getmorsel.com/">
            <img src="/logo.svg" className="loadingLogo" alt="Loading" />
          </a>
          <p className="headerText">Your account is being upgraded, please wait to be redirected</p>
        </>
      ) : (
        <p className="headerText">Something went wrong</p>
      )}
    </div>
  )
}

export default CheckSubscription
