import r from '../utils/request'

export const subscribe = (plan_id) => {
  return r.post('/chargebee/subscribe', { plan_id })
}

export const checkSubscribe = () => {
  return r.get('/chargebee/check-subscription')
}

export const shareSubscription = (params) => {
  return r.put('/chargebee/share-subscription', params)
}
