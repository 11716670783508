import { useEffect, useState } from 'react'
import { toNumber } from 'lodash'
import moment from 'moment'

import { DATA_TABlE_USERS_HEAD } from '../../utils/constants'
import routes from '../../routers'
import { getAllUsers } from '../../api/admin'
import LayoutRecipe from './components/LayoutAdmin'
import Table from './components/TableComponent'

import './style.css'

const AdminUserList = () => {
  const [userList, setUserList] = useState([])

  useEffect(() => {
    getAllUsers()
      .then(({ data }) => {
        const newData = data.map((item) => {
          return {
            id: item?.id,
            fullName: [{ name: item?.fullname, link: `${routes.adminUser}/${item?.id}` }],
            emailAddress: item?.email,
            groupsIn: toNumber(item?.group_count),
            recipesUpload: toNumber(item?.recipe_count),
            dateJoined: moment(item?.created_at).format('MM/DD/YYYY'),
            subscriptionDue: moment(item?.end_subscription).format('MM/DD/YYYY'),
          }
        })
        setUserList(newData)
      })
      .catch((e) => console.log(e))
  }, [])

  return (
    <LayoutRecipe>
      <Table
        ITEMS_PER_PAGE={18}
        dataHeader={DATA_TABlE_USERS_HEAD}
        dataTable={userList}
        tableClass="fixedTable"
        searchShow
      />
    </LayoutRecipe>
  )
}

export default AdminUserList
