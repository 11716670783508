import { isNil } from 'lodash'

import routes, { categoriesGroup } from '../../routers'
import { acceptInvite } from '../../api/group'
import { setLoading } from '../../redux/actions/ui'
import { setGroups } from '../../redux/actions/groups'

const useInvited = () => {
  const invited = (user, navigation, idGroup, slug) => (dispatch) => {
    if (!isNil(user)) {
      return acceptInvite({ group_id: idGroup, slug })
        .then(({ data }) => dispatch(setGroups(data)))
        .then(() => navigation(categoriesGroup(slug, idGroup)))
        .catch(() => navigation(routes.notFound))
        .finally(() => dispatch(setLoading(false)))
    }
    navigation(`${routes.signUp}?idGroup=${idGroup}&slug=${slug}`)
    dispatch(setLoading(false))
  }
  return { invited }
}

export default useInvited
