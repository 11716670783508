import { Modal } from 'react-bootstrap'

import { DATA_DROP_REPORT, INPUT_TYPES } from '../../../utils/constants'
import { reportRecipe } from '../../../api/recipe'
import ButtonCustom from '../../../components/ButtonCustom'
import DropDownCustom from '../../../components/DropDownCustom'
import HookForm from '../../../components/HookForm'

import './style.css'

const ModalReport = ({ recipe_id, show, onClick }) => {
  const fields = [
    {
      name: 'type',
      value: 1,
      rules: { required: true },
      ItemMenu: DATA_DROP_REPORT,
      custom: true,
      text: 'I would like to report a',
      innerStyle: 'modalReportDropWrap',
      textStyle: 'modalReportText',
      type: INPUT_TYPES.CUSTOM_DROPDOWN,
      settings: { style: 'reportDropDown', titleStyle: 'reportDropDownTitle' },
      styleToggleList: 'listForm',
      Component: DropDownCustom,
      globalClassName: 'dropdownReportGlobal',
    },
    {
      name: 'message',
      rules: { required: true },
      as: 'textarea',
      defaultValue: '',
      nameInputPlaceholder: 'Please leave additional information on what you are reporting.',
      styleInput: 'modalTextArea',
    },
  ]

  const handleSubmit = (data) => {
    reportRecipe({ ...data, recipe_id })
      .then(() => onClick())
      .catch((e) => console.log(e))
  }

  return (
    <Modal
      contentClassName="modalReportContent"
      dialogClassName="modalReportDialog"
      show={show}
      centered={true}
      onHide={onClick}
    >
      <div className="innerFormReport">
        <div onClick={onClick} className="modalClose opacity">
          X
        </div>
        <p className="modalReportTitle">Report something wrong with this recipe.</p>
        <HookForm
          fields={fields}
          onSubmit={handleSubmit}
          Footer={() => (
            <ButtonCustom style="modalButton reportButton" title="Report This Recipe" />
          )}
        />
      </div>
    </Modal>
  )
}

export default ModalReport
