import { Modal } from 'react-bootstrap'

const ModalRecipePhoto = ({ show, onHide, image }) => {
  return (
    <Modal dialogClassName="innerModalImage" fullscreen show={show} centered={true} onHide={onHide}>
      <div onClick={onHide} className="modalClose opacity closeImg">
        X
      </div>
      <div className="innerImageScreen">
        <img className="fullScreenImg" src={image} alt="Image" />
      </div>
    </Modal>
  )
}

export default ModalRecipePhoto
