import React, { useState } from 'react'

import ModalAccount from './Modals/ModalAccount'

const TrashButton = ({ deleteRecipe }) => {
  const [trashModal, setTrashModal] = useState(false)

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <p onClick={() => setTrashModal(true)} className="opacity">
        Trash
      </p>
      <ModalAccount show={trashModal} onHide={() => setTrashModal(false)}>
        <div className="innerModalRemoveUser">
          <p className="textModalRemoveFirst">Are you sure you would like to delete this recipe?</p>
          <button onClick={deleteRecipe} className="removeUserButton">
            Yes, I’m sure
          </button>
        </div>
      </ModalAccount>
    </div>
  )
}

export default TrashButton
