import { createAction } from 'redux-actions'

const SET_USER = 'SET_USER'
const SET_ROLE = 'SET_ROLE'
const SET_NEW_PLAN_ID = 'SET_NEW_PLAN_ID'
const SET_LEAVE_FROM_FAMILY_PLAN = 'SET_LEAVE_FROM_FAMILY_PLAN'
const SET_REDUCE_NUMBER_RECIPES = 'SET_REDUCE_NUMBER_RECIPES'
const SET_INCREASE_NUMBER_RECIPES = 'SET_INCREASE_NUMBER_RECIPES'

const setUser = createAction(SET_USER)
const setRoleUser = createAction(SET_ROLE)
const setNewPlanId = createAction(SET_NEW_PLAN_ID)
const setLeaveFromFamilyPlan = createAction(SET_LEAVE_FROM_FAMILY_PLAN)
const setReduceNumberRecipes = createAction(SET_REDUCE_NUMBER_RECIPES)
const setIncreaseNumberRecipes = createAction(SET_INCREASE_NUMBER_RECIPES)

export {
  setUser,
  setRoleUser,
  setNewPlanId,
  setLeaveFromFamilyPlan,
  setReduceNumberRecipes,
  setIncreaseNumberRecipes,
}
