import { Form } from 'react-bootstrap'
import './style.css'

// TODO: remove default props
const Index = ({
  className = '',
  styleInput = '',
  nameLabel = '',
  nameInputPlaceholder = '',
  inputType = 'text',
  controlIdInput = 'Username',
  value,
  onChange,
  onBlur,
  error,
  disabled = false,
  icon = false,
  readOnly = false,
  as = 'input',
}) => {
  const inputStyle = icon ? `iconPlaceHolder ${styleInput}` : styleInput
  return (
    <Form.Group className={`formSignUpGroup ${className}`} controlId={controlIdInput}>
      {nameLabel && (
        <div>
          <Form.Label className="formSignUpLabel" style={error ? { color: 'red' } : {}}>
            {!error ? nameLabel : error.message}
          </Form.Label>
        </div>
      )}
      <div className="inputContent">
        <Form.Control
          as={as}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={inputStyle}
          type={inputType}
          placeholder={nameInputPlaceholder}
          readOnly={readOnly}
          style={error ? { borderColor: 'red' } : {}}
        />
        {icon && <div className="inputIcon">{icon}</div>}
      </div>
      {error && !nameLabel ? (
        <p className="formSignUpLabel" style={{ color: 'red', textAlign: 'left' }}>
          {error?.message}
        </p>
      ) : null}
    </Form.Group>
  )
}
export default Index
