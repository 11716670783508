import React, { useState } from 'react'
import { SortIcon } from '../../../../components/SvgIcon'
import '../../style.css'

const Header = ({ headers, onSorting, onClick }) => {
  const [sortingField, setSortingField] = useState('')
  const [sortingOrder, setSortingOrder] = useState('asc')

  const onSortingChange = (field) => {
    const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'
    setSortingField(field)
    setSortingOrder(order)
    onSorting(field, order)
  }
  return (
    <thead>
      <tr>
        {headers.map(({ name, field, sortable }) => (
          <>
            {field === 'selected' ? (
              <th className="tableHeader" key={name} onClick={onClick}>
                {name}
              </th>
            ) : (
              <th
                key={name}
                className="tableHeader"
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {sortable && (
                  <>
                    &nbsp;
                    <SortIcon />
                  </>
                )}
              </th>
            )}
          </>
        ))}
      </tr>
    </thead>
  )
}

export default Header
