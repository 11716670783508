import React from 'react'

const DeleteIcon = () => (
  <svg width={40} height={40} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.668 0C35.446 0 40 4.756 40 11.832v16.336C40 35.244 35.446 40 28.666 40H11.33C4.552 40 0 35.244 0 28.168V11.832C0 4.756 4.552 0 11.33 0h17.338Zm0 3H11.33C6.27 3 3 6.466 3 11.832v16.336C3 33.534 6.27 37 11.33 37h17.336C33.728 37 37 33.534 37 28.168V11.832C37 6.466 33.728 3 28.668 3ZM16.261 14.125l3.735 3.735 3.733-3.73a1.498 1.498 0 1 1 2.12 2.12l-3.733 3.73 3.737 3.74a1.498 1.498 0 0 1-1.06 2.56c-.384 0-.768-.147-1.06-.44l-3.737-3.74-3.731 3.733c-.292.294-.676.44-1.06.44s-.768-.146-1.06-.44a1.498 1.498 0 0 1 0-2.12l3.731-3.733-3.735-3.735a1.498 1.498 0 1 1 2.12-2.12Z"
      fill="#EF2127"
    />
  </svg>
)

export default DeleteIcon
