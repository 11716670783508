import React from 'react'
import '../style.css'
import styles from '../style.module.css'
import StepsComponent from './StepsComponent'

const RecipeTitle = ({ title = '', subTitle = '', widthSubTitle = 100, step }) => {
  return (
    <>
      <p className={styles.recipeTitle}>{title}</p>
      <div className="mobView">
        <StepsComponent step={step} classNameStep="innerHeaderSteps" />
      </div>

      <p style={{ maxWidth: `${widthSubTitle}px` }} className={styles.recipeTextSmall}>
        {subTitle}
      </p>
    </>
  )
}

export default RecipeTitle
