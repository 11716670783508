import React, { useEffect, useRef, useState } from 'react'
import { isEmpty, reduce } from 'lodash'

import { changeUserInfo } from '../../../../api/admin'
import { INPUT_TYPES } from '../../../../utils/constants'
import HookForm from '../../../../components/HookForm'

const NotificationChangesForm = ({ allNotification, userId }) => {
  const refCheck = useRef(null)
  const [disable, setDisable] = useState(true)
  const checkFormRef = useRef(null)

  const handleSubmit = ({ checkNotifications, recipe_published_to_group, user_joined_group }) => {
    const notifications = reduce(
      { recipe_published_to_group, user_joined_group },
      (res, value, key) => (value ? [...res, key] : res),
      []
    )
    changeUserInfo(userId, { notifications })
      .then(() => {
        checkFormRef.current.reset({
          checkNotifications,
          recipe_published_to_group,
          user_joined_group,
        })
      })
      .catch((e) => console.log(e))
  }

  const onChange = (e) => {
    checkFormRef.current.reset({
      checkNotifications: e?.target?.checked,
      new_recipe: false,
      new_fam: false,
    })
    setDisable(e?.target?.checked)
  }

  const fields = [
    {
      name: 'checkNotifications',
      booleanRef: refCheck,
      onClick: onChange,
      defaultValue: true,
      type: INPUT_TYPES.CHECKBOX,
      rules: { validate: { value: () => !isEmpty(checkFormRef.current?.dirtyFields) } },
      classInner: 'adminCheckWrap',
      textLabel: 'Email',
      style: 'adminCheckBox',
      markBrown: true,
    },
    {
      name: 'user_joined_group',
      type: INPUT_TYPES.CHECKBOX,
      defaultValue: false,
      checkedFirst: !disable,
      rules: {
        required: false,
        validate: { value: () => !isEmpty(checkFormRef.current?.dirtyFields) },
      },
      classInner: 'adminCheckWrap',
      style: 'adminCheckBox',
      textLabel: 'New Member Joins',
      markBrown: true,
    },
    {
      name: 'recipe_published_to_group',
      type: INPUT_TYPES.CHECKBOX,
      defaultValue: false,
      checkedFirst: !disable,
      rules: {
        required: false,
        validate: { value: () => !isEmpty(checkFormRef.current?.dirtyFields) },
      },
      classInner: 'adminCheckWrap',
      style: 'adminCheckBox',
      textLabel: 'New Recipe Added',
      markBrown: true,
    },
  ]

  useEffect(() => {
    checkFormRef.current.reset({
      checkNotifications: !isEmpty(allNotification),
      recipe_published_to_group: allNotification?.includes('recipe_published_to_group'),
      user_joined_group: allNotification?.includes('user_joined_group'),
    })
    setDisable(!isEmpty(allNotification))
  }, [allNotification])

  const FooterForm = () => {
    return (
      <button type="submit" className="btnChangeName changeBtnNotification">
        Change Notifications
      </button>
    )
  }

  return <HookForm ref={checkFormRef} fields={fields} onSubmit={handleSubmit} Footer={FooterForm} />
}

export default NotificationChangesForm
