const UserWithoutPhoto = ({ className }) => {
  return (
    <svg
      className={className}
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100" height="100" rx="42" fill="#FEC925" />
      <g opacity="0.5">
        <path
          d="M50.5 60.5789C66.4987 60.5789 80 63.1786 80 73.2087C80 83.2425 66.4102 85.75 50.5 85.75C34.505 85.75 21 83.1503 21 73.1202C21 63.0864 34.5898 60.5789 50.5 60.5789ZM50.5 12C61.3379 12 70.0216 20.6805 70.0216 31.5108C70.0216 42.3411 61.3379 51.0252 50.5 51.0252C39.6658 51.0252 30.9784 42.3411 30.9784 31.5108C30.9784 20.6805 39.6658 12 50.5 12Z"
          fill="#F7F5AD"
        />
      </g>
    </svg>
  )
}

export default UserWithoutPhoto
