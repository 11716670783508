const CloseSquare = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.68 0C35.46 0 40 4.76 40 11.84V28.182C40 35.242 35.46 40 28.68 40H11.34C4.56 40 0 35.242 0 28.182V11.84C0 4.76 4.56 0 11.34 0H28.68ZM26.02 13.942C25.34 13.26 24.24 13.26 23.54 13.942L20 17.5L16.44 13.942C15.74 13.26 14.64 13.26 13.96 13.942C13.28 14.622 13.28 15.742 13.96 16.42L17.52 19.982L13.96 23.522C13.28 24.222 13.28 25.322 13.96 26C14.3 26.34 14.76 26.522 15.2 26.522C15.66 26.522 16.1 26.34 16.44 26L20 22.462L23.56 26C23.9 26.362 24.34 26.522 24.78 26.522C25.24 26.522 25.68 26.34 26.02 26C26.7 25.322 26.7 24.222 26.02 23.542L22.46 19.982L26.02 16.42C26.7 15.742 26.7 14.622 26.02 13.942Z"
        fill="#EF2127"
      />
    </svg>
  )
}

export default CloseSquare
