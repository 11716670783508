import { isEmpty, reduce } from 'lodash'

import routes from '../routers'

export const AUTH_TOKEN = 'AUTHORIZATION_TOKEN'
export const TEMPORARY_TOKEN = 'TEMPORARY_TOKEN'
export const API_ROOT =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://recipes.getmorsel.com/api/v1'
    : 'http://16.170.126.44/api/v1'

export const ENABLED_SENTRY = process.env.REACT_APP_ENV === 'production'

export const USER_LINK_TO_GROUP = 'USER_LINK_TO_GROUP'
export const PUBLIC_GA_ID = process.env.REACT_APP_PUBLIC_GA_ID
export const PUBLIC_GA_MANAGER_ID = process.env.REACT_APP_PUBLIC_GA_MANAGER_ID

export const LIMIT_RECIPES = 10

export const MIN_LENGTH = 2

export const SUCCESS_COPY_TEXT = 'Copied to clipboard'
export const COPY_TEXT = 'Copy Link'

export const ROLE_USER_ON_GROUP = 1
export const ROLE_ADMIN = 2
export const ROLE_OWNER = 3

export const ROLE_USER_IN_GROUP = {
  [ROLE_USER_ON_GROUP]: 'User',
  [ROLE_ADMIN]: 'Admin',
  [ROLE_OWNER]: 'Owner',
}

export const CHECK_SITE_KEY_PROD_OR_DEV =
  process.env.REACT_APP_ENV === 'production'
    ? '6LdwuY8eAAAAAJbAh5GEarz_Xyji6EQEWi-mGB6c'
    : '6LfTdE4jAAAAAP7GT_k_kfOo1gIuDHkDFN0XZl14'

export const SITE_KEY =
  process.env.NODE_ENV !== 'development'
    ? CHECK_SITE_KEY_PROD_OR_DEV
    : '6Les-o0eAAAAADC9KiT-XSsVcxUt1C1IlDtyx7uS'

export const LIMIT_SIZE_IMAGE = 15728640

export const PAGINATION_SIZE = 12

export const ACTIVE_STATUS = 1
export const STATUS_TRIAL = 7
export const DRAFT_STATUS = 5
export const STATUS_NEED_SUBSCRIBE = 6
export const REPORT_STATUS = 8
export const MIN_COUNT_RECIPE = 12
export const MIN_LAST_PAGE = 1

export const OPTIONS_TBLS = [
  { id: null, name: '' },
  { id: 1, name: 'Tbls' },
  { id: 2, name: 'tsp' },
  { id: 3, name: 'cup(s)' },
  { id: 4, name: 'fl oz.' },
  { id: 5, name: 'quart(s)' },
  { id: 6, name: 'package' },
  { id: 7, name: 'lb(s)' },
  { id: 8, name: 'kg(s)' },
  { id: 9, name: 'stick(s)' },
]

export const CATEGORIES_RECIPE = [
  { id: 1, name: 'Breakfast' },
  { id: 2, name: 'Beverages' },
  { id: 3, name: 'Lunch' },
  { id: 4, name: 'Appetizers / Snacks' },
  { id: 5, name: 'Summer Holidays' },
  { id: 6, name: 'Dinner' },
  { id: 7, name: 'Desserts' },
  { id: 8, name: 'Winter Holidays' },
]

export const MORSEL_PRO = 'Morsel-Pro-USD-Yearly'
export const MORSEL_PRO_FAMILY = 'Morsel-Family-Pro-USD-Yearly'

export const DROPDOWN_SUBSCRIPTION = [
  {
    id: MORSEL_PRO,
    name: 'Morsel Pro',
  },
  {
    id: MORSEL_PRO_FAMILY,
    name: 'Morsel Family Pro',
  },
]

export const PRICE_MORSEL_PRO = '29.99'
export const PRICE_MORSEL_FAMILY_PRO = '120.00'

export const TITLE_BY_ID = {
  [MORSEL_PRO]: 'Morsel Pro',
  [MORSEL_PRO_FAMILY]: 'Morsel Family Pro',
}

export const DATA_DROP_REPORT = [
  { id: 1, name: 'inappropriate image' },
  { id: 2, name: 'foul language' },
  { id: 3, name: 'inappropriate audio recording' },
  { id: 4, name: 'bug / error' },
  { id: 5, name: 'inappropriate comment' },
  { id: 6, name: 'other' },
]

export const REQUIRED_FIELD_MESSAGE = 'Required field'
export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i
export const DATE_PATTERN = /\d{2}\/\d{2}\/\d{4}/
export const RECIPE_NAME_PATTERN = /^[0-9а-яА-Яa-zA-Zі ']+$/i
export const URL_PATTERN =
  /^https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/i

export const FIELD_ADVERTISING = [
  {
    idName: 'desktopAd',
    title: 'Desktop',
    innerClass: 'adDesktop',
    textWidth: '400x400',
    is_desktop: true,
  },
  {
    idName: 'mobileAd',
    title: 'Mobile',
    innerClass: 'adMobile',
    textWidth: '350x200',
    is_desktop: false,
  },
]

export const RECIPE_STATUS = {
  [ACTIVE_STATUS]: 'Published',
  [DRAFT_STATUS]: 'Draft',
  [REPORT_STATUS]: 'Reported',
}

export const COLOR_STATUS = {
  [RECIPE_STATUS?.[ACTIVE_STATUS]]: 'rgba(87, 173, 145, 1)',
  [RECIPE_STATUS?.[DRAFT_STATUS]]: 'rgba(55, 25, 12, 1)',
  [RECIPE_STATUS?.[REPORT_STATUS]]: 'rgba(235, 87, 87, 1)',
}

export const DATA_TABlE_USERS_HEAD = [
  { name: 'Full Name', field: 'fullName', sortable: true, link: true },
  { name: 'Email Address', field: 'emailAddress', sortable: true },
  { name: 'Recipes Uploaded', field: 'recipesUpload', sortable: true },
  { name: '# of Groups in', field: 'groupsIn', sortable: true },
  { name: 'Date Joined', field: 'dateJoined', sortable: true },
  { name: 'Subscription Due', field: 'subscriptionDue', sortable: true },
]

export const DATA_TABLE_GROUPS_HEAD = [
  { name: 'Group Name', field: 'groupName', sortable: true, link: true },
  { name: 'Admin Name', field: 'adminName', sortable: true, link: true },
  { name: 'Admin Email', field: 'adminEmail', sortable: true },
  { name: '# of Group Members', field: 'numberGroupMembers', sortable: true },
  { name: '# of Recipes', field: 'numberRecipes', sortable: true },
  { name: 'Group Ident', field: 'ident', sortable: true },
  { name: 'Date Created', field: 'date', sortable: true },
]
export const DATA_TABLE_RECIPES_HEAD = [
  { name: 'Selected All', field: 'selected', sortable: false, checkBox: true },
  { name: 'Recipe Name', field: 'recipeName', sortable: true, link: true },
  { name: 'Owner Name', field: 'ownerName', sortable: true, link: true },
  { name: 'Categories', field: 'categories', sortable: true },
  { name: 'Groups', field: 'groups', sortable: true },
  { name: 'Recipe Ident', field: 'recipeIdent', sortable: true },
  { name: 'Status', field: 'status', sortable: true },
  { name: 'Date Created', field: 'date', sortable: true },
]

export const RECIPES_CREATED_HEADER = [
  { name: 'Name', field: 'name', sortable: true, link: true },
  { name: 'Groups In', field: 'groupsIn', sortable: true },
  { name: 'Category', field: 'category', sortable: true },
]

export const USERS_FAMILY_SUBSCRIBE = [
  { name: 'Email', field: 'email', sortable: true, link: false },
]

export const GROUPS_USER_IN_HEADER = [
  { name: 'Group Name', field: 'name', sortable: true, link: true },
  { name: 'Admin Level', field: 'status', sortable: true },
  { name: 'Joined Group', field: 'dataJoined', sortable: true },
]

export const HEADER_REPORTS_RECIPE = [
  { name: 'User name', field: 'userName', sortable: true, link: true },
  { name: 'Type', field: 'type', sortable: true },
  { name: 'Message', field: 'message', sortable: false },
]

export const DATA_GROUP_MEMBERS_HEADER = [
  { name: 'Name', field: 'name', sortable: true, link: true },
  { name: 'Admin Level', field: 'adminLevel', sortable: true },
  { name: 'Joined Group', field: 'joinedGroup', sortable: true },
]

export const RECIPES_GROUP_HEADER = [
  { name: 'Name', field: 'name', sortable: true, link: true },
  { name: 'Category', field: 'category', sortable: true },
  { name: 'Created By', field: 'createdBy', sortable: true },
]

export const NAVIGATION_MENU = [
  { id: '1', text: 'notifications', routes: routes.notification },
  { id: '2', text: 'invite a member', routes: routes.invite },
  { id: '3', text: 'purchase a recipe book', routes: routes.purchaseBook },
  { id: '4', text: 'manage subscription', routes: routes.manageSubscription },
  { id: '5', text: 'manage groups', routes: routes.manageFams },
  { id: '6', text: 'manage password', routes: routes.changePassword },
  { id: '7', text: 'gift a subscription', routes: routes.giftSubscriptions },
]

export const LINK_TO_HELP_PAGE = 'https://getmorsel.com/help-guides/'

export const NAVIGATION_HEADER_MOBILE_MENU = [
  { text: 'Invite Your Fam', routes: routes.invite, target: '' },
  { text: 'Recipes', routes: routes.userProfile, target: '' },
  { text: 'Recipe Drafts', routes: routes.recipeDraft, target: '' },
  { text: 'Help', routes: LINK_TO_HELP_PAGE, target: '_blank' },
]

export const INPUT_TYPES = {
  TEXT: 'INPUT_SIMPLE_TEXT',
  PASSWORD: 'INPUT_PASSWORD',
  CHECKBOX: 'INPUT_CHECKBOX',
  DROPDOWN: 'INPUT_DROPDOWN',
  COLOR_PICKER: 'COLOR_PICKER',
  GROUP_DROPDOWN: 'INPUT_GROUP_DROPDOWN',
  CUSTOM_DROPDOWN: 'INPUT_CUSTOM_DROPDOWN',
}

export const ROLE_GUEST = 'ROLE_GUEST'
export const ROLE_ADMIN_USER = 'ROLE_ADMIN_USER'
export const ROLE_USER = 'ROLE_USER'

export const ROLES = {
  [ROLE_USER]: ROLE_USER,
  [ROLE_ADMIN_USER]: ROLE_ADMIN_USER,
  [ROLE_GUEST]: ROLE_GUEST,
}

export const ROLES_ONLY_USER = [ROLES.ROLE_USER, ROLES.ROLE_ADMIN_USER]

export const ALL_ROLES = [ROLE_USER, ROLE_ADMIN_USER, ROLE_GUEST]

export const DEFAULT_VALUES_ADD_RECIPE_FIRST_STEP = {
  name: '',
  categories: [],
  description: '',
  groups: [],
  image: '',
}

export const DEFAULT_VALUE_ADMIN_FIRST_STEP = {
  categories: [],
  description: '',
  groups: [],
}

export const NAVIGATION_LINK = [
  { title: 'Users', link: routes.adminUserList },
  { title: 'Groups', link: routes.adminGroupList },
  { title: 'Recipes', link: routes.adminRecipeList },
  { title: 'Ad', link: routes.adminAd },
  { title: 'Video', link: routes.adminVideo },
]

export const DEFAULT_VALUES_ADD_RECIPE_SECOND_STEP = {
  amount: '',
  ingredients: [{ quantity: '', measurement: '', name: '' }],
  measurement: '',
}

export const DEFAULT_VALUES_ADD_RECIPE_THIRD_STEP = {
  steps: [{ description: '', step: 1 }],
}

export const DEFAULT_VALUES_CHANGE_PASSWORD = {
  current_password: '',
  new_password: '',
  confirm_password: '',
}

export const DEFAULT_VALUES_CHANGE_USER = { fullname: '', email: '' }

export const SETTINGS_SLIDER = {
  variableWidth: false,
  arrows: true,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 10,
  responsive: [
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 8,
      },
    },
    {
      breakpoint: 675,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 535,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 445,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
}

export const DROPDOWN_HEADER_RECIPES = [
  { title: 'Your recipes', route: routes.userProfile },
  { title: 'Draft recipes', route: routes.recipeDraft },
]

export const DATA_PLANS_OPPORTUNITY = [
  'Unlimited Groups',
  'Unlimited Recipe Uploads',
  'Live Customer Support',
  'Unlimited Recipe Exports',
  'Secure storage of Family Cookbook',
  'Access to Order Your Cookbooks as Gifts',
]

export const DATA_PLANS = [
  {
    title: 'Pro',
    price: 29,
    time: 'yearly per user',
    type: MORSEL_PRO,
    dataList: DATA_PLANS_OPPORTUNITY,
  },
  {
    title: 'Pro - Family Plan',
    price: 120,
    time: 'yearly up to 6 users',
    type: MORSEL_PRO_FAMILY,
    dataList: DATA_PLANS_OPPORTUNITY,
    discount: '30',
  },
]

export const STEPS_ORDER_BOOK = {
  FIRST_STEP: 1,
  SECOND_STEP: 2,
  THIRD_STEP: 3,
  PREVIEW_STEP: 4,
}

export const STEPPER_ORDER_BOOK = [
  { step: 1, name: 'Select Your Group' },
  { step: 2, name: 'Choose Your Recipes' },
  { step: 3, name: 'Add a Cover Photo & Write a Forward' },
  { step: 4, name: 'Preview Cookbook' },
]

export const FIELD_FIRST_STEP_ORDER_BOOK = {
  name: 'group',
  type: INPUT_TYPES.DROPDOWN,
  title: 'Select Your Group',
  custom: true,
  settings: {
    style: 'dropDownPaddingRight',
    titleStyle: 'textDropDownInvite',
  },
  styleToggleList: 'listForm listUserGroup',
  defaultValue: '',
  rules: {
    required: true,
  },
}

export const SELECT_RECIPE_PROPS = {
  name: 'order',
  rules: {
    required: true,
    validate: {
      value: (value) => {
        const countRecipeWithCategory = reduce(
          value,
          (res, value, key) => (isEmpty(value?.recipes) ? res : [...res, ...value?.recipes, key]),
          []
        ).length

        return countRecipeWithCategory > 24
      },
    },
  },
  defaultValue: {},
}

export const FIELD_CHANGE_NAME = [
  {
    name: 'name',
    rules: {
      required: true,
    },
    defaultValue: '',
    nameInputPlaceholder: 'Name category',
    styleInput: 'inputNameCategory',
  },
]

export const MARGIN_PAGES_DISPLAYED = 3
export const PAGE_RANGE_DISPLAYED = 4
export const SETTINGS_PAGINATION = {
  breakClassName: 'breakDots',
  pageClassName: 'innerItemNumberPagination',
  pageLinkClassName: 'itemNumberPagination',
  nextLabel: '>',
  previousLabel: '<',
  activeClassName: 'activePagination',
  previousClassName: 'arrowBtnPagination',
  nextClassName: 'arrowBtnPagination',
  disabledClassName: 'disabledArrow',
  containerClassName: 'paginateBlock',
  marginPagesDisplayed: MARGIN_PAGES_DISPLAYED,
  pageRangeDisplayed: PAGE_RANGE_DISPLAYED,
}
