const Attention = () => (
  <svg width={34} height={31} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.724 0c1.704 0 3.228.883 4.073 2.363L32.8 23.346a4.645 4.645 0 0 1-.015 4.685 4.648 4.648 0 0 1-4.06 2.341H4.703a4.648 4.648 0 0 1-4.062-2.341 4.645 4.645 0 0 1-.015-4.685L12.651 2.36A4.638 4.638 0 0 1 16.723 0h.001Zm-.002 2.505c-.795 0-1.503.411-1.9 1.1L2.8 24.59a2.17 2.17 0 0 0 .008 2.185 2.168 2.168 0 0 0 1.894 1.092h24.02c.789 0 1.497-.41 1.893-1.092.397-.683.4-1.5.006-2.185L18.623 3.606a2.165 2.165 0 0 0-1.9-1.1Zm-.01 18.37c.923 0 1.67.746 1.67 1.67 0 .923-.747 1.67-1.67 1.67a1.676 1.676 0 0 1-1.68-1.67c0-.924.74-1.67 1.662-1.67h.017Zm-.004-9.913c.691 0 1.252.562 1.252 1.253v5.177a1.253 1.253 0 0 1-2.505 0v-5.177c0-.691.561-1.253 1.253-1.253Z"
      fill="#EF2127"
    />
  </svg>
)

export default Attention
