import { useEffect } from 'react'
import { useFieldArray, useForm, useFormState } from 'react-hook-form'

import { DEFAULT_VALUES_ADD_RECIPE_THIRD_STEP } from '../../../utils/constants'
import { updateRecipe } from '../../../api/admin'
import { EditIcon } from '../../../components/SvgIcon'
import useDirty from '../../../utils/hooks/useDirty'
import RecipeSteps from '../../../components/RecipeSteps'
import ButtonCustom from '../../../components/ButtonCustom'

const StepsForm = ({ required, dataRecipe = {}, idRecipe }) => {
  const { control, handleSubmit, reset } = useForm()
  const { filterDirtyFields } = useDirty()
  const { dirtyFields } = useFormState({ control })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'steps',
  })
  const step = fields?.length + 1

  const addNewIngredient = () => {
    append({
      description: '',
      step,
    })
  }
  const onSubmit = (data) => {
    const newData = { ...data, id: idRecipe, to_publish: required }
    updateRecipe(newData).then(() => {
      reset(data)
    })
  }

  useEffect(() => {
    const filterDataRecipe = filterDirtyFields({
      dirtyFields: DEFAULT_VALUES_ADD_RECIPE_THIRD_STEP,
      data: dataRecipe,
    })
    reset(
      { ...DEFAULT_VALUES_ADD_RECIPE_THIRD_STEP, ...filterDataRecipe },
      { keepDefaultValues: Object.keys(filterDataRecipe).length === 1 }
    )
  }, [dataRecipe])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-16">
      <div className="adminUserNameWrap">
        <p className="adminUserTitle">Steps</p>
        <button type="submit" className="btnChangeName">
          <EditIcon className="changePencilName" />
        </button>
      </div>
      <div className="innerStepsAdmin">
        <div className="innerAllSteps">
          {fields.map((item, index) => {
            return (
              <RecipeSteps
                key={item.id}
                index={index}
                control={control}
                dirtyFields={dirtyFields}
                required={required}
                remove={remove}
              />
            )
          })}
        </div>
        <div className="recipeButtonWrap">
          <ButtonCustom
            onClick={() => addNewIngredient()}
            style="recipeButtonUpload"
            title="+ add another step"
          />
        </div>
      </div>
    </form>
  )
}

export default StepsForm
