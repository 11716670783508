import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import routes from '../routers'
import LoadingSpinner from './LoadingSpinner'

const RedirectPage = () => {
  const navigation = useNavigate()

  useEffect(() => navigation(routes.signIn), [])

  return <LoadingSpinner />
}

export default RedirectPage
