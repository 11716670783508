const Doc = () => (
  <svg width={26} height={30} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.834 2.565c.057.014.11.032.162.055l.18.045c.267.067.495.239.634.478l4.923 8.56c.13.226.168.495.105.748L22.86 24.463a6.234 6.234 0 0 1-7.344 4.662L5.007 26.521l-.143-.035A6.191 6.191 0 0 1 .45 19.184l3.545-14.3C4.877 1.652 8.21-.3 11.439.5l8.227 2.038c.056.004.112.013.168.027Zm-1.473 1.736-7.407-1.835c-2.171-.538-4.41.773-5 2.93L2.48 19.41c-.605 2.233.706 4.52 2.916 5.121l10.567 2.62c2.21.452 4.393-.936 4.93-3.168l2.672-10.78-2.634-.652a4.498 4.498 0 0 1-3.268-5.435l.698-2.815Zm-2.867 14.725a1.013 1.013 0 0 1-.487 1.966L7.93 19.238a1.013 1.013 0 0 1 .487-1.966l7.077 1.754Zm-1.461-5.58a1.013 1.013 0 0 1-.487 1.965l-4.398-1.09a1.013 1.013 0 0 1 .487-1.966l4.398 1.09Zm6.02-7.555-.424 1.713a2.468 2.468 0 0 0 1.792 2.982l1.554.384-2.922-5.08Z"
      fill="#E0E0E0"
    />
  </svg>
)

export default Doc
