import { handleActions } from 'redux-actions'

import { setGroups, setUrl } from '../actions/groups'

const initialState = {
  data: [],
  url: '',
}

const actions = {
  [setGroups]: (state, { payload }) => {
    return {
      ...state,
      data: payload,
    }
  },
  [setUrl]: (state, { payload }) => {
    return {
      ...state,
      url: payload,
    }
  },
}

export default handleActions(actions, initialState)
