import { updateAvatar as updateAvatarAPI } from '../api/user'
import { setUser } from '../redux/actions/user'
import { setLoading } from '../redux/actions/ui'

export const updateAvatar = (file) => (dispatch) => {
  dispatch(setLoading(true))
  return updateAvatarAPI(file).then((data) => {
    const avatar = data?.avatar + `?dummy=${+new Date()}`
    dispatch(setUser({ ...data, avatar }))
  })
}
