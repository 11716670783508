import { isNil } from 'lodash'

const UserPhoto = ({ image = null, name = '', classStylePhoto = '' }) => {
  return (
    <>
      {!isNil(image) ? (
        <img src={image} alt="" className={`groupUserPhoto ${classStylePhoto}`} />
      ) : (
        <div className={`userWithoutPhoto groupUserPhoto ${classStylePhoto}`}>
          <p className="userTableName">{name}</p>
        </div>
      )}
    </>
  )
}

export default UserPhoto
