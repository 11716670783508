import { useEffect, useState } from 'react'
import { isNil, isObject } from 'lodash'

import { DeleteBasket } from '../../../components/SvgIcon'
import { LIMIT_SIZE_IMAGE } from '../../../utils/constants'
import { updateRecipePhoto } from '../../../api/admin'

const RecipePhotoChange = ({ to_publish, value = null, idRecipe }) => {
  const [image, setImage] = useState(null)
  const [bigSize, setBigSize] = useState('')

  useEffect(() => {
    !isObject(value) && setImage(value)
  }, [value])

  const replaceImage = (image) => {
    updateRecipePhoto({ image, to_publish, id: idRecipe }).then(() =>
      setImage(URL.createObjectURL(image))
    )
  }

  const deleteRecipePhoto = () => {
    updateRecipePhoto({ id: idRecipe, image: null, to_publish })
      .then(() => setImage(null))
      .catch((e) => console.log(e))
  }

  return (
    <>
      <p className="adminUserTitle">RECIPE PHOTO</p>
      <div className="changeRecipeStyle">
        <img
          style={{ backgroundColor: '#f1f0f0', zIndex: -1 }}
          className="formPhotoRecipe"
          src={!isNil(image) ? image : '/images/imageRecipeWithoutPhoto.png'}
          alt=""
        />
        <input
          onChange={(e) => {
            const file = e.target?.files?.[0]

            if (file?.size > LIMIT_SIZE_IMAGE) {
              return setBigSize('Large image')
            }

            if (!isNil(file)) {
              setBigSize('')
              replaceImage(file)
            }
          }}
          id="file"
          className="adminText inputFilePhoto"
          type="file"
          accept=".png, .jpg, .jpeg"
        />
        <label htmlFor="file" className="adminText">
          Replace
        </label>
        <div onClick={deleteRecipePhoto}>
          <DeleteBasket />
        </div>
      </div>
      <p className="errorBigImage">{bigSize}</p>
    </>
  )
}

export default RecipePhotoChange
