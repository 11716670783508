import { setUserRoleStorage, updateToken } from './auth'
import { ROLES } from './constants'

const errorHandler = (e, handleNeworkError) => {
  const errorStatus = parseInt(e?.response?.status ?? 0)
  if (e.message === 'Network Error' || e.response === undefined) {
    handleNeworkError()
    throw e
  }

  if (errorStatus >= 500) {
    handleNeworkError()
    console.error(
      `\u001b[31m\n\nServer Error: ${e.message} \n ${JSON.stringify(e.response.data, null, 2)}`
    )
    throw e
  }

  if (
    errorStatus === 401 &&
    (e?.response?.data ===
      'E_JWT_TOKEN_EXPIRED: The jwt token has been expired. Generate a new one to continue' ||
      e?.response?.data === 'E_INVALID_JWT_TOKEN: invalid signature')
  ) {
    setUserRoleStorage(ROLES.ROLE_GUEST)
    updateToken()
  }

  throw e
}

export default errorHandler
