import { useDispatch } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import routes from '../../routers'
import { setLoading } from '../../redux/actions/ui'
import { getRecipesByCategory } from '../../api/recipe'
import { MIN_COUNT_RECIPE, MIN_LAST_PAGE, PAGINATION_SIZE } from '../../utils/constants'
import Layout from '../../components/Layout'
import TableUsers from '../../components/TableUsers'
import UserPhoto from '../../components/UserPhoto'
import ListRecipes from '../../components/ListRecipes'

import './styles.css'

const Category = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [visibleLoadBtn, setVisibleLoadBtn] = useState(false)
  const [allRecipes, setAllRecipes] = useState([])
  const [categoryName, setCategoryName] = useState('')
  const navigation = useNavigate()
  const { idGroup, codeCategory } = useParams()

  const getRecipes = useCallback(() => {
    getRecipesByCategory({ idGroup, codeCategory, page, size: PAGINATION_SIZE })
      .then(({ data }) => {
        setCategoryName(data?.category_name)
        setAllRecipes((oldState) => [...oldState, ...data?.recipes?.data])
        setVisibleLoadBtn(
          data?.recipes?.data.length >= MIN_COUNT_RECIPE &&
            data?.recipes?.lastPage !== MIN_LAST_PAGE
        )
      })
      .catch(() => navigation(routes.notFound))
      .finally(() => dispatch(setLoading(false)))
  }, [page])

  useEffect(() => {
    dispatch(setLoading(true))
    getRecipes()
  }, [getRecipes])

  const onClick = () => {
    setPage((oldState) => oldState + 1)
  }

  return (
    <Layout title="Category">
      <div className="containerMain containRecipe">
        <div className="dashboardWrap">
          <TableUsers />
          <ListRecipes
            showCountRecipes={false}
            title={categoryName}
            loadBtn={visibleLoadBtn}
            onClick={onClick}
          >
            {allRecipes?.map(({ id, image, name, author, slug }, index) => (
              <Link to={`${slug}-${id}`} key={index} className="innerRecipe">
                <img
                  src={image ? image : '/images/imageRecipeWithoutPhoto.png'}
                  alt=""
                  className="recipeImage"
                />
                <UserPhoto
                  image={author?.avatar}
                  name={author?.fullname}
                  classStylePhoto="listAvatar"
                />
                <p className="nameRecipe">{name}</p>
                <div className="darkWrapper" />
              </Link>
            ))}
          </ListRecipes>
        </div>
      </div>
    </Layout>
  )
}

export default Category
