import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useForm, Controller } from 'react-hook-form'

import { RECIPE_NAME_PATTERN } from '../../../utils/constants'
import { EditIcon } from '../../../components/SvgIcon'
import InputSimple from '../../../components/InputSimple'
import { updateRecipe } from '../../../api/admin'

const ChangeRecipeName = ({ idRecipe, to_publish, value }) => {
  const { handleSubmit, control, reset, formState } = useForm()

  const changeName = (data) => {
    updateRecipe({ ...data, id: idRecipe }).then(() => reset(data))
  }

  useEffect(() => {
    reset({ name: value })
  }, [value])

  return (
    <form onSubmit={handleSubmit(changeName)} className="formChaneName mb-16">
      <Controller
        name="name"
        control={control}
        rules={{
          required: to_publish,
          pattern: {
            value: RECIPE_NAME_PATTERN,
          },
          validate: { value: () => !isEmpty(formState?.dirtyFields) },
        }}
        defaultValue=""
        render={({ field, fieldState }) => (
          <InputSimple
            {...field}
            {...fieldState}
            nameInputPlaceholder="Name Recipe"
            styleInput="inputTable nameRecipeAdmin"
          />
        )}
      />
      <button type="submit" className="btnChangeName">
        <EditIcon className="changePencilName" />
      </button>
    </form>
  )
}

export default ChangeRecipeName
