import { useState } from 'react'

import ModalReport from '../pages/Dashboard/modals/Report'

import { Attention } from './SvgIcon'

const ButtonReport = ({ recipeId }) => {
  const [showReport, setShowReport] = useState(false)

  return (
    <div>
      <div className="reportWrap opacity" onClick={() => setShowReport(true)}>
        <Attention />
        <p className="reportText">Report this recipe</p>
      </div>
      <ModalReport recipe_id={recipeId} show={showReport} onClick={() => setShowReport(false)} />
    </div>
  )
}

export default ButtonReport
