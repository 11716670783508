import { useFieldArray, useForm, Controller } from 'react-hook-form'
import { connect, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import { EMAIL_PATTERN } from '../../utils/constants'
import { setUser } from '../../redux/actions/user'
import { setLoading } from '../../redux/actions/ui'
import { shareSubscription } from '../../api/trial'
import { SendIcon } from '../SvgIcon'
import InputSimple from '../InputSimple'

import './styles.css'

const FormAddons = ({ setCount, userData }) => {
  const dispatch = useDispatch()
  const defaultValueAddons = isEmpty(userData?.settings?.sharedUsers)
    ? [{ email: '', status: '' }]
    : userData?.settings?.sharedUsers
  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      addons_count: defaultValueAddons,
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addons_count',
  })
  const countShareLink = 6

  const onSubmit = ({ addons_count }) => {
    dispatch(setLoading(true))
    const emails = addons_count.reduce(
      (value, { email }) => (!isEmpty(email) ? [...value, email] : value),
      []
    )

    shareSubscription({ emails })
      .then(({ data }) => dispatch(setUser(data)))
      .catch((error) => {
        const arrayFailedEmails = error?.response?.data?.data
        const findErrorIndex = emails?.reduce(
          (allEmails, email, index) =>
            arrayFailedEmails?.includes(email) ? [...allEmails, index] : allEmails,
          []
        )
        findErrorIndex?.map((index) =>
          setError(
            `addons_count.${index}.email`,
            {
              type: 'manual',
              message: 'This email have own subscription',
            },
            { shouldFocus: true }
          )
        )
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const StatusPending = () => {
    return (
      <div className="statusPending">
        <SendIcon />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((item, index) => {
        const checkFirst = !index
          ? {
              function: () => {
                setCount((oldState) => oldState + 1)
                append({ email: '' })
              },
              icon: <p className="innerButtonChanges addonsButtonForm">+</p>,
            }
          : {
              function: () => {
                setCount((oldState) => oldState - 1)
                remove(index)
              },
              icon: <p className="innerButtonChanges addonsButtonForm">-</p>,
            }

        return (
          <div key={item.id}>
            <div className="innerInputAddon">
              <Controller
                name={`addons_count.${index}`}
                control={control}
                key={item.id}
                defaultValue=""
                rules={{
                  required: false,
                  pattern: { value: EMAIL_PATTERN },
                }}
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <InputSimple
                      onChange={(e) => {
                        const email = e?.target?.value
                        setCount((oldState) =>
                          email.length !== 1 || oldState !== 0 ? oldState : oldState + 1
                        )
                        onChange({ email })
                      }}
                      value={value?.email}
                      {...fieldState}
                      inputType="email"
                      className="famPadding"
                      nameInputPlaceholder="Shared email"
                    />
                    {!index && fields.length === countShareLink ? null : (
                      <div
                        onClick={checkFirst?.function}
                        className="submitAdd opacity buttonAddons"
                      >
                        {checkFirst?.icon}
                      </div>
                    )}
                    {value?.status && <StatusPending />}
                  </>
                )}
              />
            </div>
          </div>
        )
      })}
      <button className="linkNavigationAccount linkManageSubscriptions">Share subscription</button>
    </form>
  )
}

export default connect(({ user }) => ({ userData: user.data }))(FormAddons)
