import { useEffect, useState } from 'react'
import { isNil } from 'lodash'
import { connect, useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import routes, { categoriesGroup, draftRecipeLink } from '../../routers'
import { setLoading } from '../../redux/actions/ui'
import { deleteRecipe, getRecipeById } from '../../api/recipe'
import { setReduceNumberRecipes } from '../../redux/actions/user'
import Layout from '../../components/Layout'
import TrashButton from '../../components/TrashButton'
import ButtonReport from '../../components/ButtonReport'
import TableUsers from '../../components/TableUsers'
import UserPhoto from '../../components/UserPhoto'
import ModalRecipePhoto from './modals/ModalRecipePhoto'

import './style.css'

const renderWidget = () => {
  // DON'T EDIT BELOW THIS LINE
  let d = document,
    s = d.createElement('script')
  s.src = 'https://morsel.disqus.com/embed.js'
  s.setAttribute('data-timestamp', +new Date())
  ;(d.head || d.body).appendChild(s)
}

const Dashboard = ({ user }) => {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const { recipeId, idGroup, idSlug } = useParams()
  const [dataRecipe, setDataRecipe] = useState({})
  const [showImg, setShowImg] = useState(false)

  const servingSize = `${!isNil(dataRecipe?.amount) ? dataRecipe?.amount : ''} ${
    !isNil(dataRecipe?.measurement) ? dataRecipe?.measurement : ''
  }`
  const checkCurrentUser = user?.id === dataRecipe?.user_id

  const backgroundRecipeStyle = {
    backgroundImage: `url(${
      !isNil(dataRecipe?.image) ? dataRecipe?.image : '/images/imageRecipeWithoutPhoto.png'
    })`,
  }

  const deleteMyRecipe = () => {
    deleteRecipe(recipeId)
      .then(() => {
        dispatch(setReduceNumberRecipes())
        !isNil(idGroup)
          ? navigation(categoriesGroup(idSlug, idGroup))
          : navigation(routes.userProfile)
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    dispatch(setLoading(true))
    getRecipeById(recipeId, false)
      .then(({ data }) => {
        setDataRecipe(data)
      })
      .catch(() => navigation(routes.notFound))
      .finally(() => dispatch(setLoading(false)))
  }, [])

  renderWidget()

  return (
    <Layout title="Recipe">
      <div className="containerMain">
        <div className={`dashboardWrap ${isNil(idGroup) ? 'justify-content-center' : ''}`}>
          {!isNil(idGroup) ? <TableUsers sliderDisable /> : null}

          <div className="recipeWrap">
            <div
              onClick={() => setShowImg(!isNil(dataRecipe?.image))}
              style={backgroundRecipeStyle}
              className="backgroundRecipeStyle"
            >
              <div className="backgroundShadowRecipe" />
              <div className="recipeHeader">
                <p className="recipeHeaderTitle">{dataRecipe.name}</p>
                {checkCurrentUser ? (
                  <div className="recipeHeaderSetting">
                    <Link to={draftRecipeLink(dataRecipe?.id, 1)} className="opacity">
                      Edit Recipe
                    </Link>
                    &nbsp;|&nbsp;
                    <TrashButton deleteRecipe={deleteMyRecipe} />
                  </div>
                ) : null}
              </div>
              <div className="innerInfoCreatorUser">
                <span className="infoCreatorUserText">Recipe by:</span>
                <p className="infoCreatorUser">{dataRecipe?.author?.fullname}</p>
                <UserPhoto
                  classStylePhoto="infoCreatorUserPhoto"
                  image={dataRecipe?.author?.avatar}
                />
              </div>
            </div>
            <div className="recipeWrapContent">
              <div className="recipeSummaryWrap">
                <div className="innerDescription">
                  <p className="recipeTitle">Summary</p>
                  <p className="recipeText textDescription">{dataRecipe.description}</p>
                </div>

                <div className="summaryAvatarWrap">
                  <UserPhoto classStylePhoto="summaryAvatar" image={dataRecipe?.author?.avatar} />
                  <span className="summaryAvatarText">Recipe by:</span>
                  <p className="summaryAvatarName">{dataRecipe?.author?.fullname}</p>
                </div>
              </div>

              <div className="recipeWrapStep">
                <div className="recipeIngredients ">
                  <p className="recipeTitle">Ingredients</p>
                  {dataRecipe?.ingredients?.map(({ name, measurement, quantity }, index) => (
                    <p key={index} className="recipeText">
                      {quantity} {measurement} {name}
                    </p>
                  ))}

                  <p className="recipeTitle">Serving Size</p>
                  <p className="recipeText">{servingSize}</p>
                </div>
                <div className="recipeLine" />
                <div className="recipeSteps">
                  <p className="recipeTitle">Steps</p>
                  <div>
                    {dataRecipe?.steps?.map(({ step, description }) => (
                      <div key={step} className="recipeStepContent">
                        <p className="recipeNumber">{step}.</p>
                        <p>{description}</p>
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <ButtonReport recipeId={recipeId} />
              <div className="recipeTitle">Activity Feed</div>
              <div id="disqus_thread" />
            </div>
          </div>
        </div>
      </div>
      <ModalRecipePhoto show={showImg} onHide={() => setShowImg(false)} image={dataRecipe?.image} />
    </Layout>
  )
}

export default connect(({ user }) => ({ user: user.data }))(Dashboard)
