import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { Link, useParams } from 'react-router-dom'

import routes, { categoriesGroup } from '../../routers'
import { STATUS_NEED_SUBSCRIBE, STATUS_TRIAL } from '../../utils/constants'
import { getAdvertising } from '../../api/admin'
import { getInfoGroup } from '../../api/group'
import UserPhoto from '../UserPhoto'
import CustomSlider from '../CustomSlider'

import './TableUsers.css'

const UserAvatar = ({ image, routes, fullname }) => (
  <Link to={routes} className="groupUserPhotoMargin opacity">
    <UserPhoto image={image} name={fullname} />
  </Link>
)

const TableUsers = ({ sliderDisable, user }) => {
  const [allInfoGroups, setAllInfoGroups] = useState([])
  const [advertising, setAdvertising] = useState([])
  const { idGroup, idSlug } = useParams()

  useEffect(() => {
    getAdvertising()
      .then(({ data }) => setAdvertising(data))
      .catch((e) => console.log(e))
    getInfoGroup(idGroup)
      .then(({ data }) => setAllInfoGroups(data))
      .catch((e) => console.log(e))
  }, [idGroup])

  return (
    <div className={sliderDisable ? 'innerUsersRecipe' : 'innerUsers'}>
      <h2 className="titleTableUsers">{allInfoGroups?.name}</h2>
      <div className="wrapperUsers">
        {allInfoGroups?.users?.map(({ avatar, fullname, id, slug }) => {
          const linkToProfile =
            user.id === id
              ? routes.userProfile
              : `${categoriesGroup(idSlug, idGroup)}/profile-${slug}-${id}`

          return <UserAvatar key={id} image={avatar} routes={linkToProfile} fullname={fullname} />
        })}

        <Link to={routes.invite} className="groupUserPhotoMargin">
          <div className="inviteUser">
            <p className="inviteText">invite</p>
          </div>
        </Link>
      </div>
      {!sliderDisable ? (
        <CustomSlider
          idUser={user?.id}
          idGroup={idGroup}
          idSlug={idSlug}
          data={allInfoGroups?.users}
          className="sliderUsers"
        />
      ) : null}
      {!isEmpty(advertising?.[0]?.image) &&
      !isEmpty(advertising?.[1]?.image) &&
      (user.status === STATUS_TRIAL || user.status === STATUS_NEED_SUBSCRIBE) ? (
        <>
          <a href={advertising?.[0]?.link}>
            <img src={advertising?.[0]?.image} alt="Advertising" className="advertisingDesktop" />
          </a>
          <a href={advertising?.[1]?.link}>
            <img src={advertising?.[1]?.image} alt="Advertising" className="advertisingMobile" />
          </a>
        </>
      ) : null}
    </div>
  )
}

export default connect(({ user }) => ({ user: user.data }))(TableUsers)
