const ChangePencil = ({ onClick = () => {}, big = false, classStyle = '' }) => {
  return (
    <svg
      className={classStyle}
      onClick={onClick}
      width={big ? '27' : '19'}
      height={big ? '27' : '20'}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.86815 0.745512C9.25494 0.745512 9.56885 1.06016 9.56885 1.44784C9.56885 1.83553 9.25494 2.15017 8.86815 2.15017H5.37489C2.96072 2.15017 1.40141 3.80767 1.40141 6.37257V14.1581C1.40141 16.723 2.96072 18.3805 5.37489 18.3805H13.6189C16.0331 18.3805 17.5934 16.723 17.5934 14.1581V10.3861C17.5934 9.99846 17.9073 9.68382 18.2941 9.68382C18.6808 9.68382 18.9948 9.99846 18.9948 10.3861V14.1581C18.9948 17.5237 16.8338 19.7852 13.6189 19.7852H5.37489C2.16005 19.7852 0 17.5237 0 14.1581V6.37257C0 3.00701 2.16005 0.745512 5.37489 0.745512H8.86815ZM17.0053 1.56827L18.1423 2.70791C18.6964 3.26228 19.0009 3.99926 19 4.784C19 5.56873 18.6954 6.30477 18.1423 6.85821L11.1269 13.8899C10.6121 14.4059 9.92631 14.6906 9.19757 14.6906H5.69777C5.50905 14.6906 5.3278 14.6138 5.19607 14.478C5.06434 14.3432 4.9924 14.1606 4.99707 13.9705L5.08489 10.4317C5.10264 9.72746 5.38573 9.0654 5.88276 8.56627H5.8837L12.8655 1.56827C14.0072 0.425812 15.8636 0.425812 17.0053 1.56827ZM12.2904 4.13036L6.87403 9.55984C6.63205 9.80237 6.49471 10.1245 6.4863 10.4663L6.41623 13.2859H9.19757C9.5526 13.2859 9.8852 13.1483 10.1365 12.8964L15.5856 7.43318L12.2904 4.13036ZM13.8559 2.56183L13.2807 3.1368L16.5759 6.44056L17.152 5.86465C17.4398 5.57623 17.5986 5.19229 17.5986 4.784C17.5986 4.37477 17.4398 3.9899 17.152 3.70147L16.015 2.56183C15.4199 1.96719 14.4519 1.96719 13.8559 2.56183Z"
        fill={big ? '#000000' : '#F7F5AD'}
      />
    </svg>
  )
}

export default ChangePencil
