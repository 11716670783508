const User = () => (
  <svg width={20} height={26} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 20.446c0 4.162-5.707 4.643-9.999 4.643h-.307C6.96 25.082 0 24.909 0 20.42c0-4.077 5.478-4.621 9.737-4.642h.571c2.735.006 9.692.179 9.692 4.667Zm-9.999-2.774c-5.38 0-8.107.924-8.107 2.749 0 1.84 2.727 2.774 8.107 2.774 5.38 0 8.105-.924 8.105-2.749 0-1.84-2.726-2.774-8.105-2.774Zm0-17.672a6.71 6.71 0 0 1 6.704 6.705A6.71 6.71 0 0 1 10 13.408h-.04A6.691 6.691 0 0 1 3.295 6.7C3.295 3.008 6.303 0 10.001 0Zm0 1.803a4.907 4.907 0 0 0-4.903 4.902 4.89 4.89 0 0 0 4.867 4.901l.036.902v-.902c2.702 0 4.9-2.2 4.9-4.901a4.906 4.906 0 0 0-4.9-4.902Z"
      fill="#E0E0E0"
    />
  </svg>
)

export default User
