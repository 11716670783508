import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'

import routes, { categoriesGroup, manageGroup } from '../../routers'
import { createGroup, leaveFromGroup } from '../../api/group'
import { setGroups } from '../../redux/actions/groups'
import {
  RECIPE_NAME_PATTERN,
  REQUIRED_FIELD_MESSAGE,
  ROLE_ADMIN,
  ROLE_OWNER,
} from '../../utils/constants'
import { ArrowBack } from '../../components/SvgIcon'
import Layout from '../../components/Layout'
import HookForm from '../../components/HookForm'
import LayoutAccount from '../../components/LayoutAccount'
import Button from '../../components/ButtonCustom'
import ModalAccount from '../../components/Modals/ModalAccount'

import './styles.css'

const AccountYourFams = ({ groups }) => {
  const [show, setShow] = useState(false)
  const [groupsUser, setGroupsUser] = useState(groups)
  const dispatch = useDispatch()
  const createGroupRef = useRef(null)

  const leaveGroup = (id) => {
    leaveFromGroup(id)
      .then(() =>
        setGroupsUser((oldState) => {
          const newGroups = oldState.filter((item) => item.id !== id)
          dispatch(setGroups(newGroups))
          return newGroups
        })
      )
      .catch((e) => console.log(e))
    setShow(false)
  }
  const handleSubmit = (data) => {
    createGroup(data)
      .then(({ data }) => {
        setGroupsUser((oldState) => {
          const newGroups = [...oldState, data]
          dispatch(setGroups(newGroups))
          return newGroups
        })
      })
      .then(() => createGroupRef.current?.reset({ name: '' }))
      .catch((e) => console.log(e))
  }

  const field = [
    {
      name: 'name',
      defaultValue: '',
      nameInputPlaceholder: 'Enter a New Group Name',
      rules: { required: REQUIRED_FIELD_MESSAGE, pattern: { value: RECIPE_NAME_PATTERN } },
    },
  ]

  const ListItemGroup = ({ id, name, role, slug }) => {
    const checkUserAdmin = role === ROLE_ADMIN || role === ROLE_OWNER

    return (
      <div className="innerAllFams">
        <Link to={categoriesGroup(slug, id)} className="nameYourFam groupsName">
          {name}
        </Link>
        {checkUserAdmin ? (
          <Link to={manageGroup(id)} className="makeItemBtn linkManage">
            Manage
          </Link>
        ) : null}
        <p onClick={() => setShow({ id, name })} className="btnLeaveFams nameYourFam">
          Leave Group
        </p>
      </div>
    )
  }

  return (
    <Layout title="Manage Your Fams">
      <LayoutAccount>
        <h1 className="titleManageFams titleNotification">Manage Your Groups</h1>
        <div className="innerInfoFams">
          <div className="itemFams">
            <h1 className="titleItemFamsYour titleNotification">Groups You’re In</h1>
            {groupsUser.map((item) => (
              <ListItemGroup key={item?.id} {...item} />
            ))}
          </div>
          <div className="innerFomCreateGroup">
            <h1 className="titleItemFams titleNotification">Create a New Group</h1>
            <HookForm
              className="createNewFam"
              ref={createGroupRef}
              fields={field}
              onSubmit={handleSubmit}
              Footer={() => <Button title="Create a New Group" style="createBtnFams" />}
            />
          </div>
        </div>
        <Link className="backLink innerBackBtnManageFams" to={routes.settings}>
          <ArrowBack />
          back
        </Link>
      </LayoutAccount>

      <ModalAccount show={show} onHide={() => setShow(false)}>
        <div className="innerModalRemoveUser innerLeaveFromGroup">
          <p className="textModalRemoveFirst">Are you sure you want to leave {show?.name}?</p>
        </div>
        <button
          onClick={() => leaveGroup(show?.id)}
          className="removeUserButton leaveGroupModalbutton"
        >
          Yes, leave the group
        </button>
      </ModalAccount>
    </Layout>
  )
}

export default connect(({ groups }) => ({ groups: groups.data }))(AccountYourFams)
