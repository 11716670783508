import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { isNil } from 'lodash'

import routes from '../routers'

const Page404 = ({ userData }) => {
  const redirection = !isNil(userData) ? routes.settings : routes.signIn

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>
        Sorry this page doesn't exist
        <br />
        <Link to={redirection}>Home</Link>
      </h1>
    </div>
  )
}

export default connect(({ user }) => ({ userData: user.data }))(Page404)
