import { useState } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'

import routes, { categoriesGroup } from '../../routers'
import { Group, Home } from '../SvgIcon'

import './styles.css'

const GroupMenu = ({ groups, menuGroups }) => {
  return (
    <div className={`innerGroupMenu ${menuGroups ? 'activeGroupMenu' : ''}`}>
      {!isEmpty(groups) ? (
        groups?.map((item) => (
          <Link
            key={item?.id}
            to={categoriesGroup(item?.slug, item?.id)}
            className="groupNameMenu navigateMenuItem"
          >
            {item.name}
          </Link>
        ))
      ) : (
        <Link to={routes.manageFams} className="groupNameMenu navigateMenuItem">
          + Create Group
        </Link>
      )}
    </div>
  )
}

const Footer = ({ groups }) => {
  const [menuGroups, setMenuGroups] = useState(false)

  return (
    <div className="innerFooter">
      <footer className="footer">
        <div className="footerInner">
          <Link to={routes.userProfile} className="innerHomeBtn">
            <Home />
          </Link>
          <Link to={routes.addRecipe} className="btnAddRecipe">
            <span />
            <span />
          </Link>
          <div onClick={() => setMenuGroups((oldState) => !oldState)} className="navigateGroups">
            <Group color={menuGroups ? ' #FEC925' : '#F7F5AD'} />
          </div>
        </div>
      </footer>
      <GroupMenu groups={groups} menuGroups={menuGroups} />
    </div>
  )
}

export default connect(({ groups }) => ({ groups: groups.data }))(Footer)
