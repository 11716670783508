const CustomBlockInput = ({
  ItemMenu = [],
  onChange,
  title = '',
  settings = {},
  text = '',
  innerStyle = '',
  textStyle = '',
  custom = true,
  styleToggleList = '',
  Component,
  globalClassName = '',
  value = '',
}) => {
  return (
    <div className={innerStyle}>
      <p className={textStyle}>{text}</p>
      <Component
        title={title}
        ItemMenu={ItemMenu}
        custom={custom}
        styleToggleList={styleToggleList}
        onChange={onChange}
        settings={settings}
        globalClassName={globalClassName}
        value={value}
      />
    </div>
  )
}

export default CustomBlockInput
