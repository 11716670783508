import { useFieldArray, useForm, Controller } from 'react-hook-form'
import { connect } from 'react-redux'

import { EMAIL_PATTERN } from '../../utils/constants'
import InputSimple from '../InputSimple'
import DropDownCustom from '../DropDownCustom'

const ToGroupList = ({
  classInput = 'famInput famMargin',
  icon = null,
  Footer = null,
  classInnerList = 'innerList',
  inputPlaceHolder = '',
  linkToGroup = false,
  titleStyle = '',
  setLinkToGroupInvite,
  submitInvite = () => {},
  groupsData,
}) => {
  const { control, handleSubmit, reset } = useForm({
    rules: {
      required: true,
      pattern: {
        value: EMAIL_PATTERN,
        message: 'This input is emails only',
      },
    },
    defaultValues: {
      emails: [{ email: '' }],
    },
  })
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'emails',
  })

  const addNewFam = () => {
    prepend({
      email: '',
    })
  }

  const onSubmit = (data) => {
    submitInvite(data)
    reset({ emails: [{ email: '' }], group_id: '' })
  }

  const List = () => {
    return (
      <div className="famItemWrap">
        {fields.map((item, index) => {
          const checkFirst = !index
            ? { function: addNewFam, icon: <p className="innerButtonChanges">+</p> }
            : { function: () => remove(index), icon: <p className="innerButtonChanges">-</p> }

          return (
            <Controller
              name={`emails.${index}.email`}
              control={control}
              key={item.id}
              defaultValue=""
              rules={{
                required: index !== 0 || fields.length < 2,
                pattern: { value: EMAIL_PATTERN },
              }}
              render={({ field, fieldState }) => (
                <div className="innerButtonInviteFriends">
                  <InputSimple
                    {...field}
                    {...fieldState}
                    inputType="email"
                    className={`${classInput} famPadding`}
                    icon={icon}
                    nameInputPlaceholder={inputPlaceHolder}
                  />
                  <div onClick={checkFirst?.function} className="submitAdd opacity">
                    {checkFirst?.icon}
                  </div>
                </div>
              )}
            />
          )
        })}
      </div>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formFam">
          <div className={classInnerList}>
            <List />
          </div>
        </div>
        {linkToGroup ? (
          <Controller
            name="group_id"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <DropDownCustom
                  value={value}
                  title="Add to a Group (select one or more)"
                  settings={{
                    style: `mt-16 dropDownArray ${error ? 'borderError' : ''}`,
                    titleStyle: titleStyle,
                  }}
                  styleToggleList="listForm"
                  ItemMenu={groupsData}
                  setLinkGroup={setLinkToGroupInvite}
                  onChange={onChange}
                  selectedWithValue
                  custom
                />
              )
            }}
          />
        ) : null}

        <Footer />
      </form>
    </>
  )
}

export default connect(({ groups }) => ({ groupsData: groups.data }))(ToGroupList)
