import { useEffect, useState } from 'react'
import fileDownload from 'js-file-download'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import routes from '../../routers'
import { deleteRecipe, exportAll } from '../../api/admin'
import { DATA_DROP_REPORT, DRAFT_STATUS, HEADER_REPORTS_RECIPE } from '../../utils/constants'
import { getAllInfoRecipeById, removeReportRecipe } from '../../api/admin'
import ButtonCustom from '../../components/ButtonCustom'
import ModalAccount from '../../components/Modals/ModalAccount'
import Table from './components/TableComponent'
import LayoutRecipe from './components/LayoutAdmin'
import ChangeRecipeName from './components/ChangeRecipeName'
import RecipePhotoChange from './components/RecipePhotoChange'
import SummaryChanges from './components/SummaryChanges'
import IngredientsForm from './components/IngredientsForm'
import StepsForm from './components/StepsForm'

import './style.css'

const AdminRecipe = () => {
  const { idRecipe } = useParams()
  const navigation = useNavigate()
  const [required, setRequired] = useState(true)
  const [modalRemove, setModalRemove] = useState(false)
  const [allRecipeData, setAllRecipesData] = useState({})
  const [allReports, setAllReports] = useState([])

  const props = { dataRecipe: allRecipeData, required, idRecipe }

  const deleteReport = (idRecipe, idUser) => {
    removeReportRecipe(idRecipe, idUser).then(() =>
      setAllReports((oldState) =>
        oldState?.filter((item) => item?.id !== idRecipe && item?.user_id !== idUser)
      )
    )
  }

  const exportRecipe = () => {
    exportAll({ ids: [idRecipe] }).then((data) => fileDownload(data, `${allRecipeData?.name}.xlsx`))
  }

  const deleteRecipeAll = () => {
    deleteRecipe(allRecipeData?.id)
      .then(() => navigation(routes?.adminRecipeList))
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    getAllInfoRecipeById(idRecipe).then(({ data }) => {
      setRequired(data?.status !== DRAFT_STATUS)
      const reports = data?.reports?.map((item) => ({
        id: item?.recipe_id,
        user_id: item?.user_id,
        userName: [{ name: item?.author?.fullname, link: `${routes.adminUser}/${item?.user_id}` }],
        type: DATA_DROP_REPORT?.find(({ id }) => id === item?.type).name,
        message: item?.message,
      }))
      setAllReports(reports)
      setAllRecipesData(data)
    })
  }, [])

  return (
    <LayoutRecipe>
      <div className="adminUserContent innerAdminAllSteps">
        <div>
          <div className="adminUserWrap">
            <div>
              <ChangeRecipeName
                idRecipe={idRecipe}
                to_publish={required}
                value={allRecipeData?.name}
              />
              <p className="adminUserTitle">Group Ident #{allRecipeData?.id}</p>
            </div>
            <div>
              <div className="adminUserDate">
                <p>DATE CREATED: {moment(allRecipeData?.created_at).format('MM/DD/YYYY')}</p>
                <ButtonCustom
                  style="adminUserDeleteButton mt-16"
                  titleStyle="adminUserButtonText"
                  title="DELETE RECIPE"
                  onClick={() => setModalRemove(true)}
                />
                <ButtonCustom
                  style="adminUserResetButton  mt-16"
                  titleStyle="adminUserButtonText"
                  title="EXPORT RECIPE"
                  onClick={exportRecipe}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="recipeAdminWrap">
          <RecipePhotoChange
            idRecipe={idRecipe}
            to_publish={required}
            value={allRecipeData?.image}
          />
          <SummaryChanges {...props} />
          <IngredientsForm {...props} required={false} />
          <StepsForm {...props} />
        </div>
        <p className="adminUserTitle">RECIPE REPORTS</p>
        <Table
          dataTable={allReports}
          dataHeader={HEADER_REPORTS_RECIPE}
          smallTable
          deleteTd
          deleteTableRow={deleteReport}
        />
      </div>
      <ModalAccount show={modalRemove} onHide={() => setModalRemove(false)}>
        <div className="innerModalRemoveUser">
          <p className="textModalRemoveFirst">Are you sure you want to remove this recipe?*</p>
          <button onClick={deleteRecipeAll} className="removeUserButton">
            Yes, remove recipe
          </button>
        </div>
      </ModalAccount>
    </LayoutRecipe>
  )
}

export default AdminRecipe
