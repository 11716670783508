const DeleteRecipeIcon = () => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity={0.4}
      d="M17.208 0H6.804C2.736 0 0 2.856 0 7.104v9.804C0 21.144 2.736 24 6.804 24h10.404C21.276 24 24 21.144 24 16.908V7.104C24 2.856 21.276 0 17.208 0Z"
      fill="#D30000"
    />
    <path
      d="m15.62 14.125-2.135-2.134 2.134-2.134c.41-.409.41-1.075 0-1.484a1.047 1.047 0 0 0-1.485-.001L12 10.505 9.865 8.37a1.052 1.052 0 0 0-1.486 0c-.409.41-.409 1.077 0 1.486l2.136 2.136-2.131 2.13a1.049 1.049 0 0 0 .742 1.793c.27 0 .537-.102.742-.307L12 13.475l2.136 2.135a1.052 1.052 0 0 0 1.484-1.486Z"
      fill="#D30000"
    />
  </svg>
)

export default DeleteRecipeIcon
