import r from '../utils/request'

export const loginAdmin = (params) => {
  return r.post('/admin/login', params)
}

export const getAllUsers = () => {
  return r.get('/admin/users')
}

export const getAllRecipe = () => {
  return r.get('/admin/recipes')
}

export const getAllGroups = () => {
  return r.get('/admin/groups')
}

export const getUserById = (id) => {
  return r.get(`/admin/users/${id}`)
}

export const changeUserInfo = (id, params) => {
  return r.put(`/admin/users/${id}`, params)
}

export const deleteUser = (id) => {
  return r.delete(`/admin/users/${id}`)
}

export const pauseUser = (id) => {
  return r.put(`/admin/users/${id}/pause`)
}

export const deleteRecipe = (idRecipe) => {
  return r.delete(`/admin/recipes/${idRecipe}`)
}

export const deleteUserFromGroup = (idUser, idGroup) => {
  return r.delete(`/admin/users/${idUser}/groups/${idGroup}`)
}

export const getAllInfoRecipeById = (id) => {
  return r.get(`/admin/recipes/${id}`)
}

export const removeReportRecipe = (recipe_id, user_id) => {
  return r.delete(`/admin/reports/recipes/${recipe_id}/users/${user_id}`)
}

export const updateRecipe = (params) => {
  return r.put(`/admin/recipes/${params?.id}`, params)
}

export const updateRecipePhoto = (params) => {
  return r.upload(`/admin/recipes/${params?.id}`, params)
}

export const getGroupById = (id) => {
  return r.get(`/admin/groups/${id}`)
}

export const changeNameGroup = (params) => {
  return r.put(`/admin/groups/${params?.id}`, params)
}

export const deleteGroupById = (id) => {
  return r.delete(`/admin/groups/${id}`)
}

export const deleteRecipeFromGroup = (idRecipe, idGroup) => {
  return r.delete(`/admin/groups/${idGroup}/recipes/${idRecipe}`)
}

export const advertisingAd = (params) => {
  return r.upload('/admin/ads', params, 'post')
}

export const getAdvertising = (is_desktop) => {
  return r.get('/ads', { is_desktop })
}

export const exportAll = (params = {}) => {
  return r.getFile('/admin/recipes/export', params)
}

export const deleteAd = (is_desktop) => {
  return r.delete('/admin/ads', { is_desktop })
}

export const addNewVideo = (params) => {
  return r.upload('/admin/recipes/video', params, 'post')
}

export const changeSharedSubscribe = (params) => {
  return r.put(`/admin/users/${params?.id}/shared-emails`, params)
}
