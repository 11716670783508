import { Link } from 'react-router-dom'
import './style.css'

const Button = ({ link = '', title = '', onClick, style = '', icon, titleStyle = '' }) => {
  const ButtonWrap = ({ link, children }) => (
    <>
      {link ? (
        <Link to={link} className={`buttonWrapper ${style}`}>
          {children}
        </Link>
      ) : (
        <button type="submit" onClick={onClick} className={`buttonWrapper ${style}`}>
          {children}
        </button>
      )}
    </>
  )

  return (
    <ButtonWrap link={link} onClick={onClick}>
      <p className={`buttonText ${titleStyle}`}>{title}</p>
      {icon ? <>&nbsp;&nbsp;{icon}</> : ''}
    </ButtonWrap>
  )
}

export default Button
