import { useEffect } from 'react'
import { isNil } from 'lodash'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate } from 'react-router-dom'

import routes from '../../routers'
import { TEMPORARY_TOKEN } from '../../utils/constants'
import { updateToken } from '../../utils/auth'

import './styles.css'

const LayoutRegistration = ({
  children,
  title,
  signIn = false,
  user,
  routesRedirect = routes?.signIn,
}) => {
  const navigation = useNavigate()

  useEffect(() => {
    if (!isNil(user)) {
      return navigation(routes.userProfile)
    }
    const temporary = localStorage.getItem(TEMPORARY_TOKEN)
    updateToken(temporary, TEMPORARY_TOKEN)
  }, [])

  return (
    <div className="wrapper">
      <Helmet>
        <title>{title} - Morsel</title>
        <meta property="og:title" content={title} key="title" />
      </Helmet>

      <div className="headerSignUp">
        <a className="innerLogoSiteRegistration" href="https://getmorsel.com">
          <img className="siteLogo" alt="Logo" src="/logo.svg" />
        </a>
        <Link to={routesRedirect} className="headerText opacity">
          {signIn ? <p>Have an account? Sign In</p> : <p>No account? Sign Up</p>}
        </Link>
      </div>
      {children}
    </div>
  )
}

export default connect(({ user }) => ({ user: user.data }))(LayoutRegistration)
