import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import routes from '../../../routers'
import { resetPassword } from '../../../api/auth'
import { Mail } from '../../../components/SvgIcon'
import { EMAIL_PATTERN, INPUT_TYPES } from '../../../utils/constants'
import HookForm from '../../../components/HookForm'
import LayoutRegistration from '../../../components/LayoutRegistration'

const ResetPassword = () => {
  const navigation = useNavigate()
  const formResetRef = useRef()

  const field = [
    {
      name: 'email',
      className: 'mt-8',
      rules: {
        required: true,
        pattern: {
          value: EMAIL_PATTERN,
          message: 'This input is emails only',
        },
        minLength: { value: 6 },
      },
      defaultValue: '',
      type: INPUT_TYPES.TEXT,
      icon: <Mail />,
      nameLabel: 'Enter your email',
      inputType: 'email',
    },
  ]

  const handleSubmit = (data) => {
    resetPassword(data)
      .then(() => navigation(routes.successReset))
      .catch(() => {
        formResetRef.current?.setError('email', {
          type: 'manual',
          message: 'There is no user with this email',
        })
      })
  }

  return (
    <LayoutRegistration title="Reset Password">
      <div className="modalSignUp">
        <p className="modalTitle">Reset Your Password</p>
        <HookForm
          ref={formResetRef}
          className="formSignUp"
          onSubmit={handleSubmit}
          fields={field}
          Footer={() => (
            <button type="submit" className="buttonWrapper buttonText">
              Reset Password
            </button>
          )}
        />
      </div>
    </LayoutRegistration>
  )
}

export default ResetPassword
