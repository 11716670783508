import { createStore as createReduxStore, combineReducers } from 'redux'

import userReducer from './reducers/user'
import groupsReducer from './reducers/groups'
import createUIReducer from './reducers/ui'
import adminReducer from './reducers/admin'

export const createStore = () => {
  const reducers = {
    user: userReducer,
    admin: adminReducer,
    groups: groupsReducer,
    ui: createUIReducer(),
  }

  const rootReducer = combineReducers(reducers)
  return createReduxStore(rootReducer)
}

export default createStore
