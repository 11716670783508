const ArrowBack = ({ className = 'arrowBack' }) => {
  return (
    <svg
      className={className}
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 7.39047C18 7.80468 17.6922 8.14701 17.2928 8.20118L17.1818 8.20865L2.79927 8.208L7.99526 13.3828C8.31547 13.7016 8.31658 14.2196 7.99775 14.5398C7.7079 14.8309 7.25342 14.8583 6.9326 14.6214L6.84067 14.5423L0.240669 7.97069C0.19846 7.92867 0.1618 7.88318 0.130684 7.83515C0.121897 7.82069 0.112951 7.8059 0.104469 7.79081C0.0966682 7.77788 0.0896187 7.76445 0.0829697 7.75087C0.0737324 7.73111 0.0647087 7.71071 0.0565147 7.68988C0.0498562 7.67382 0.0442982 7.6582 0.0392284 7.64246C0.0331993 7.62294 0.0273438 7.60214 0.0223045 7.58102C0.0185604 7.56628 0.0155926 7.55208 0.0130062 7.53784C0.00936699 7.51664 0.00627136 7.4947 0.00406075 7.47251C0.0021534 7.45558 0.00100517 7.43881 0.000370026 7.42202C0.000205994 7.41184 0 7.40118 0 7.39047L0.00041008 7.35878C0.00103569 7.34272 0.00213242 7.32667 0.00370026 7.31067L0 7.39047C0 7.33884 0.00478172 7.28833 0.0139275 7.23935C0.0160484 7.22765 0.0185757 7.21564 0.0213757 7.20368C0.0271873 7.17905 0.0339031 7.15533 0.0416374 7.13208C0.045433 7.12053 0.0498543 7.10819 0.0545807 7.09593C0.0641422 7.07132 0.0745354 7.04785 0.085968 7.02499C0.0912781 7.01424 0.0972271 7.00298 0.103456 6.99184C0.11368 6.97367 0.124214 6.9564 0.135351 6.93957C0.143209 6.92766 0.151913 6.91526 0.160999 6.90305L0.168079 6.89362C0.190123 6.86482 0.214037 6.83753 0.239639 6.81193L0.240622 6.81117L6.84062 0.238443C7.1608 -0.0804143 7.67884 -0.0793417 7.9977 0.240839C8.28757 0.531912 8.31304 0.986508 8.07472 1.30632L7.99531 1.39792L2.80145 6.57163L17.1818 6.57229C17.6337 6.57229 18 6.9386 18 7.39047Z"
        fill="#37190C"
      />
    </svg>
  )
}

export default ArrowBack
